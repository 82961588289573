import {useApolloClient, useSuspenseQuery} from "@apollo/client";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {CollectExprSpec, TypeInfo} from "@graphql/graphql";
import ExpressionTree from "@components/ExpressionTree";
import {ProgramCounter} from "@util/types.ts";
import {gql} from "@graphql/gql";
import {buildTree, maybeAddType, treeNodes} from "./available-vars-helpers";

const GET_AVAILABLE_VARS = gql(/* GraphQL */ `
  query GetAvailableVars(
    $binaryID: String!
    $functionName: String!
    $pc: String
    $paramsOnly: Boolean
  ) {
    availableVars(
      binaryID: $binaryID
      funcName: $functionName
      pc: $pc
      paramsOnly: $paramsOnly
    ) {
      Vars {
        Name
        Type
        FormalParameter
        ReturnValue
        LoclistAvailable
      }
      Types {
        Name
        Kind
        Redirect
        Fields {
          Name
          Type
          Embedded
        }
        FieldsNotLoaded
      }
    }
  }
`);

export type AvailableVarsProps = {
  funcQualifiedName: string;
  collectExprs: CollectExprSpec[];
  binaryID: string;
  // If binaryID is specified (as a string), then a program counter can also be
  // specified. If it is, it will dictate the availability of variables.
  pc?: ProgramCounter;
  // If set, only function parameters are returned, not local variables.
  paramsOnly?: boolean;

  // onExpressionCollectChanged is called when a variable/expression gets
  // checked on unchecked in the tree rendering the variables to collect.
  onExpressionCollectChanged: (
    expr: string,
    checked: boolean,
  ) => Promise<boolean>;
  // onStaleExpressionDeleted is called when the "delete" button is clicked on a
  // node representing a variable/expressions that is not compatible with the
  // current binary.
  onStaleExpressionDeleted: (expr: string) => Promise<boolean>;
};

// AvailableVars renders a tree of variables and fields. The tree starts from a
// function, with that function's variables as roots.
export default function AvailableVars(
  props: AvailableVarsProps,
): React.JSX.Element {
  const client = useApolloClient();

  // The expressions to collect, as strings. useMemo() because we need a stable
  // object; we use it as an effect dependency.
  const collectedExprs = useMemo(
    () =>
      new Map<string, string | undefined>(
        // Hoisting is not needed for function variables.
        props.collectExprs?.map((e) => [e.expr, undefined]) ?? [],
      ),
    [props.collectExprs],
  );

  // Load the function's variables.
  const {data: functionVars, error: varsError} = useSuspenseQuery(
    GET_AVAILABLE_VARS,
    {
      variables: {
        binaryID: props.binaryID,
        functionName: props.funcQualifiedName,
        pc: props.pc?.toString(10),
        paramsOnly: props.paramsOnly,
      },
      // Don't throw on errors.
      errorPolicy: "all",
    },
  );

  const [tree, setTree] = useState<treeNodes | undefined>();
  // expandedNodes keeps track of the IDs of the nodes currently expanded. This
  // data is passed to the ExpressionTree component. The data is managed by this
  // AvailableVars components, as opposed to being entirely managed by the
  // ExpressionTree component, because rebuilding the tree needs to know what
  // nodes are expanded, to make sure that the respective child nodes are
  // present in the tree.
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  // typesMapRef is a cache keeping track of the loaded types. The map is
  // mutable - more types can be loaded over time.
  const typesMapRef = useRef<Map<string, TypeInfo>>(new Map());
  const typesMap = typesMapRef.current;

  // Load the types that we got from the query into the cache.
  let typeFields: TypeInfo | undefined;
  if (!varsError) {
    if (functionVars!.availableVars.Types) {
      functionVars!.availableVars.Types.forEach((t) => {
        maybeAddType(typesMap, t);
      });
    }
  }

  // Rebuild the tree whenever a new frame or type is being displayed, or when a
  // new node was expanded, or when a new type finished loading. We basically
  // want to rebuild the tree on every render, but building the tree is an async
  // function, so we have to call it from an effect.
  useEffect(() => {
    if (varsError) {
      return;
    }

    async function rebuild() {
      const tree = await buildTree(
        props.binaryID,
        typeFields,
        functionVars?.availableVars.Vars,
        expandedNodes,
        collectedExprs,
        typesMap,
        client,
      );
      setTree(tree);
    }

    void rebuild();
  }, [
    expandedNodes,
    collectedExprs,
    varsError,
    props.binaryID,
    typeFields,
    functionVars?.availableVars.Vars,
    typesMap,
    client,
  ]);

  // If the tree is not loaded yet, don't render anything. The effect above will
  // kick in to build the tree and cause a re-render.
  if (!tree) {
    if (varsError) {
      return <div>Error: {varsError.message}</div>;
    }
    return <></>;
  }

  return (
    <>
      <ExpressionTree
        nodes={tree.tree}
        hoisting={false}
        // When a node is toggled, we update the list of expanded nodes (thus
        // adding or removing the respective node's ID from the list).
        // TODO: When expanding a new node, this simple code is not great: if
        // loading the children of the newly-expanded node fails, the node's ID
        // will remain in the expanded list, and so the node will be rendered as
        // expanded and loading the children will be re-attempted on every
        // render, even if it fails over and over and pollutes the console.
        onNodeToggle={(expandedNodeIds) => setExpandedNodes(expandedNodeIds)}
        onNodeChange={(node, checked, _) =>
          void props.onExpressionCollectChanged(node.fullExpr, checked)
        }
        onStaleNodeDelete={(node) =>
          void props.onStaleExpressionDeleted(node.fullExpr)
        }
        expandedNodeIDs={expandedNodes}
      />
    </>
  );
}
