import React, {useContext, useState} from "react";
import {Card, CardContent, CardHeader, Divider, Tab} from "@mui/material";
import {HowToDeployLink} from "./HowToDeployLink.tsx";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {TableAgents} from "./TableAgents.tsx";
import _ from "lodash";
import {AgentReportsContext} from "../../../providers/agent-reports-provider.tsx";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export const TabsEnvironments: React.FC = () => {
  const agentsReport = useContext(AgentReportsContext);
  const reports = agentsReport!.Report.Reports;

  const groupedReportsByEnvironment = _.groupBy(
    reports,
    (r) => r.Environment || "Environment not set",
  );
  const environments = _.sortBy(
    Object.keys(groupedReportsByEnvironment),
    // Make the unnamed environment sort first.
    (x: string): string => (x == "Environment not set" ? "___" : x),
  );

  const [selectedEnvTab, setSelectedEnvTab] = useState(environments[0]);

  return (
    <Card>
      <CardHeader title="Agents" subheader={<HowToDeployLink />} />
      <CardContent>
        {/*If there are many environments, allow selecting them through an autocomplete.*/}
        {environments.length > 3 && (
          <Autocomplete
            disablePortal
            options={environments}
            sx={{width: 700, mb: 2}}
            renderInput={(params) => (
              <TextField {...params} label="Environment" />
            )}
            value={selectedEnvTab}
            onChange={(_, val) => setSelectedEnvTab(val)}
            disableClearable={true}
          />
        )}
        <TabContext value={selectedEnvTab}>
          <TabList
            variant="scrollable"
            onChange={(_, env) => setSelectedEnvTab(env as string)}
          >
            {environments.map((env) => (
              <Tab
                key={env}
                value={env}
                label={
                  env + ` (${groupedReportsByEnvironment[env]?.length || 0})`
                }
              />
            ))}
          </TabList>

          <Divider color="gradient"></Divider>

          {environments.map((env) => (
            <TabPanel key={env} value={env} sx={{padding: 0, mt: 3}}>
              <TableAgents reports={groupedReportsByEnvironment[env]} />
            </TabPanel>
          ))}
        </TabContext>
      </CardContent>
    </Card>
  );
};
