import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import React from "react";

export const MuiCheckbox = (): Pick<
  Components<typeof darkTheme>,
  "MuiCheckbox"
> => ({
  MuiCheckbox: {
    defaultProps: {
      icon: <CheckBoxOutlineBlankOutlinedIcon color="info" />,
      checkedIcon: <CheckBoxOutlinedIcon />,
      indeterminateIcon: <IndeterminateCheckBoxOutlinedIcon />,
    },
    styleOverrides: {
      root: {
        paddingBlock: 8,
      },
    },
  },
});
