import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const MuiAccordion = (): Pick<
  Components<typeof darkTheme>,
  "MuiAccordion" | "MuiAccordionSummary" | "MuiAccordionDetails"
> => ({
  MuiAccordion: {
    defaultProps: {
      disableGutters: true,
    },
    styleOverrides: {
      root: {
        borderRadius: "24px !important",
        overflow: "hidden",
        ":before": {
          display: "none",
        },
      },
    },
  },
  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: <ExpandMoreIcon />,
    },
    styleOverrides: {
      root: ({theme}) => ({
        paddingInline: 24,
        paddingBlock: 12,
        "&.Mui-expanded": {
          background: theme.palette.secondary.dark,
        },
      }),
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: 24,
      },
    },
  },
});
