import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiToolbar = (): Pick<
  Components<typeof darkTheme>,
  "MuiToolbar"
> => ({
  MuiToolbar: {
    styleOverrides: {
      root: {
        paddingInline: "40px !important",
      },
    },
  },
});
