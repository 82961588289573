import {FlamegraphTooltip} from "./FlamegraphTooltip.tsx";
import {TreeNode} from "./FlamegraphData.ts";
import {FlamegraphState} from "@components/Flamegraph/FlamegraphState.ts";
import {calculateColor} from "@components/Flamegraph/FlamegraphColors.ts";
import {CSSProperties, MouseEvent} from "react";
import {useTheme} from "@mui/material";

export function FocusedCellExtraInfo({
  node,
  state,
}: {
  node: TreeNode;
  state: FlamegraphState;
}) {
  const {root} = state;

  return (
    <>
      {node.file && (
        <div>
          {node.file}:{node.line}
        </div>
      )}
      <div>
        Reached here in {node.value} {state.unit} of {root.value} total
        {root.value > 0 && `(${(100 * (node.value / root.value)).toFixed(2)}%)`}
      </div>
    </>
  );
}

export function FlamegraphCell({
  node,
  state,
}: {
  node: TreeNode;
  state: FlamegraphState;
}) {
  const theme = useTheme();
  const isFocused = state.focusedNodes.has(node);
  const isFocusedSingle = state.focusedNodes.size == 1 && isFocused;
  const isFocusedInMulti = state.focusedNodes.size > 1 && isFocused;
  const isHighlighted = state.isSingleNodeHighlighted(node);

  const clickHandler = (event: MouseEvent<HTMLElement>) => {
    if (isFocusedInMulti && (event.ctrlKey || event.metaKey)) {
      // We'll remove this from the selection set
      const newFocusedNodes = [...state.focusedNodes].filter(
        (entry) => entry != node,
      );
      state.setFocusedEntry(newFocusedNodes);
      return;
    }
    state.setFocusedEntry(node);
  };

  const style: Partial<CSSProperties> = {
    background: isHighlighted
      ? "cornflowerblue"
      : calculateColor(node.getName()),
  };
  if (isFocused) {
    style.border = `2px solid ${theme.palette.text.primary}`;
  }

  return (
    <FlamegraphTooltip entry={node} state={state}>
      <div className={"flame-cell"} style={style} onClick={clickHandler}>
        {node.getName()}
        {isFocusedSingle && <FocusedCellExtraInfo node={node} state={state} />}
      </div>
    </FlamegraphTooltip>
  );
}
