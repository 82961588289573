import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiDrawer = (): Pick<
  Components<typeof darkTheme>,
  "MuiDrawer"
> => ({
  MuiDrawer: {
    styleOverrides: {
      paper: ({theme}) => ({
        backgroundColor: theme.palette.background.drawer,
        border: "none",
        borderTopRightRadius: "32px",
      }),
    },
  },
});
