import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiTableContainer = (): Pick<
  Components<typeof darkTheme>,
  "MuiTableContainer"
> => ({
  MuiTableContainer: {
    styleOverrides: {
      root: {
        borderRadius: 16,
      },
    },
  },
});
