export enum treeNodeType {
  VARIABLE,
  PARAMETER,
  RETURN_VALUE,
  STRUCT_FIELD,
}

export type treeNode = {
  name: string;
  nodeType: treeNodeType;
  fullExpr: string;
  // typeName is the name of the type of the variable or field represented by
  // this node. If it's undefined, that means that the node corresponds to an
  // expression that is not compatible with the current binary. In that case,
  // the tree will display a button for deleting the expression.
  typeName: string | undefined;
  // childrenNotLoaded, if set, means that the node might have children, but the
  // `children` field has not been populated. treeNode's start out with
  // childrenNotLoaded if they have children but the children should not be
  // visible because the tree node is not expanded. treeNode's with
  // childrenNotLoaded set get an arrow button that expands the node on click.
  childrenNotLoaded: boolean;
  // If children is undefined and childrenNotLoaded is false, then the node has
  // no children. Otherwise, the respective node in the tree will be rendered
  // with a button to expand it.
  children?: treeNode[];
  locListAvailable: boolean;
  // collected indicates if the expression represented by this node is being
  // collected. If the node represents a collected expression, the collected is
  // "yes". If one or more of the node's children are collected (i.e. the node's
  // expression is a prefix of a collected expression), then collected is
  // "partial". Otherwise, collected is "no".
  collected: "yes" | "no" | "partial";
  // alias indicates the field is optionally hoisted using given column name.
  alias?: string;
  // If note is set, it will be shown in an exclamation mark tooltip next to the
  // variable name.
  note: string | undefined;
};
