import React, {
  createContext,
  PropsWithChildren,
  Suspense,
  useState,
} from "react";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import {SelectorBinary} from "@components/SelectorBinary.tsx";

export const BinarySelectionDialogContext = createContext<
  (
    snapshotID?: number,
    logID?: number,
    funcQualifiedName?: string,
  ) => Promise<string | undefined>
>(undefined as never);

// useBinarySelectionDialog returns a function that, when called, renders a
// modal dialog asking the user to select a binary. The function returns a
// Promise that is resolved when the user either selects a binary in the dialog,
// or closes the dialog without selecting a binary.
export function useBinarySelectionDialog(): (
  snapshotID?: number,
  logID?: number,
  funcQualifiedName?: string,
) => Promise<string | undefined> {
  const showDialog = React.useContext(BinarySelectionDialogContext);
  if (showDialog === undefined) {
    throw new Error(
      "useBinarySelectionDialog must be used within a BinarySelectionDialogProvider",
    );
  }
  return showDialog;
}

// BinarySelectionDialogProvider is the provider underlying
// useBinarySelectionDialog(). It maintains state about whether the binary
// selection dialog should be shown or not.
export const BinarySelectionDialogProvider: React.FunctionComponent<
  PropsWithChildren
> = ({children}) => {
  type binarySelectionDialogInfo = {
    snapshotID?: number;
    logID?: number;
    funcQualifiedName?: string;
    onBinarySelected: (binaryID: string) => void;
    onDialogCanceled: () => void;
  };
  const [binarySelectionDialogState, setBinarySelectionDialogState] =
    useState<binarySelectionDialogInfo>();

  // showBinarySelectionDialog shows a dialog allowing the selection of a
  // binary. It returns a Promise that will be resolved when the user either
  // confirms or aborts the operation.
  //
  // Only one of snapshotID and logID can be set. If one of them is set,
  // funcQualified also needs to be set. In that case, the options for selection
  // will be the binaries corresponding to the specified snapshot/log that
  // contain a function with the given name.
  //
  // We update the state so that the dialog is shown and bind the result of the
  // dialog to the returned Promise.
  function showBinarySelectionDialog(
    snapshotID?: number,
    logID?: number,
    funcQualifiedName?: string,
  ): Promise<string | undefined> {
    if (snapshotID != undefined && logID != undefined) {
      throw new Error(
        "both snapshotID and logID must not be set at the same time",
      );
    }
    const idSet = snapshotID != undefined || logID != undefined;
    if (idSet != (funcQualifiedName != undefined)) {
      throw new Error("both an ID and funcQualifiedName must be set or unset");
    }

    // Return a promise that, when awaited, causes a re-render that show the
    // binary selection dialog.
    return new Promise<string | undefined>((accept, _reject) => {
      setBinarySelectionDialogState({
        snapshotID: snapshotID,
        logID: logID,
        funcQualifiedName: funcQualifiedName,
        onBinarySelected: (binaryID: string) => {
          accept(binaryID);
          // Clear the state to hide the dialog.
          setBinarySelectionDialogState(undefined);
        },
        onDialogCanceled: () => {
          accept(undefined);
          // Clear the state to hide the dialog.
          setBinarySelectionDialogState(undefined);
        },
      });
    });
  }

  return (
    <>
      <BinarySelectionDialogContext.Provider value={showBinarySelectionDialog}>
        {children}
      </BinarySelectionDialogContext.Provider>
      {/*The modal dialog forcing the user to select a binary.*/}
      {binarySelectionDialogState && (
        <Dialog
          open={true}
          onClose={() => {
            binarySelectionDialogState.onDialogCanceled();
          }}
        >
          <DialogTitle>Select a binary</DialogTitle>
          <DialogContent>
            <Suspense fallback={<div>Loading binaries...</div>}>
              <SelectorBinary
                setBinaryID={binarySelectionDialogState.onBinarySelected}
                snapshotID={binarySelectionDialogState.snapshotID}
                logID={binarySelectionDialogState.logID}
                funcQualifiedName={binarySelectionDialogState.funcQualifiedName}
                autoSelectSingleBinary={true}
              />
            </Suspense>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
