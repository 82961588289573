import {ApolloClient} from "@apollo/client";
import {TypeInfo, VarInfo} from "@graphql/graphql.ts";
import {gql} from "@graphql/gql";
import {treeNode, treeNodeType} from "@components/ExpressionTree";

export const TYPE_INFO = gql(/* GraphQL */ `
  query TypeInfo($binaryID: String!, $typeName: String!) {
    typeInfo(binaryID: $binaryID, name: $typeName) {
      Name
      Kind
      Redirect
      Fields {
        Name
        Type
        Embedded
      }
      FieldsNotLoaded
    }
  }
`);

export const ADD_OR_UPDATE_TYPE_SPEC = gql(/* GraphQL */ `
  mutation AddOrUpdateTypeSpec($input: TypeSpecInput!) {
    addOrUpdateTypeSpec(input: $input) {
      ...FullSnapshotSpec
      missingTypeQualifiedNames
    }
  }
`);

function resolveRedirect(
  typ: TypeInfo,
  types: Map<string, TypeInfo>,
): TypeInfo {
  while (typ.Redirect) {
    const next = types.get(typ.Redirect);
    if (next == undefined) {
      throw new Error(
        `error: redirect ${typ.Redirect} not found. redirect from: ${typ.Name}`,
      );
    }
    typ = next;
  }
  return typ;
}

export function typeToTreeNodes(
  typ: TypeInfo,
  parentExpr: string,
  locListAvailable: boolean,
  types: Map<string, TypeInfo>,
  collectedExprs: Map<string, string | undefined>,
  // idToNode gets populated with the created nodes.
  idToNode: Map<string, treeNode>,
): treeNode[] {
  typ = resolveRedirect(typ, types);
  if (typ.Fields == undefined) {
    return [];
  }
  const nodes = typ.Fields.map((field) =>
    newTreeNode(
      field.Name,
      treeNodeType.STRUCT_FIELD,
      (parentExpr != "" ? parentExpr + "." : "") + field.Name,
      field.Type,
      locListAvailable,
      undefined /* note */,
      types,
      collectedExprs,
      idToNode,
    ),
  );
  nodes.push(
    ...treeNodesForIncompatibleExpressions(
      parentExpr,
      collectedExprs,
      idToNode,
    ),
  );
  return nodes;
}

// newTreeNode creates a tree node from a variable or a fields of a struct.
export function newTreeNode(
  name: string,
  nodeType: treeNodeType,
  fullExpr: string,
  typeName: string | undefined,
  locListAvailable: boolean,
  note: string | undefined,
  // types can be undefined when typeName is undefined.
  types: Map<string, TypeInfo> | undefined,
  // Map from collected expr to optional hoisting alias
  collectedExprs: Map<string, string | undefined>,
  idToNode: Map<string, treeNode>,
): treeNode {
  let resolvedType: TypeInfo | undefined = undefined;
  let numFields = 0;
  if (typeName != undefined) {
    const typ = types!.get(typeName);
    if (typ == undefined) {
      throw new Error(`type "${typeName}" not found`);
    }
    resolvedType = resolveRedirect(typ, types!);
    if (!resolvedType.FieldsNotLoaded) {
      numFields = resolvedType.Fields!.length;
    }
  }
  const typeNotLoaded = resolvedType?.FieldsNotLoaded ?? false;

  const [collected, alias] = collectionStatus(fullExpr, collectedExprs);
  const node: treeNode = {
    name: name,
    nodeType: nodeType,
    fullExpr: fullExpr,
    typeName: typeName,
    // We set childrenNotVisible if the type has fields. If the type is not
    // loaded, we assume it has fields.
    childrenNotLoaded: typeNotLoaded || numFields > 0,
    // We're not loading children.
    children: undefined,
    locListAvailable,
    note: note,
    collected: collected,
    alias: alias,
  };

  idToNode.set(fullExpr, node);
  return node;
}

export type treeNodes = {
  tree: treeNode[];
  // idToNode maps expressions to nodes. All nodes in the tree, recursively, are
  // present in this map (not just the roots).
  idToNode: Map<string, treeNode>;
};

// varsToTree converts a function's parameters and variables to tree nodes.
function varsToTree(
  vars: VarInfo[],
  types: Map<string, TypeInfo>,
  collectedExprs: Map<string, string | undefined>,
): treeNodes {
  const dedupedVars = dedupFunctionVars(vars);
  const idToNode = new Map<string, treeNode>();

  const nodes: treeNode[] = [];
  for (const v of dedupedVars) {
    nodes.push(
      newTreeNode(
        v.Name,
        v.ReturnValue
          ? treeNodeType.RETURN_VALUE
          : v.FormalParameter
            ? treeNodeType.PARAMETER
            : treeNodeType.VARIABLE,
        v.Name /* fullExpr */,
        v.Type,
        v.LoclistAvailable,
        v.note,
        types,
        collectedExprs,
        idToNode,
      ),
    );
  }
  nodes.push(
    ...treeNodesForIncompatibleExpressions("", collectedExprs, idToNode),
  );

  return {
    tree: nodes,
    idToNode: idToNode,
  };
}

// treeNodesForIncompatibleExpressions creates nodes for collectedExprs that are
// children of a parent node (i.e. `parentExpr`) and do not have a corresponding
// node loaded. These represent fields that the spec expects but the debug info
// for this binary did not provide. We render and highlight such nodes in the
// tree in order to allow the user to de-select them.
//
// parentExpr can be "", in which case we'll look at each expression's first
// element.
export function treeNodesForIncompatibleExpressions(
  parentExpr: string,
  collectedExprs: Map<string, string | undefined>,
  idToNode: Map<string, treeNode>,
): treeNode[] {
  const newNodes: treeNode[] = [];
  const parentPrefix = parentExpr != "" ? parentExpr + "." : "";
  for (const expr of collectedExprs.keys()) {
    if (!expr.startsWith(parentPrefix)) {
      continue;
    }
    const remainingPath: string = expr.substring(parentPrefix.length);
    const firstElement = remainingPath.split(".")[0];
    const childExpr = parentPrefix + firstElement;
    if (!idToNode.has(childExpr)) {
      newNodes.push(
        newTreeNode(
          firstElement,
          treeNodeType.STRUCT_FIELD,
          childExpr,
          undefined /* typeName */,
          false /* locListAvailable */,
          "Field is missing in this binary." /* note */,
          undefined /* types */,
          collectedExprs,
          idToNode,
        ),
      );
    }
  }
  return newNodes;
}

function collectionStatus(
  fullExpr: string,
  collectedExprs: Map<string, string | undefined>,
): ["no" | "yes" | "partial", string | undefined] {
  if (collectedExprs.has(fullExpr)) {
    return ["yes", collectedExprs.get(fullExpr)];
  }
  for (const collected of collectedExprs.keys()) {
    if (collected.startsWith(fullExpr + ".")) {
      return ["partial", undefined];
    }
  }
  return ["no", undefined];
}

// buildTree builds the tree of variables and fields. The tree starts from type
// or functionVars, then the nodes corresponding to expandedExprs get their
// children populated.
export async function buildTree(
  binaryID: string,
  type: TypeInfo | undefined,
  functionVars: VarInfo[] | undefined,
  // expandedExprs is the list of node IDs (i.e. expressions) that have been
  // expanded. All expanded nodes will be loaded.
  expandedExprs: string[],
  // collectedExprs is a map from expressions that are currently being collected to
  // their optional hoisting alias. This controls the `collected` and `alias` properties
  //  of tree nodes.
  collectedExprs: Map<string, string | undefined>,
  // typesMap is the map to type information that has already been loaded. The map
  // is mutable; more types may be added to it (e.g. on the initial load, or
  // subsequently when a node is expanded and its type had not previously been
  // loaded). An empty map can be passed in, in which case it will be initialized
  // from typeFields or frameVars.
  typesMap: Map<string, TypeInfo>,
  client: ApolloClient<object>,
): Promise<treeNodes> {
  if (!type && !functionVars) {
    throw new Error("neither type nor functionVars provided");
  }

  let tree: treeNodes = {
    tree: [],
    idToNode: new Map<string, treeNode>(),
  };

  // First, populate the roots of the tree.

  // If we're displaying a type, the tree has that type's fields as roots.
  if (type) {
    tree.tree = typeToTreeNodes(
      type,
      "", // parentExpr
      true, // loclistAvailable
      typesMap,
      collectedExprs,
      tree.idToNode,
    );
  } else {
    // If we're displaying a function's variables, the tree has the variables as
    // roots.
    tree = varsToTree(functionVars!, typesMap, collectedExprs);
  }

  // Then, populate the expanded nodes. If a node is present in the
  // expandedExprs collection, then we make sure that its children are loaded.
  // Note that a node might be in expandedExprs even if it doesn't actually
  // appear on screen - e.g. foo.bar might be in expandedExprs, but foo might
  // not be (if the user expanded both foo and foo.bar, and then collapsed foo).
  // We load the children of foo.bar anyway, for simplicity.

  const loadChildren = async (id: string): Promise<treeNode[]> => {
    const node = tree.idToNode.get(id);
    if (node == undefined) {
      throw new Error(`error: node not found for id: ${id}`);
    }

    // Nodes with unknown types (i.e. nodes corresponding to expressions that
    // are not valid for the current binary) cannot be expended.
    if (node.typeName == undefined) {
      return [];
    }

    const typeInfo = await ensureTypeLoaded(
      binaryID,
      node.typeName,
      typesMap,
      client,
    );
    return typeToTreeNodes(
      typeInfo,
      node.fullExpr,
      node.locListAvailable, // if the parent is available, so is the child
      typesMap,
      collectedExprs,
      tree.idToNode,
    );
  };

  // Sort the expanded expressions by length, so that foo comes before foo.bar.
  // We then load the children of the respective nodes in order, thus ensuring
  // that we find foo.bar when we want to load its children.
  expandedExprs.sort((a, b) => (a.length < b.length ? -1 : 1));
  for (const expr of expandedExprs) {
    const node = tree.idToNode.get(expr);
    if (node == undefined) {
      // A node that's supposed to be expanded is not present in the tree. This
      // must mean that an ancestor is not expanded, so we don't need to load
      // the children of this node either.
      continue;
    }

    const children = await loadChildren(expr);
    node.childrenNotLoaded = false;
    node.children = children;
  }

  return tree;
}

// ensureTypeLoaded ensures that `types` contains the info for the type named
// `type`, populating it if it doesn't. If the type is a "redirect", the result
// of the redirect is also loaded.
async function ensureTypeLoaded(
  binaryID: string,
  type: string,
  types: Map<string, TypeInfo>,
  client: ApolloClient<object>,
): Promise<TypeInfo> {
  await ensureTypeLoadedInner(binaryID, type, types, client);
  let typeInfo = types.get(type);
  if (!typeInfo) {
    throw new Error(`error: type ${type} not found after loading`);
  }
  // Types *Foo and Foo are generally loaded at the same time. However, it is
  // possible that we didn't descend into *Foo because of the recursion limit.
  // In that case, we still have both *Foo and Foo in `types`, but Foo might
  // have `FieldsNotLoaded` set. Thus, we need to follow the redirect and load
  // *Foo.
  typeInfo = resolveRedirect(typeInfo, types);
  return ensureTypeLoadedInner(binaryID, typeInfo.Name, types, client);
}

// ensureTypeLoadedInner ensures that `types` contains the info for the type
// named `type`. It does not deal with redirects.
async function ensureTypeLoadedInner(
  binaryID: string,
  type: string,
  types: Map<string, TypeInfo>,
  client: ApolloClient<object>,
): Promise<TypeInfo> {
  // Check if the type is already loaded.
  {
    const typeInfo = types.get(type);
    if (typeInfo && !typeInfo.FieldsNotLoaded) {
      return typeInfo;
    }
  }
  return doLoadType(binaryID, type, types, client);
}

// doLoadType performs a GQL call to load a type; the resulting info is added to
// the cache before being returned.
async function doLoadType(
  binaryID: string,
  type: string,
  types: Map<string, TypeInfo>,
  client: ApolloClient<object>,
): Promise<TypeInfo> {
  // We need to load the type information for the node's type.
  console.log(`loading type ${type}`);
  const res = await client.query({
    query: TYPE_INFO,
    variables: {
      binaryID: binaryID,
      typeName: type,
    },
  });
  // Add the loaded types to the cache.
  res.data.typeInfo.map((typeInfo) => {
    maybeAddType(types, typeInfo);
  });

  const typeInfo = res.data.typeInfo[0];
  console.log(`loaded type ${type}:`, typeInfo);
  return typeInfo;
}

export function maybeAddType(
  typesMap: Map<string, TypeInfo>,
  typeInfo: TypeInfo,
) {
  const existing = typesMap.get(typeInfo.Name);
  if (!existing || existing.FieldsNotLoaded) {
    typesMap.set(typeInfo.Name, typeInfo);
  }
}

type VarWithNote = VarInfo & {note?: string};

// Deduplicate variables with the same name. If there are multiple variables
// with the same name, the first one will remain and get a note.
//
// See https://github.com/DataExMachina-dev/side-eye/issues/586 asking for better
// handling of vars with the same name.
function dedupFunctionVars(vars: VarInfo[]): VarWithNote[] {
  // Map from variable name to type names that variables with that name have.
  const typesForVars = new Map<string, {firstIdx: number; multiple: boolean}>();
  for (const [i, v] of vars.entries()) {
    if (!typesForVars.has(v.Name)) {
      typesForVars.set(v.Name, {
        firstIdx: i,
        multiple: false,
      });
      continue;
    }
    const info = typesForVars.get(v.Name)!;
    info.multiple = true;
  }

  const res: VarWithNote[] = [];
  // Dedup variables with the same name. The remaining var from a set of vars
  // with the same name will have a note.
  for (const [i, v] of vars.entries()) {
    const info = typesForVars.get(v.Name)!;
    if (i != info.firstIdx) {
      continue;
    }
    if (!info.multiple) {
      res.push(v);
      continue;
    }

    const note: string = `This function has multiple variables with the same
    name. If any data collection for this variable is requested, it will only
    apply to the first one; all the others will be ignored due to a current
    limitation of the debugger.`;
    res.push({...v, note});
  }
  return res;
}
