import {Box, Checkbox, Typography} from "@mui/material";
import React from "react";
import {
  Type_Kind,
  TypeInfo,
  TypeSpec,
  TypeSpecInput,
} from "src/__generated__/graphql.ts";
import {TYPE_INFO} from "src/components/available-vars-helpers.tsx";
import {useSuspenseQuery} from "@apollo/client";
import {StructSpecEditorInner} from "@components/StructSpecEditorInner";

type TypeSpecCardProps = {
  // spec is the current type spec.
  spec: TypeSpec;
  hoisting: boolean;
  // binaryID is the binary to be used for resolving the type.
  binaryID: string;
  addOrUpdateTypeSpec: (input: TypeSpecInput) => void;
};

// TypeSpecCard renders a type editor. For structs, an actual editor showing the
// struct's fields and sub-fields is rendered. For any other type, we simply
// render a message.
export function TypeSpecCard(props: TypeSpecCardProps): React.JSX.Element {
  const {data: types} = useSuspenseQuery(TYPE_INFO, {
    variables: {
      binaryID: props.binaryID,
      typeName: props.spec.typeQualifiedName,
    },
  });

  const typ = types.typeInfo[0];

  return typ.Kind == Type_Kind.Struct ? (
    <StructSpecEditor
      spec={props.spec}
      hoisting={props.hoisting}
      binaryID={props.binaryID}
      types={types.typeInfo}
      addOrUpdateTypeSpec={props.addOrUpdateTypeSpec}
    />
  ) : (
    <ScalarTypeSpecInfo kind={typ.Kind} />
  );
}

type StructSpecEditorProps = {
  spec: TypeSpec;
  binaryID: string;
  hoisting: boolean;
  // types is a preloaded list of types. It must include the struct type we're
  // editing.
  types: TypeInfo[];
  addOrUpdateTypeSpec: (input: TypeSpecInput) => void;
};

// StructSpecEditor renders the type spec editor for a struct type. It displays
// a "collect all" checkbox and otherwise delegates to StructSpecEditorInner for
// selecting individual fields.
function StructSpecEditor(props: StructSpecEditorProps): React.JSX.Element {
  function toggleCollectAll(collectAll: boolean) {
    props.addOrUpdateTypeSpec({
      ...props.spec,
      collectAll: collectAll,
      collectExprs: props.spec.collectExprs ?? [],
    });
  }

  const typ = props.types.find((t) => t.Name == props.spec.typeQualifiedName);
  if (!typ) {
    throw new Error("type not found: " + props.spec.typeQualifiedName);
  }
  if (typ.Kind != Type_Kind.Struct) {
    throw new Error("expected struct type, got " + typ.Kind);
  }
  // We show a warning if no fields are being collected.
  const showWarning =
    typ.Fields!.length > 0 && // If the struct has no type, there's no warning.
    !props.spec.collectAll &&
    (!props.spec.collectExprs || props.spec.collectExprs.length == 0);

  return (
    <>
      {showWarning && (
        <Box
          sx={{
            mb: 1,
            mr: 2,
            p: 1,
            boxShadow: 1,
            borderRadius: 1,
          }}
        >
          <Typography variant="warning">
            No fields are being collected. This might be want you want, but it's
            not common. If you want any data to be collected, either check
            "Collect all" or select specific fields. Alternatively, you can
            delete this type spec if you want the default behavior — everything
            to be collected when an object of this type is reached through a
            pointer, and nothing to be collected when an object of this type is
            wrapped in an interface.
          </Typography>
        </Box>
      )}
      <Checkbox
        checked={props.spec.collectAll}
        onChange={(e) => {
          toggleCollectAll(e.target.checked);
        }}
      />
      Collect all
      <br />
      Or collect specific fields:
      <br />
      <StructSpecEditorInner
        spec={props.spec}
        hoisting={props.hoisting}
        // If "collect all" is checked, the checkboxes on individual fields are
        // disabled.
        disabled={props.spec.collectAll}
        binaryID={props.binaryID}
        types={props.types}
        addOrUpdateTypeSpec={props.addOrUpdateTypeSpec}
      />
    </>
  );
}

function ScalarTypeSpecInfo(props: {kind: Type_Kind}): React.JSX.Element {
  return (
    <Typography>
      Type of kind: {props.kind} does not have sub-fields; it will be collected
      as per the default policy.
    </Typography>
  );
}
