import {
  execute,
  FetchResult,
  useApolloClient,
  useSuspenseQuery,
} from "@apollo/client";
import {gql} from "src/__generated__";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {Link as ReactLink, useParams} from "react-router-dom";
import {AppConfigContext} from "@providers/app-config-provider.tsx";
import _ from "lodash";
import {
  EventLogStatus,
  EventStreamMessage,
  StreamedEvent,
} from "@graphql/graphql.ts";
import {RingBuffer} from "ring-buffer-ts";
import {
  Box,
  Button,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {renderPossiblyJSONValue} from "@components/cell.tsx";
import EventFrequency from "@components/EventFrequency.tsx";
import HistogramPicker from "@components/HistogramPicker";
import EventsFlamegraph from "@components/EventsFlamegraph";

const GET_LOG_META = gql(/* GraphQL */ `
  query GetLogMeta($id: ID!) {
    getLog(id: $id) {
      id
      startTime
      durationMillis
      status
      specs {
        funcQualifiedName
      }
    }
  }
`);

const EVENTS_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription EventsSubscription($logID: ID!) {
    eventsSubscription(logID: $logID) {
      event {
        timestamp
        pid
        goroutineID
        message
        data
      }
      dropped
      throttled
    }
  }
`);

const STOP_LOG = gql(/* GraphQL */ `
  mutation StopLog($id: ID!) {
    stopLog(id: $id)
  }
`);

// LiveEventLog subscribes to a live event log and displays the events as they
// are received.
export default function LiveEventLog(): React.JSX.Element {
  const pathParams = useParams();
  const logID = parseInt(pathParams.logID!);
  return <LiveEventLogInner key={logID} logID={logID} />;
}

function LiveEventLogInner({logID}: {logID: number}): React.JSX.Element {
  const appCfg = useContext(AppConfigContext);
  const client = useApolloClient();
  const [events, setEvents] = useState<StreamedEvent[]>([]);
  const [streamErr, setStreamErr] = useState<unknown>(undefined);
  const [completed, setCompleted] = useState(false);
  const [liveLogID, setLiveLogID] = useState<number | undefined>(undefined);
  const [streamThrottled, setStreamThrottled] = useState(false);
  const [streamDroppedMessages, setStreamDroppedMessages] = useState(false);

  const {data: logRes} = useSuspenseQuery(GET_LOG_META, {
    variables: {id: logID},
  });
  const log = logRes.getLog;

  if (log.status != EventLogStatus.Recording) {
    if (!completed) {
      setCompleted(true);
    }
  } else {
    if (liveLogID == undefined) {
      setLiveLogID(logID);
    }
  }

  useEffect(() => {
    if (liveLogID == undefined) {
      return;
    }

    let streamThrottled = false;
    let streamDroppedMessages = false;
    const ringBuffer = new RingBuffer<StreamedEvent>(100);
    const throttledUpdate = _.throttle(
      () => {
        setEvents(ringBuffer.toArray());
      },
      100, // milliseconds
      {leading: true},
    );
    const sub = execute(appCfg.wsLink!, {
      query: EVENTS_SUBSCRIPTION,
      variables: {logID: liveLogID},
    }).subscribe({
      next: (result: FetchResult) => {
        if (result.errors) {
          console.log("subscription error", result.errors);
          setStreamErr(result.errors);
          return;
        }
        const data = result.data!;
        const msg = data.eventsSubscription as EventStreamMessage;
        if (msg.event) {
          ringBuffer.add(msg.event);
        }
        if (msg.throttled && !streamThrottled) {
          streamThrottled = true;
          setStreamThrottled(true);
        }
        if (msg.dropped && !streamDroppedMessages) {
          streamDroppedMessages = true;
          setStreamDroppedMessages(true);
        }
        throttledUpdate();
      },
      error(error: unknown) {
        console.error("stream error", error);
        setStreamErr(error);
      },
      complete() {
        console.debug("stream complete");
        setCompleted(true);
      },
    });
    return () => {
      sub.unsubscribe();
    };
  }, [liveLogID, appCfg.wsLink]);

  const stopLog = () => {
    void client.mutate({mutation: STOP_LOG, variables: {id: logID}});
  };

  if (streamErr) {
    if (streamErr instanceof Error) {
      return (
        <div>
          Subscription error
          <pre>{streamErr.message}</pre>
        </div>
      );
    }
    return (
      <div>
        Subscription error
        <pre>{JSON.stringify(streamErr, null /* replacer */, 2)}</pre>
      </div>
    );
  }

  return (
    <>
      {completed ? (
        <Stack direction={"row"} gap={1}>
          <Typography>This event log is now complete.</Typography>
          <Link component={ReactLink} to={`/logs/${logID}`}>
            Go to complete view
          </Link>
        </Stack>
      ) : (
        <span>
          <Button
            color={"error"}
            onClick={() => stopLog()}
            variant={"contained"}
          >
            Stop
          </Button>
        </span>
      )}
      {streamThrottled && (
        <Typography variant={"caption"} color={"warning"}>
          This log has been throttled because it received too many events. Now
          receiving only up to 10 events per second; the others are dropped.
        </Typography>
      )}
      {streamDroppedMessages && (
        <Typography variant={"caption"} color={"warning"}>
          Events were dropped from this live view because streaming them to the
          browser was going too slow.
        </Typography>
      )}

      <Stack direction={"column"} spacing={2}>
        {/*The frequency and latency graphs, side by side.*/}
        <Stack
          direction={"row"}
          spacing={2}
          sx={{width: "100%", height: "400px"}}
        >
          <Box sx={{width: "50%", height: "100%"}}>
            <EventFrequency logID={logID} poll={!completed} />
          </Box>
          <Box sx={{width: "50%", height: "100%"}}>
            <HistogramPicker log={log} poll={!completed} />
          </Box>
        </Stack>

        <EventsFlamegraph log={log} poll={true} />

        <Table
          style={{
            marginTop: "10px",
            width: "100%",
            tableLayout: "fixed",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{width: "100px"}}>PID</TableCell>
              <TableCell style={{width: "100px"}}>GoroutineID</TableCell>
              <TableCell style={{width: "300px"}}>Timestamp</TableCell>
              <TableCell style={{width: "100%"}}>Message</TableCell>
              <TableCell style={{width: "100%"}}>Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event, i) => (
              <Fragment key={`${i}-header`}>
                <TableRow>
                  <TableCell>{event.pid}</TableCell>
                  <TableCell>{event.goroutineID}</TableCell>
                  <TableCell>{event.timestamp}</TableCell>
                  <TableCell>{event.message}</TableCell>
                  <TableCell>
                    {renderPossiblyJSONValue(
                      event.data,
                      undefined /* onExpand */,
                      0 /* defaultExpandedLevels */,
                    )}
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </Stack>
    </>
  );
}
