import {Button, Tooltip} from "@mui/material";
import {AuthenticatedDownloadLink} from "./util.tsx";
import {useContext} from "react";
import {IconDownload} from "./icons/IconDownload.tsx";
import {
  AppConfigContext,
  getApiUrlForPath,
} from "@providers/app-config-provider.tsx";

type Props = {
  // The snapshot whose database will be downloaded.
  snapshotID?: number;
  duckdbRecordingID?: number;
  logID?: number;

  // The name of the file to use for the downloaded database.
  fileName: string;

  tooltip: string;
};

export function DownloadDatabaseButton({
  snapshotID,
  duckdbRecordingID,
  logID,
  fileName,
  tooltip,
}: Props) {
  const appConfig = useContext(AppConfigContext);
  let url: string;
  if (snapshotID != undefined) {
    url = getApiUrlForPath(appConfig.APIBaseURL, `/db/${snapshotID}`);
  } else if (logID != undefined) {
    url = getApiUrlForPath(appConfig.APIBaseURL, `/log/${logID}`);
  } else if (duckdbRecordingID != undefined) {
    url = getApiUrlForPath(
      appConfig.APIBaseURL,
      `/duckdb/${duckdbRecordingID}`,
    );
  } else {
    throw new Error("One of snapshotID/duckdbSnapshotID/logID must be set");
  }
  return (
    <Tooltip title={tooltip}>
      <Button variant="outlined" color="info">
        <AuthenticatedDownloadLink url={url} filename={fileName}>
          {duckdbRecordingID == undefined ? (
            <IconDownload />
          ) : (
            <img src={"/duckdb.png"} />
          )}
        </AuthenticatedDownloadLink>
      </Button>
    </Tooltip>
  );
}
