import {GetLogMetaQuery} from "@graphql/graphql";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";

type clearableExtension<clearable extends boolean> = clearable extends true
  ? undefined
  : never;

export default function FunctionInLogSpecSelector<clearable extends boolean>({
  log,
  clearable,
  value,
  onChange,
}: {
  log: GetLogMetaQuery["getLog"];
  clearable: clearable;
  value: string | clearableExtension<clearable>;
  onChange: (funcQualifiedName: string | clearableExtension<clearable>) => void;
}): React.JSX.Element {
  return (
    <Autocomplete
      disablePortal
      options={log.specs!}
      autoHighlight={true}
      renderInput={(params) => (
        <TextField color="secondary" {...params} label="Function" />
      )}
      getOptionLabel={(option) => option.funcQualifiedName}
      value={
        value == undefined
          ? null
          : log.specs!.find((spec) => spec.funcQualifiedName == value)
      }
      onChange={(_event, newValue) => {
        if (newValue == null) {
          if (!clearable) {
            throw new Error("clearable is false, but newValue is null");
          }
          onChange(undefined as clearableExtension<clearable>);
        } else {
          onChange(newValue.funcQualifiedName);
        }
      }}
      disableClearable={!clearable}
      handleHomeEndKeys={true}
    />
  );
}
