import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiBreadcrumbs = (): Pick<
  Components<typeof darkTheme>,
  "MuiBreadcrumbs"
> => ({
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        "*": {
          fontSize: 12,
          textDecoration: "none",
          color: "inherit",
        },

        /* hide separator if breadcrumb is empty */
        ".MuiBreadcrumbs-separator:has(+ * > *:empty)": {
          display: "none",
        },
      },
    },
    variants: [
      {
        props: {color: "primary.light"},
        style: {
          "*": {
            fontSize: 14,
          },
        },
      },
    ],
  },
});
