import {z} from "zod";
import {PaletteMode} from "@mui/material/styles/createPalette";
import {
  ProcessSelection,
  ProcessSelectionJSONSchema,
  RawProcessSelection,
} from "@components/ProcessSelector/helpers/processSelection.ts";
import {UNNAMED_ENV} from "src/constants/unnamed_env";

// The list of keys we use to store data in session storage (i.e. data
// associated with a browser tab).
export enum SessionStorageKeys {
  SelectedBinaryID = "selectedBinaryID",
  // Whether the menu is shown or hidden in the PageLayout. Value is a boolean.
  // boolean.
  MenuBarOpen = "menuBarOpen",
  // The selection for the CaptureSnapshot button. The value is a json-ified
  // environmentSelectionState.
  ProcessesSelectionStateForSnapshot = "processesSelectionState",
  Theme = "theme",
}

// Clear the session storage keys that are tied to the organization the user is
// logged into.
export function clearOrgDependentSessionStorage() {
  sessionStorage.removeItem(SessionStorageKeys.SelectedBinaryID);
  sessionStorage.removeItem(
    SessionStorageKeys.ProcessesSelectionStateForSnapshot,
  );
}

type serializedMap = {
  dataType: "Map";
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any[];
};

// jsonMapReplacer is meant to be used as the `replacer` argument to
// JSON.stringify(). It serializes Maps, which otherwise would be serialized as
// empty objects. jsonMapReviver should be used on corresponding JSON.parse()
// calls to unmarshal the Maps serialized by this function.
export function jsonMapReplacer(_key: string, value: unknown): unknown {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(value.entries()),
    };
  } else {
    return value;
  }
}

// jsonMapReviver should be used as the `reviver` argument to JSON.parse() when
// the JSON value was produced by JSON.stringify(<>, jsonMapReplacer).
export function jsonMapReviver(_key: string, value: unknown): unknown {
  if (
    typeof value === "object" &&
    value !== null &&
    value.hasOwnProperty("dataType") &&
    value["dataType"] == "Map"
  ) {
    const val = value as serializedMap;
    return new Map(val.value);
  }
  return value;
}

export function setMenuBarOpenInStorage(open: boolean) {
  sessionStorage.setItem(SessionStorageKeys.MenuBarOpen, open.toString());
}

export function getMenuBarOpenFromStorage(): boolean {
  const openFromStorage = sessionStorage.getItem(
    SessionStorageKeys.MenuBarOpen,
  );
  if (openFromStorage == null) {
    return true;
  }
  const res = z.enum(["true", "false"]).safeParse(openFromStorage);
  if (!res.success) {
    console.error(
      "error parsing menu bar open state from session storage",
      res.error,
    );
    sessionStorage.removeItem(SessionStorageKeys.MenuBarOpen);
    return true;
  }
  return res.data == "true";
}

export function setThemeTypeInStorage(themeType: PaletteMode) {
  sessionStorage.setItem(SessionStorageKeys.Theme, themeType);
}

export function getThemeTypeFromStorage(): PaletteMode {
  const rawValue = sessionStorage.getItem(SessionStorageKeys.Theme);
  const res = z.enum(["light", "dark"]).safeParse(rawValue);
  if (res.success) {
    return res.data;
  }
  return "dark";
}

export function setProcessesSelectionStateInStorage(
  key: SessionStorageKeys,
  selection: RawProcessSelection | undefined,
) {
  if (selection) {
    sessionStorage.setItem(key, JSON.stringify(selection));
  } else {
    sessionStorage.removeItem(key);
  }
}

// selectionFromSessionStorage reads and parses the process selection state from session storage.
export function selectionFromSessionStorage(
  sessionKey: SessionStorageKeys,
): RawProcessSelection | undefined {
  // Initialize the process selection from session storage.
  const sessionStateJSON = sessionStorage.getItem(sessionKey);
  if (!sessionStateJSON) {
    return undefined;
  }
  const parseResult = ProcessSelectionJSONSchema.safeParse(
    JSON.parse(sessionStateJSON, jsonMapReviver),
  );

  if (!parseResult.success) {
    console.error(
      "failed to parse stored process state",
      sessionStateJSON,
      parseResult.error,
    );
    return undefined;
  }
  return parseResult.data;
}
