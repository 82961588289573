import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {loadDevMessages, loadErrorMessages} from "@apollo/client/dev";
import {__DEV__} from "@apollo/client/utilities/globals";
import App from "./App.tsx";

/* dayjs lib require extra plugins to handle time zones */
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

declare module "@mui/material" {
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    mono?: React.CSSProperties;
    muted?: React.CSSProperties;
    mutedNormalSize?: React.CSSProperties;
    bold?: React.CSSProperties;
    explanation?: React.CSSProperties;
    warning?: React.CSSProperties;
    error?: React.CSSProperties;
  }

  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    mono: true;
    muted: true;
    mutedNormalSize: true;
    bold: true;
    explanation: true;
    warning: true;
    error: true;
  }

  interface PaperPropsVariantOverrides {
    dense: true;
  }

  interface TypeText {
    logo: string;
  }

  interface TypeBackground {
    component: string;
    drawer: string;
    sidebarFooter: string;
    gradient: string;
  }
}

if (__DEV__) {
  // Include the Apollo error messages in the app, instead of having them be links
  // to the Apollo docs. See https://www.apollographql.com/docs/react/errors/
  loadDevMessages();
  loadErrorMessages();
}

// Replace some old paths generated before we switched to hash paths. Issues
// generated by the roachtest integrations might have these old paths.
const pathname = window.location.pathname;
if (pathname.match("/snapshots(/d*)?")) {
  window.location.replace(
    window.location.href.replace("/snapshots", "#/snapshots"),
  );
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);
