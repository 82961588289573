import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiPaper = (): Pick<Components<typeof darkTheme>, "MuiPaper"> => ({
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        backgroundImage: "none",
      },
    },
  },
});
