import {BinariesArgs} from "./Binaries.tsx";
import {gql} from "@graphql/gql.ts";

export const GET_BINARIES = gql(/* GraphQL */ `
  query GetBinaries($ids: [String!]) {
    getBinaries(ids: $ids) {
      id
      userName
      programs
    }
  }
`);

export const FILTER_URL_PARAM = "filter";

export function updateBinariesFilter(
  queryParams: URLSearchParams,
  filter?: BinariesArgs,
): URLSearchParams {
  if (filter) {
    queryParams.set(FILTER_URL_PARAM, JSON.stringify(filter));
  } else {
    queryParams.delete(FILTER_URL_PARAM);
  }
  return queryParams;
}
