import {Stack, TextField} from "@mui/material";
import {HelpCircle} from "@components/HelpCircle.tsx";
import React from "react";
import {ProcessHierarchy} from "../helpers/processHierarchy.ts";
import Autocomplete from "@mui/material/Autocomplete";
import _ from "lodash";
import {UNNAMED_ENV} from "src/constants/unnamed_env";

type Props = {
  selectedEnv: string | typeof UNNAMED_ENV | undefined;
  onEnvironmentSelectionChange: (newEnv: string | typeof UNNAMED_ENV) => void;
  report: ProcessHierarchy;
};

export const ProcessSelectorEnvironment: React.FC<Props> = ({
  selectedEnv,
  onEnvironmentSelectionChange,
  report,
}: Props) => {
  const environments = _.sortBy(
    Array.from(report.environments.keys()),
    // Make the unnamed environment sort first.
    (x: string | typeof UNNAMED_ENV): string => (x == UNNAMED_ENV ? "___" : x),
  );

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Autocomplete
        disablePortal
        options={environments}
        sx={{minWidth: "30em"}}
        fullWidth
        renderInput={(params) => <TextField {...params} label="Environment" />}
        value={selectedEnv ?? null}
        onChange={(_, val) => {
          if (val == null) {
            // We set disableClearable when a value was selected, so we should
            // never get a null value.
            throw new Error("val expected to not be null");
          }
          onEnvironmentSelectionChange(val);
        }}
        // We want to accept a null value initially (and for this we need
        // disableClearable=false initially), but not after a value has been
        // set.
        disableClearable={!!selectedEnv}
        getOptionLabel={(option) =>
          option == UNNAMED_ENV ? "<environment not set>" : option
        }
      />

      <HelpCircle
        tip={
          `Select the environment for which to capture the snapshot.\n` +
          `Environments are independent sets of machines/agents as configured ` +
          `through the --environment flag passed to the agent.`
        }
      />
    </Stack>
  );
};
