import {isInteger, isSafeNumber} from "lossless-json";

export type ProgramCounter = number | bigint;

export function parseNumber(value: string): number | bigint {
  if (!isInteger(value)) {
    return parseFloat(value);
  }
  if (!isSafeNumber(value)) {
    return BigInt(value);
  }
  return parseInt(value);
}

export type ClickedFrame = {
  BinaryID: string;
  FuncQualifiedName: string;
  File: string;
  Line: number;
  Inlined: boolean;
  PC: ProgramCounter;
};
