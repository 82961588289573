import {useQuery} from "@apollo/client";
import {GET_HISTOGRAM} from "src/pages/EventLog";
import React from "react";
import DurationHistogramChart from "@components/DurationHistogramChart"; // LiveHistogram polls the server for a histogram of the latencies of events in

// LatencyHistogram polls the server for a histogram of the latencies of events
// in a given log.
export default function LatencyHistogram({
  logID,
  funcQualifiedName,
  numBuckets,
  poll,
}: {
  logID: number;
  // The function to show the histogram for. This must be one of the functions
  // for which the log includes events.
  funcQualifiedName: string;
  numBuckets: number;
  // If set, the histogram will poll the server for updates once a second.
  poll: boolean;
}): React.JSX.Element {
  const {
    loading,
    error,
    data: histogramRes,
  } = useQuery(GET_HISTOGRAM, {
    variables: {id: logID, funcQualifiedName, numBuckets},
    // Disable polling by setting it to a really high number. Switching from
    // 1000 -> undefined does not work:
    // https://github.com/apollographql/apollo-client/issues/11120
    pollInterval: poll ? 1000 : 100000000,
  });

  if (loading) {
    return <>Loading...</>;
  }
  if (error) {
    return <>Error: {error.message}</>;
  }
  const latencyHistogram = histogramRes!.getDurationHistogramForLog;

  return (
    <DurationHistogramChart
      histogram={latencyHistogram}
      seriesName={funcQualifiedName}
    />
  );
}
