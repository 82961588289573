import React from "react";
import {
  Card,
  CardContent,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import {Link as ReactLink} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {useMutation} from "@apollo/client";
import {DELETE_RECORDING, GET_RECORDINGS} from "./gqlHelper.ts";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {LogTimelineItem} from "./@types.ts";
import {Avatar} from "@components/Avatar.tsx";
import {toastError} from "src/components/tables/util";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

type Props = {
  log: LogTimelineItem;
};

export const EventLogItem: React.FC<Props> = ({log}) => {
  const [deleteRecording] = useMutation(DELETE_RECORDING, {
    refetchQueries: [GET_RECORDINGS],
  });

  let name = "Event log";
  if (log.environment) {
    name += `: ${log.environment}`;
  }
  name += " (" + log.programs.join(", ") + ")";

  return (
    <Card>
      <CardContent
        component={Stack}
        direction="row"
        justifyContent="space-between"
        gap={4}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          flexWrap="wrap"
          flexGrow={1}
        >
          <Typography variant="body2" color="primary.light" sx={{minWidth: 50}}>
            {log.id}
          </Typography>

          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Avatar photoURL={log.user?.photoURL ?? undefined} />
            <Typography variant="body2" color="textSecondary" width={200}>
              {log.user?.name ?? "<user unknown>"}
            </Typography>
            <Link component={ReactLink} to={`/logs/` + log.id}>
              {name}
            </Link>
          </Stack>

          <Typography variant="body2" color="textSecondary" width={300}>
            {dayjs(log.startTime).format("dddd, h:mm:ss A z")}
          </Typography>
        </Stack>

        <IconButton
          onClick={() => {
            void deleteRecording({variables: {id: log.recordingID}}).catch(
              (e) => toastError(e, "failed to delete recording"),
            );
          }}
        >
          <DeleteIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};
