import React from "react";
import {CssBaseline} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {AppConfigProvider} from "./providers/app-config-provider.tsx";
import {AgentReportsProvider} from "./providers/agent-reports-provider.tsx";
import {ConfirmationDialogProvider} from "./providers/confirmation-dialog.tsx";
import {BinarySelectionDialogProvider} from "./providers/binary-selection-dialog.tsx";
import {RouterProvider} from "react-router-dom";
import {router} from "./router";
import {SnapshotOpenerConfigProvider} from "src/templates/PageLayout/components/Header.tsx";
import {ThemeContextProvider} from "./theme/ThemeChangeContext.tsx";

const App: React.FC = () => {
  return (
    <ThemeContextProvider>
      <CssBaseline>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppConfigProvider>
            <AgentReportsProvider>
              <ConfirmationDialogProvider>
                <BinarySelectionDialogProvider>
                  <SnapshotOpenerConfigProvider>
                    <RouterProvider router={router} />
                  </SnapshotOpenerConfigProvider>
                </BinarySelectionDialogProvider>
              </ConfirmationDialogProvider>
            </AgentReportsProvider>
          </AppConfigProvider>
        </LocalizationProvider>
      </CssBaseline>
    </ThemeContextProvider>
  );
};

export default App;
