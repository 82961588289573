import {FormControlLabel, Switch, useTheme} from "@mui/material";
import React, {useContext} from "react";
import {ThemeChangeContext} from "../theme/ThemeChangeContext.tsx";
import {darkTheme, lightTheme} from "../theme/theme.ts";

export function ThemeSelectionToggle() {
  const theme = useTheme();
  const setThemeContext = useContext(ThemeChangeContext);

  return (
    <FormControlLabel
      sx={{color: theme.palette.text.primary}}
      control={
        <Switch
          checked={theme.palette.mode == "dark"}
          onChange={() => {
            const newTheme =
              theme.palette.mode == "dark" ? lightTheme : darkTheme;
            setThemeContext(newTheme);
          }}
        />
      }
      label="Dark Mode"
    />
  );
}
