import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiLink = (): Pick<Components<typeof darkTheme>, "MuiLink"> => ({
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: "none",
        ":hover": {
          color: "#535bf2",
        },
      },
    },
  },
});
