import React, {memo, PropsWithChildren} from "react";
import {IconButton, Stack, TableCell} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = PropsWithChildren & {
  iconSide: "LEFT" | "RIGHT";
  state: boolean;
  onClick: () => void;
};

export const ExpandableCell = memo(
  ({iconSide, state, onClick, children}: Props) => (
    <TableCell
      sx={{
        padding: "16px !important",
        ...(state && {
          borderColor: (theme) => theme.palette.primary.main + " ! important",
        }),
      }}
      onClick={onClick}
    >
      <Stack
        flexDirection="row"
        gap={1}
        justifyContent={iconSide == "LEFT" ? "left" : "space-between"}
        alignItems="center"
      >
        {iconSide == "RIGHT" && children}

        <IconButton>
          {state ? (
            <ExpandLessIcon color="primary" />
          ) : (
            <ExpandMoreIcon color="action" />
          )}
        </IconButton>

        {iconSide == "LEFT" && children}
      </Stack>
    </TableCell>
  ),
);
