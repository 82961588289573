import React from "react";
import {Box, Stack} from "@mui/material";
import {GetLogMetaQuery} from "@graphql/graphql";
import LatencyHistogram from "./LatencyHistogram";
import FunctionInLogSpecSelector from "@components/FunctionInLogSpecSelector";

// HistogramPicker renders a dropdown for selecting the name of a function in a
// log, and renders the histogram for that function's duration by using
// <LatencyHistogram>.
export default function HistogramPicker({
  log,
  poll,
}: {
  log: GetLogMetaQuery["getLog"];
  poll: boolean;
}): React.JSX.Element {
  const [selectedFunc, setSelectedFunc] = React.useState<string>(
    log.specs![0].funcQualifiedName,
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        direction={"column"}
        spacing={1}
        sx={{width: "100%", height: "100%"}}
      >
        <FunctionInLogSpecSelector
          log={log}
          clearable={false}
          value={selectedFunc}
          onChange={setSelectedFunc}
        />
        <LatencyHistogram
          logID={log.id}
          funcQualifiedName={selectedFunc}
          numBuckets={30}
          poll={poll}
        />
      </Stack>
    </Box>
  );
}
