import {DeepPartial} from "@apollo/client/utilities";
import {Binary, GoroutineId} from "src/__generated__/graphql.ts";
import {ProcessInfo} from "src/util/util.ts";

export class ProcessResolver {
  processes: ProcessInfo[];
  binaries: Map<string, DeepPartial<Binary>>;

  constructor(processes: ProcessInfo[], binaries: DeepPartial<Binary>[]) {
    this.processes = processes;
    this.binaries = new Map();
    binaries.forEach((b) => {
      this.binaries.set(b.id!, b);
    });
  }

  resolveGoroutineID(gid: GoroutineId): GoroutineSelector {
    const snap: ProcessInfo = this.resolveProcessIDOrThrow(gid.ProcessID);
    if (snap == undefined) {
      throw new Error(`missing process with ID: ${gid.ProcessID}`);
    }
    return {
      processFriendlyName: snap.FriendlyName,
      ID: gid.ID,
    };
  }

  resolveProcessIDOrThrow(processID: number): ProcessInfo {
    const proc = this.resolveProcessID(processID);
    if (proc == undefined) {
      throw new Error(`missing process with ID: ${processID}`);
    }
    return proc;
  }

  resolveProcessID(processID: number): ProcessInfo | undefined {
    return this.processes.find((p) => p.ProcessID == processID);
  }

  resolveBinary(binaryID: string): DeepPartial<Binary> | undefined {
    return this.binaries.get(binaryID);
  }

  resolveBinaryName(binaryID: string): string {
    const bin = this.resolveBinary(binaryID);
    if (!bin) {
      return "unknown";
    }
    return bin.userName!;
  }
}

// GoroutineSelector is a fully-qualified goroutine ID within a collection: a
// process ID and a goroutine ID within that process. GoroutineSelector is
// similar to GoroutineID, but with the process resolved to its human-readable
// name.
export type GoroutineSelector = {
  processFriendlyName: string;
  ID: number;
};
