import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";

export const MuiAutocomplete = (): Pick<
  Components<typeof darkTheme>,
  "MuiAutocomplete"
> => ({
  MuiAutocomplete: {
    defaultProps: {
      popupIcon: <KeyboardArrowDownIcon />,
    },
    styleOverrides: {
      root: {
        fontSize: 14,
        ".MuiOutlinedInput-root": {
          padding: "0 14px",
        },
      },
      popper: {
        backgroundColor: "transparent",
        paddingTop: 10,
      },
      listbox: ({theme}) => ({
        backgroundColor: theme.palette.background.component,
        paddingBlock: 0,
        ".MuiAutocomplete-option": {
          fontSize: 14,
          paddingBlock: 14,
        },
      }),
      noOptions: {
        fontSize: 14,
      },
    },
  },
});
