import React from "react";
import {useQuery} from "@apollo/client";
import {GET_STACKS_FOR_LOG} from "src/pages/EventLog/gql";
import {Flamegraph, ParseTreeData} from "@components/Flamegraph";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import {HelpCircle} from "@components/HelpCircle";
import FunctionInLogSpecSelector from "@components/FunctionInLogSpecSelector";
import {GetLogMetaQuery} from "@graphql/graphql";

export default function EventsFlamegraph({
  log,
  poll,
}: {
  log: GetLogMetaQuery["getLog"];
  poll: boolean;
}): React.JSX.Element {
  const [selectedFunc, setSelectedFunc] = React.useState<string | undefined>(
    undefined,
  );

  const {loading, error, data} = useQuery(GET_STACKS_FOR_LOG, {
    variables: {logID: log.id, funcQualifiedName: selectedFunc},
    // Disable polling by setting it to a really high number. Switching from
    // 1000 -> undefined does not work:
    // https://github.com/apollographql/apollo-client/issues/11120
    pollInterval: poll ? 3000 : 100000000,
  });

  const flamegraphData =
    data &&
    data.getStacksForLog &&
    ParseTreeData(data.getStacksForLog.treeJSON);

  return (
    <Accordion>
      <AccordionSummary>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          Flame graph
          <HelpCircle
            tip={`If stack traces were captured for events, this flame graph aggregates all the stacks.`}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <>Loading...</>
        ) : error ? (
          <>Error: {error.message}</>
        ) : !flamegraphData ? (
          <>No stacks collected</>
        ) : (
          <Stack direction={"column"} spacing={3}>
            <FunctionInLogSpecSelector
              log={log}
              clearable={true}
              value={selectedFunc}
              onChange={setSelectedFunc}
            />
            <Flamegraph
              root={flamegraphData}
              // !!! figure out these arguments so that the flame graph is interactive
              highlighterFilter={""}
              filters={[]}
              hiddenNodes={[]}
              focusedNodes={[]}
              unit={"events"}
              actions={{
                setSelectedFrame: () => {},
                setHidden: () => {},
                setFocused: () => {},
              }}
            />
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
