import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const MuiSelect = (): Pick<
  Components<typeof darkTheme>,
  "MuiSelect"
> => ({
  MuiSelect: {
    defaultProps: {
      IconComponent: KeyboardArrowDownIcon,
    },
  },
});
