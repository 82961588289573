import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Select,
  Stack,
  styled,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SortIcon from "@mui/icons-material/Sort";
import React, {Suspense, useContext, useState} from "react";
import {
  ApolloClient,
  SkipToken,
  skipToken,
  SuspenseQueryHookOptions,
  useApolloClient,
  useQuery,
  useSuspenseQuery,
} from "@apollo/client";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import {
  ColumnInfo,
  ColumnSpec,
  ColumnType,
  FunctionName,
  FunctionSnapshotSpec,
  FunctionSpec,
  FunctionStartEventSpec,
  GetTablesQuery,
  LinkEndpointSpec,
  QueryGetTablesArgs,
  Row,
  TableReference,
  TableSchema,
  TableSpec,
  TableSpecInput,
} from "src/__generated__/graphql.ts";
import {Settings, Terminal, Toc} from "@mui/icons-material";
import {Link, useSearchParams} from "react-router-dom";
import {getFunctionSpec, schemaInfo} from "src/util/spec.ts";
import {SpecContext} from "src/providers/spec-provider.tsx";
import SQLShell from "src/components/tables/sql-shell.tsx";
import QueryEditor from "./table-spec-editor.tsx";
import {format} from "sql-formatter";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {TablesFilter} from "src/components/tables/tables-filter.tsx";
import {WarningLabel} from "src/components/util.tsx";
import MyTableCell from "src/components/cell.tsx";
import EditableLabel from "src/util/editable-label.tsx";
import {
  exhaustiveCheck,
  funcOrMethodNameWithShortPkg,
  parseGoroutineID,
  ProcessInfo,
} from "src/util/util.ts";
import {
  addOrUpdateDerivedTableSpec,
  columnTypes,
  confirmationDialogInfo,
  DataMode,
  makeTablesParamUpdaters,
  tableMatchesFilter,
  tableMatchesString,
  TableMode,
  tablesAutocompleteOption,
  tablesURLController,
  toastError,
  VALIDATE_TABLE_UPDATE,
  warningMessageForTableValidationFail,
} from "src/components/tables/util.tsx";
import {useConfirmationDialog} from "src/providers/confirmation-dialog.tsx";
import {GET_SCHEMA, RUN_SQL_QUERY} from "@util/queries.tsx";
import {linksByColumn, resolvedLinksInfo} from "src/util/links.ts";
import {ProcessResolver} from "src/util/process-resolver.ts";
import {useBinarySelectionDialog} from "src/providers/binary-selection-dialog.tsx";
import {FunctionTableEditor} from "@components/FunctionTableEditor.tsx";
import {FunctionSpecEditor, specType} from "src/util/function-spec-editing.tsx";
import {useProcessResolver} from "@providers/processResolverProvider.tsx";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {DELETE_DERIVED_TABLE_SPEC, GET_TABLES} from "./gql.ts";
import {usePopupState} from "material-ui-popup-state/hooks";
import {SnapshotState, useSnapshotState} from "@providers/snapshot-state.tsx";

// Returns false if the user didn't confirm the deletion.
async function deleteTableSpec(
  client: ApolloClient<unknown>,
  tableID: string,
  tableName: string,
  showConfirmationDialog: (_: confirmationDialogInfo) => Promise<boolean>,
): Promise<boolean> {
  const {data: validationQueryRes} = await client.query({
    query: VALIDATE_TABLE_UPDATE,
    variables: {
      newTableSpec: {
        id: tableID,
        newTableSchema: undefined, // indicate a deletion
      },
    },
    fetchPolicy: "no-cache",
  });
  const validationResult = validationQueryRes.validateTableUpdate;
  const validationFailed =
    validationResult.tableValidationError ||
    validationResult.newlyFailingTables.length > 0 ||
    validationResult.newlyFailingLinks.length > 0;

  const dialogContent = (
    <>
      <Typography>
        Are you sure you want to delete table {tableName}?
      </Typography>
      {validationFailed && (
        <Typography>
          {warningMessageForTableValidationFail(
            validationResult.tableValidationError,
            validationResult.newlyFailingTables,
            validationResult.newlyFailingLinks,
          )}
        </Typography>
      )}
    </>
  );

  const confirmed: boolean = await showConfirmationDialog({
    title: "Confirm table deletion",
    content: dialogContent,
  });
  if (!confirmed) {
    // User changed their mind.
    return false;
  }

  await client.mutate({
    mutation: DELETE_DERIVED_TABLE_SPEC,
    variables: {tableID},
    refetchQueries: [GET_TABLES, GET_SCHEMA],
  });
  return true;
}

type TablesProps = {
  // snapshotID or logID, if set, indicate the snapshot or log to use for
  // generating the tables. If not specified, no table data will be displayed;
  // the table schemas can still be edited.
  snapshotID?: number;
  logID?: number;

  // controller, if specified, controls the URL state of the Tables component.
  // It exposes the current URL search params, and it provides methods for
  // computing URLs that change the Tables state.
  //
  // If not specified, Tables will manage its own state. It will still use the
  // URL search params for the state.
  controller?: tablesURLController;
};

// Tables renders the tables as a list of cards or, if a table is selected,
// renders that table.
export function Tables(props: TablesProps): React.JSX.Element {
  // Validate the props.
  if (props.snapshotID != undefined && props.logID != undefined) {
    throw new Error("only one of snapshotID or logID can be set");
  }

  // Either run GET_USER_TABLES or GET_SCHEMA, depending on whether snapshotID
  // was specified.
  let getTablesQueryArgs:
    | SuspenseQueryHookOptions<GetTablesQuery, QueryGetTablesArgs>
    | SkipToken;
  let getSchemaQueryArgs: SkipToken | Record<string, never>;
  if (props.snapshotID != undefined || props.logID != undefined) {
    getSchemaQueryArgs = skipToken;
    getTablesQueryArgs = {
      variables: {snapshotID: props.snapshotID, logID: props.logID},
      // Throw on errors (the default).
      errorPolicy: "none",
      // The data returned here consists of too many objects and overwhelms the
      // default cache limits. See
      // https://community.apollographql.com/t/slow-updates-with-large-cache/7356.
      fetchPolicy: "no-cache",
    };
  } else {
    getTablesQueryArgs = skipToken;
    getSchemaQueryArgs = {};
  }
  const {data: tablesRes} = useSuspenseQuery(GET_TABLES, getTablesQueryArgs);
  const {data: schemaRes} = useSuspenseQuery(GET_SCHEMA, getSchemaQueryArgs);

  const tables: TableSchema[] = tablesRes?.getTables ?? schemaRes!.getSchema;
  const schema = new schemaInfo(tables);

  const showConfirmationDialog = useConfirmationDialog();

  const [searchParams, setSearchParams] = useSearchParams();
  let controller: tablesURLController;
  if (props.controller) {
    controller = props.controller;
  } else {
    controller = new tablesURLController(
      searchParams,
      setSearchParams,
      makeTablesParamUpdaters(
        props.snapshotID != undefined || props.logID != undefined,
      ),
    );
  }

  // If a single table is selected, render that table.
  const {queryRef, dataMode} = controller.state;
  const selectedTableRef = queryRef?.selectedTable;
  if (selectedTableRef) {
    let table: TableSchema | undefined;
    switch (selectedTableRef.__typename) {
      case "BuiltinTableReference":
        table = tables.find(
          (t) =>
            t.Details.__typename == "BuiltinTableDetails" &&
            t.Name === selectedTableRef.TableName,
        );
        break;
      case "FunctionReference":
        table = tables.find(
          (t) =>
            t.Details.__typename === "FunctionTableDetails" &&
            t.Details.FuncName.QualifiedName ===
              selectedTableRef.FuncQualifiedName,
        );
        break;
      case "FunctionStartEventReference":
        table = tables.find(
          (t) =>
            t.Details.__typename === "EventsTableDetails" &&
            t.Details.FuncName.QualifiedName ===
              selectedTableRef.FuncQualifiedName,
        );
        break;
      case "DerivedTableReference":
        table = tables.find(
          (t) =>
            t.Details.__typename === "DerivedTableDetails" &&
            t.Details.Spec.id === selectedTableRef.TableID,
        );
        break;
      case undefined:
        throw new Error("unexpected undefined type name");
      default:
        exhaustiveCheck(selectedTableRef);
    }
    if (!table) {
      return <Typography>Table not found</Typography>;
    }
    return (
      <TableViewerOrEditor
        table={table}
        controller={controller}
        snapshotID={props.snapshotID}
        logID={props.logID}
        schema={schema}
      />
    );
  }

  // No table is selected; render the list of tables as a grid.
  return (
    <Stack direction="column" height={"100%"}>
      {dataMode == DataMode.SQL_SHELL && (
        <>
          <Stack direction="row" justifyContent="end" my={2}>
            <Tooltip title="List tables">
              <IconButton component={Link} to={controller.listTablesURL()}>
                <Toc color="secondary" />
              </IconButton>
            </Tooltip>
          </Stack>
          <SQLShell
            snapshotID={props.snapshotID}
            logID={props.logID}
            showTableValidationErrorConfirmationDialog={showConfirmationDialog}
            initialRun={true}
            initialQuery={`SELECT "a Festivus for the rest of us" AS my_column`}
            onNewTableAdded={(newTable: TableReference) => {
              controller.navigateToTable(newTable);
            }}
            controller={controller}
            schema={schema}
          />
        </>
      )}
      {dataMode == DataMode.TABLES && (
        <TablesGrid
          tables={tables}
          tablesOption={
            props.logID
              ? "events"
              : props.snapshotID
                ? "snapshot"
                : "all schema"
          }
          setSelectedTableURL={controller.showTableURL}
          addNewDerivedTableURL={controller.showSQLShellURL()}
          controller={controller}
        />
      )}
    </Stack>
  );
}

function TableViewerOrEditor(props: {
  table: TableSchema;
  snapshotID?: number;
  logID?: number;
  controller: tablesURLController;
  schema: schemaInfo;
}): React.JSX.Element {
  const editing = props.controller.state.tableMode == TableMode.SETTINGS;
  if (!editing) {
    return (
      <TableViewer
        table={props.table}
        snapshotID={props.snapshotID}
        logID={props.logID}
        controller={props.controller}
        editURL={props.controller.showTableSettingsURL()}
        schema={props.schema}
      />
    );
  } else {
    return (
      <TableEditor
        table={props.table}
        controller={props.controller}
        snapshotID={props.snapshotID}
        logID={props.logID}
        schema={props.schema}
      />
    );
  }
}

function TableViewer(props: {
  table: TableSchema;
  snapshotID?: number;
  logID?: number;
  controller: tablesURLController;
  editURL: string;
  schema: schemaInfo;
}): React.JSX.Element {
  const showConfirmationDialog = useConfirmationDialog();
  const table = props.table;

  const {queryRef} = props.controller.state;
  const query = queryRef!.query;

  // If this is the special "all_captured_data" table, group by the
  // "table_name".
  let grouping: string[] | undefined = undefined;
  if (
    table?.Details.__typename === "BuiltinTableDetails" &&
    table?.Name === "all_captured_data"
  ) {
    grouping = table.Columns.map((col, idx) => [col.Name, idx])
      .filter(([name, _]) => name === "table_name")
      .map(([_, idx]) => idx.toString());
    if (grouping.length === 0) {
      throw new Error("table_name column not found in all_captured_data");
    }
  }

  const tableKey = props.snapshotID
    ? `snap-${props.snapshotID}:${table.Name}`
    : `log-${props.logID}:${table.Name}`;

  return (
    // A stack with two rows - a header and then the table data.
    <Stack direction={"column"} spacing={1}>
      {/*The header with the table name*/}
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{width: "100%"}}
        py={3}
        gap={3}
        flexWrap="wrap"
      >
        <Button
          startIcon={<ArrowBackIosNewIcon />}
          component={Link}
          to={props.controller.listTablesURL()}
        >
          Back to tables list
        </Button>
        <Box sx={{ml: 2}} flexDirection={"row"} flexGrow={1}>
          <SelectedTableTitle
            table={table}
            enableNameEdit={false}
            showConfirmationDialog={showConfirmationDialog}
          />
        </Box>
        <Tooltip
          title={
            table.Details.__typename == "BuiltinTableDetails"
              ? "Cannot edit a built-in table."
              : "Edit the table's schema."
          }
        >
          <span>
            <Button
              sx={{ml: 3}}
              variant={"contained"}
              startIcon={<Settings />}
              component={Link}
              to={props.editURL}
              disabled={table.Details.__typename == "BuiltinTableDetails"}
            >
              Edit table definition
            </Button>
          </span>
        </Tooltip>
      </Stack>
      {table.Details.__typename == "BuiltinTableDetails" && (
        <Typography>{table.Details.Note}</Typography>
      )}

      {props.table.Error && (
        <Typography color={"red"}>{props.table.Error}</Typography>
      )}

      <Suspense fallback={<div>Loading...</div>}>
        <SQLShell
          key={tableKey}
          snapshotID={props.snapshotID}
          logID={props.logID}
          initialQuery={query ?? "SELECT * FROM " + table.Name}
          initialRun={true}
          initialGrouping={grouping}
          showTableValidationErrorConfirmationDialog={showConfirmationDialog}
          onNewTableAdded={(newTable: TableReference) => {
            props.controller.navigateToTable(newTable);
          }}
          controller={props.controller}
          schema={props.schema}
        />
      </Suspense>
    </Stack>
  );
}

// TableEditor renders the editor for a frames table or a derived table.
function TableEditor({
  snapshotID,
  logID,
  table,
  controller,
  schema,
}: {
  // snapshotID is the snapshot providing the context in which the table is
  // being edited. undefined if the table is not being edited in the context of
  // a snapshot. If specified, this is used for binary selection if/when a
  // binary is needed.
  snapshotID?: number;
  logID?: number;
  table: TableSchema;
  controller: tablesURLController;
  schema: schemaInfo;
}): React.JSX.Element {
  const client = useApolloClient();
  const spec = useContext(SpecContext);
  const showConfirmationDialog = useConfirmationDialog();

  // We might need to prompt the user for a binary ID. If we ever do, we'll
  // store that selection in binaryID.
  const [binaryID, setBinaryID] = useState<string | undefined>(undefined);
  const showBinarySelectionDialog = useBinarySelectionDialog();

  async function promptForBinarySelection(funcQualifiedName: string) {
    let binaryID: string | undefined;
    // showBinarySelectionDialog() requires either both an ID and a function
    // name to be passed in, or neither.
    if (snapshotID || logID) {
      binaryID = await showBinarySelectionDialog(
        snapshotID,
        logID,
        funcQualifiedName,
      );
    } else {
      binaryID = await showBinarySelectionDialog();
    }

    if (binaryID == undefined) {
      return;
    }
    setBinaryID(binaryID);
  }

  function innerForFunctionOrEventTable(table: TableSchema) {
    let functionSpec: FunctionSpec | undefined;
    let specEditor: FunctionSpecEditor<specType>;
    let tableSpec: FunctionStartEventSpec | FunctionSnapshotSpec;
    let specType: specType;
    if (table.Details.__typename == "FunctionTableDetails") {
      functionSpec = getFunctionSpec(
        spec,
        table.Details.FuncName.QualifiedName,
      );
      if (!functionSpec || functionSpec.snapshotSpec == undefined) {
        throw new Error(
          `didn't find frame spec for ${table.Details.FuncName.QualifiedName}`,
        );
      }
      tableSpec = functionSpec.snapshotSpec;
      specType = "snapshot";
      specEditor = new FunctionSpecEditor(
        "snapshot",
        functionSpec.funcName,
        tableSpec,
        showConfirmationDialog,
        client,
      );
    } else if (table.Details.__typename == "EventsTableDetails") {
      functionSpec = getFunctionSpec(
        spec,
        table.Details.FuncName.QualifiedName,
      );
      if (!functionSpec || functionSpec.functionStartEvent == undefined) {
        throw new Error(
          `didn't find event spec for ${table.Details.FuncName.QualifiedName}`,
        );
      }
      tableSpec = functionSpec.functionStartEvent;
      specType = "event";
      specEditor = new FunctionSpecEditor(
        "event",
        functionSpec.funcName,
        tableSpec,
        showConfirmationDialog,
        client,
      );
    } else {
      throw new Error("unexpected table details type");
    }

    return (
      <Box>
        <FunctionTableEditor
          labels={{
            variablesLabel: "Captured variables",
            variablesTooltip: `The set of expressions that are evaluated and collected
                    in a snapshot when this function is encountered on a goroutine's
                    stack trace.`,
            capturedExprTooltip: `The name of the column representing this captured
                    expression in the function's data table.`,
            tableNameTooltip: `The table name under which this function's frames table is stored in
                    the snapshot database. This is the table name to use in SQL queries.`,
            extraColsTooltip: `Extra columns for the function's frames table, in addition to
                    the columns defined implicitly by the captured variables above. These
                    extra columns are defined using SQL expressions (commonly using JSONPath) evaluated on top
                    of the implicit columns (i.e. the expressions can reference these implicit columns;
                    the names of implicit columns containing dots should be quoted like "myVar.myField").`,
          }}
          binaryID={
            binaryID ??
            (() =>
              void promptForBinarySelection(
                functionSpec.funcName.QualifiedName,
              ))
          }
          funcQualifiedName={functionSpec.funcName.QualifiedName}
          specEditor={specEditor}
          tableSpec={tableSpec}
          specType={specType}
        />

        <Button
          color="error"
          onClick={() => {
            void (async () => {
              if (specType == "snapshot") {
                if (await specEditor.onSnapshotSpecDelete()) {
                  controller.navigateToTablesList();
                }
              } else {
                if (await specEditor.onEventSpecDelete()) {
                  controller.navigateToTablesList();
                }
              }
            })();
          }}
          variant={"outlined"}
          sx={{mt: 4}}
        >
          {specType == "snapshot"
            ? "Stop collecting data for this function in snapshots"
            : "Delete event spec"}
        </Button>
      </Box>
    );
  }

  let inner: React.JSX.Element;
  switch (table.Details.__typename) {
    case "DerivedTableDetails":
      // NOTE: Derived tables are specific to snapshots; there are no derived
      // tables for events.
      inner = (
        <DerivedTableEditor
          snapshotID={snapshotID}
          tableSpec={table.Details.Spec}
          columns={table.Columns}
          onTableDeleted={controller.navigateToTablesList}
          showConfirmationDialog={showConfirmationDialog}
          controller={controller}
          schema={schema}
        />
      );
      break;
    case "FunctionTableDetails":
      inner = innerForFunctionOrEventTable(table);
      break;
    case "EventsTableDetails":
      inner = innerForFunctionOrEventTable(table);
      break;
    case "BuiltinTableDetails":
      throw new Error("TableEditor unexpectedly used for built-in table");
    case undefined:
      throw new Error("unexpected undefined type name");
    default:
      exhaustiveCheck(table.Details);
  }

  return (
    // A stack with two rows - a header and then the table data.
    <Stack direction={"column"} spacing={1}>
      {/*The header with the table name*/}
      <Stack direction={"row"} alignItems={"center"} sx={{width: "100%"}}>
        <Button
          startIcon={<ArrowBackIosNewIcon />}
          component={Link}
          to={controller.showTableDataURL()}
        >
          Back to table data
        </Button>
        <Box sx={{ml: 2}} flexDirection={"row"} flexGrow={1}>
          <SelectedTableTitle
            table={table}
            enableNameEdit={true}
            showConfirmationDialog={showConfirmationDialog}
          />
        </Box>
      </Stack>
      {inner}
    </Stack>
  );
}

function DerivedTableEditor({
  snapshotID,
  tableSpec,
  columns,
  onTableDeleted,
  showConfirmationDialog,
  controller,
  schema,
}: {
  snapshotID?: number;
  tableSpec: TableSpec;
  // columns are the table's actual columns, as they were generated by running
  // the query (as opposed to the columns specified in the table spec).
  columns: ColumnInfo[];
  onTableDeleted: () => void;
  showConfirmationDialog: (_: confirmationDialogInfo) => Promise<boolean>;
  controller: tablesURLController;
  schema: schemaInfo;
}) {
  const [selectedTab, setSelectedTab] = useState<"results" | "columns">(
    "results",
  );
  const [updatedQuery, setUpdatedQuery] = useState<string | undefined>(
    undefined,
  );

  // The query that was last executed, if any.
  const [executedQuery, setExecutedQuery] = useState<string | undefined>(
    undefined,
  );

  function runQuery(query: string) {
    setExecutedQuery(query);
    setSelectedTab("results");
  }

  const client = useApolloClient();

  // onSave updates the table's query.
  const onSave = () => {
    addOrUpdateDerivedTableSpec(
      client,
      {
        ...tableSpec,
        query,
      },
      showConfirmationDialog,
    )
      .then(() => {
        setUpdatedQuery(undefined);
        setExecutedQuery(undefined);
      })
      .catch((err) => {
        toastError(err, "Failed to save table");
      });
  };
  const onDelete = () => {
    void deleteTableSpec(
      client,
      tableSpec.id,
      tableSpec.name,
      showConfirmationDialog,
    )
      .then((deleted: boolean) => {
        if (deleted) {
          onTableDeleted();
        }
      })
      .catch((err) => {
        toastError(err, "Failed to delete table");
      });
  };

  const query = updatedQuery || tableSpec.query;

  const runTooltip =
    snapshotID != undefined
      ? "Run the query"
      : "Run the query. We are not in a snapshot context so we will not get back " +
        "any data; running can still be useful for validating the schema.";

  return (
    <Box>
      Table {tableSpec.name} is defined as the results of query:
      <QueryEditor query={query || ""} setQuery={setUpdatedQuery} />
      <Stack direction={"row"}>
        <Button
          disabled={!updatedQuery}
          onClick={() => {
            onSave();
          }}
        >
          Save
        </Button>
        <Tooltip title={runTooltip}>
          <span>
            <Button onClick={() => runQuery(query)}>Run</Button>
          </span>
        </Tooltip>
        <Tooltip title={"Format the query"}>
          <Button
            onClick={() => {
              setUpdatedQuery(
                format(query, {
                  language: "sqlite",
                  keywordCase: "upper",
                  logicalOperatorNewline: "before",
                  indentStyle: "standard",
                  expressionWidth: 10,
                  denseOperators: true,
                }),
              );
            }}
          >
            Format
          </Button>
        </Tooltip>
        <Button color="error" onClick={onDelete}>
          Delete Table
        </Button>
      </Stack>
      <Tabs
        value={selectedTab}
        onChange={(_ev, value) =>
          setSelectedTab(value as "results" | "columns")
        }
      >
        <Tab label="Query results" value="results" />
        <Tab label="Edit columns" value="columns" />
      </Tabs>
      {selectedTab == "columns" && (
        <DerivedTableColumnsEditor
          columns={columns}
          tableSpec={tableSpec}
          showConfirmationDialog={showConfirmationDialog}
        />
      )}
      {selectedTab == "results" && (
        <>
          {executedQuery && (
            <QueryResults
              query={executedQuery}
              snapshotID={snapshotID}
              controller={controller}
              schema={schema}
            />
          )}
        </>
      )}
    </Box>
  );
}

// DerivedTableColumnsEditor renders the editor for the columns of a derived
// table.
function DerivedTableColumnsEditor(props: {
  // columns are the actual column of the table, as they were generated by
  // executing the table's query.
  columns: ColumnInfo[];
  // tableSpec is the table's spec. tableSpec.columns might not have specs for
  // all the `columns`, and it may also have extra columns that are no longer
  // relevant.
  tableSpec: TableSpec;
  showConfirmationDialog: (_: confirmationDialogInfo) => Promise<boolean>;
}): React.JSX.Element {
  const client = useApolloClient();
  type columnItem = {
    name: string;
    column: ColumnInfo | undefined;
    spec: ColumnSpec | undefined;
  };
  const columnItems: columnItem[] = [];
  for (const col of props.columns) {
    columnItems.push({
      name: col.Name,
      column: col,
      spec: props.tableSpec.columns?.find((spec) => spec.name === col.Name),
    });
  }
  // Track stale columns for which we have a spec but don't appear in the query.
  const extraColumnSpecs = props.tableSpec.columns?.filter(
    (colSpec) => !props.columns.find((col) => col.Name === colSpec.name),
  );
  for (const colSpec of extraColumnSpecs || []) {
    columnItems.push({name: colSpec.name, column: undefined, spec: colSpec});
  }

  // updateColumnType updates the type of a column. If the column doesn't exist
  // in the spec, it is created. Note that the column might not exist, as the
  // columns available to edit are the columns resulting from the query, and not
  // all of them necessarily have a corresponding spec.
  function updateColumnType(
    name: string,
    type: ColumnType,
    showConfirmationDialog: (_: confirmationDialogInfo) => Promise<boolean>,
  ) {
    const newCols = props.tableSpec.columns.some((col) => col.name === name)
      ? props.tableSpec.columns.map((col) =>
          col.name === name ? {...col, type} : col,
        )
      : [...props.tableSpec.columns, {name, type, expr: "", hidden: false}];
    const input: TableSpecInput = {
      ...props.tableSpec,
      columns: newCols,
    };
    void addOrUpdateDerivedTableSpec(client, input, showConfirmationDialog);
  }

  // updateColumnType updates the visibility of a column. If the column doesn't
  // exist in the spec, it is created. Note that the column might not exist, as
  // the columns available to edit are the columns resulting from the query, and
  // not all of them necessarily have a corresponding spec.
  function updateColumnHidden(name: string, hidden: boolean) {
    const newCols = props.tableSpec.columns?.some((col) => col.name === name)
      ? props.tableSpec.columns?.map((col) =>
          col.name === name ? {...col, hidden} : col,
        )
      : [
          ...(props.tableSpec.columns || []),
          {name, hidden, expr: "", type: ColumnType.String},
        ];
    const input: TableSpecInput = {
      ...props.tableSpec,
      columns: newCols,
    };
    void addOrUpdateDerivedTableSpec(
      client,
      input,
      undefined /* showConfirmationDialog - column visibility doesn't impact validation */,
    );
  }

  function onDeleteColumn(
    name: string,
    showConfirmationDialog: (_: confirmationDialogInfo) => Promise<boolean>,
  ) {
    return () => {
      const newCols = props.tableSpec.columns?.filter(
        (col) => col.name !== name,
      );
      const input: TableSpecInput = {
        ...props.tableSpec,
        columns: newCols,
      };
      void addOrUpdateDerivedTableSpec(client, input, showConfirmationDialog);
    };
  }

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{minWidth: "300px", maxWidth: "800px"}}
      >
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell width={"70%"}>Column name</TableCell>
              <TableCell width={"20%"} align="right">
                Data type
              </TableCell>
              <TableCell width={"10rem"} align="right">
                Hidden
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {columnItems.map(({name, column: colInfo, spec: colSpec}) => (
              <TableRow
                key={name}
                sx={{"&:last-child td, &:last-child th": {border: 0}}}
              >
                <TableCell component="th" scope="row">
                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography display={"inline"}>{name}</Typography>
                    {!colInfo && (
                      <>
                        <WarningLabel
                          text={"This column no longer exists in the table."}
                        />
                        <Button
                          onClick={onDeleteColumn(
                            name,
                            props.showConfirmationDialog,
                          )}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  <FormControl variant="standard" sx={{m: 1}}>
                    <Select
                      label="Type"
                      variant={"standard"}
                      value={colSpec?.type || ColumnType.String}
                      onChange={(event) => {
                        updateColumnType(
                          name,
                          event.target.value as ColumnType,
                          props.showConfirmationDialog,
                        );
                      }}
                    >
                      {columnTypes.map(([label, type]) => (
                        <MenuItem key={type} value={type}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    checked={colInfo?.Hidden ?? false}
                    onChange={(event) =>
                      updateColumnHidden(name, event.target.checked)
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

// QueryResults takes a SQL query, runs it, and renders its results in a
// UserDefinedTable. If snapshotID is specified, the query is run against the
// table generated for that snapshot; if it is not specified, the query is run
// against empty tables.
function QueryResults({
  query,
  snapshotID,
  controller,
  schema,
}: {
  query: string;
  snapshotID?: number;
  controller: tablesURLController;
  schema: schemaInfo;
}): React.JSX.Element {
  const snapshotState = useSnapshotState();
  const {data, error, loading} = useQuery(RUN_SQL_QUERY, {
    variables: {
      snapshotID,
      query,
    },
  });
  if (error) {
    return <Typography color="error">{error.message}</Typography>;
  }
  if (loading) {
    return <Typography>Loading...</Typography>;
  }
  if (!data) {
    throw new Error("unexpected missing response");
  }
  return (
    <UserDefinedTable
      table={data.runSQLQuery}
      // Reset the table state when the query changes.
      key={query}
      controller={controller}
      schema={schema}
      snapshotState={snapshotState}
    />
  );
}

function compareTables(a: TableSchema, b: TableSchema): number {
  function detailsRank(a: TableSchema): number {
    switch (a.Details.__typename) {
      case "FunctionTableDetails":
        return 0;
      case "DerivedTableDetails":
        return 1;
      case "BuiltinTableDetails":
        return 2;
      default:
        throw new Error("unexpected table details type");
    }
  }

  function compareWithComparators<T>(
    a: T,
    b: T,
    comparators: ((a: T, b: T) => number)[],
  ): number {
    for (const cmp of comparators) {
      const res = cmp(a, b);
      if (res !== 0) {
        return res;
      }
    }
    return 0;
  }

  return compareWithComparators(a, b, [
    (a, b) => detailsRank(a) - detailsRank(b),
    (a, b) => {
      if (
        a.Details.__typename !== "FunctionTableDetails" ||
        b.Details.__typename !== "FunctionTableDetails"
      ) {
        return 0;
      }
      return compareWithComparators(a.Details, b.Details, [
        (a, b) => a.ModuleName.localeCompare(b.ModuleName),
        (a, b) => a.FuncName.Package.localeCompare(b.FuncName.Package),
        (a, b) =>
          funcOrMethodNameWithShortPkg(a.FuncName).localeCompare(
            funcOrMethodNameWithShortPkg(b.FuncName),
          ),
      ]);
    },
  ]);
}

type tablesOption = "all schema" | "events" | "snapshot";

// TablesGrid renders the list of tables as a grid of cards.
function TablesGrid(props: {
  tables: TableSchema[];
  tablesOption: tablesOption;
  setSelectedTableURL: (_: TableReference) => string;
  addNewDerivedTableURL: string;
  controller: tablesURLController;
}) {
  const anyErrors = props.tables.some((table) => !!table.Error);
  const [showOnlyTablesWithErrors, setShowOnlyTablesWithErrors] =
    useState(false);
  const [hideTablesWithNoRows, setHideTablesWithNoRows] = useState(
    // If a toggle is visible, it defaults to hiding empty tables.
    props.tablesOption != "all schema",
  );
  const [filterValue, setFilterValue] = useState<
    tablesAutocompleteOption | string | null
  >(null);
  const [funcTablesSort, setFuncTablesSort] = useState<"spec" | "name">("spec");
  const popupState = usePopupState({
    variant: "popover",
    popupId: "funcTablesSortMenu",
  });

  // Filter tables according to the top toggles.
  let tables = props.tables.filter((t) => {
    if (t.Error) {
      // Tables with errors are never filtered out.
      return true;
    }
    if (showOnlyTablesWithErrors) {
      return false;
    }
    if (hideTablesWithNoRows) {
      return t.NumRows > 0;
    }
    return true;
  });
  // If there is a filter in the textbox, filter according to it.
  if (filterValue) {
    tables = tables.filter((table) => {
      if (typeof filterValue === "string") {
        return tableMatchesString(table, filterValue);
      } else {
        return tableMatchesFilter(table, filterValue);
      }
    });
  }

  if (funcTablesSort == "name") {
    tables = [...tables].sort(compareTables);
  }

  type tablesSection = {
    sectionName: string;
    tables: TableSchema[];
  };

  const functionTablesSection: tablesSection = {
    sectionName: "Function frame tables",
    tables: tables.filter(
      (table) => table.Details.__typename === "FunctionTableDetails",
    ),
  };
  const derivedTablesSection: tablesSection = {
    sectionName: "Derived tables",
    tables: tables.filter(
      (table) => table.Details.__typename === "DerivedTableDetails",
    ),
  };
  const eventTablesSection: tablesSection = {
    sectionName: "Event tables",
    tables: tables.filter(
      (table) => table.Details.__typename === "EventsTableDetails",
    ),
  };
  const builtinTablesSection: tablesSection = {
    sectionName: "Built-in tables",
    tables: tables.filter(
      (table) => table.Details.__typename === "BuiltinTableDetails",
    ),
  };
  let sections: tablesSection[];
  switch (props.tablesOption) {
    case "events":
      sections = [eventTablesSection, builtinTablesSection];
      break;
    case "snapshot":
      sections = [
        functionTablesSection,
        derivedTablesSection,
        builtinTablesSection,
      ];
      break;
    case "all schema":
      sections = [
        functionTablesSection,
        eventTablesSection,
        derivedTablesSection,
        builtinTablesSection,
      ];
      break;
    default:
      exhaustiveCheck(props.tablesOption);
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        width: "100%",
      }}
    >
      <Stack
        direction={"row"}
        alignItems="center"
        flexWrap="wrap"
        gap={3}
        my={2}
      >
        <Box flexGrow={1} sx={{minWidth: 300}}>
          <TablesFilter
            tables={props.tables}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            setSelectedTableURL={props.setSelectedTableURL}
          />
        </Box>

        <Box>
          {anyErrors && (
            <FormControlLabel
              control={
                <Switch
                  checked={showOnlyTablesWithErrors}
                  onClick={() =>
                    setShowOnlyTablesWithErrors(!showOnlyTablesWithErrors)
                  }
                />
              }
              label="Show only tables with errors"
            />
          )}
          {props.tablesOption != "all schema" && (
            <FormControlLabel
              control={
                <Switch
                  checked={hideTablesWithNoRows}
                  onClick={() => setHideTablesWithNoRows(!hideTablesWithNoRows)}
                />
              }
              label="Hide empty tables"
            />
          )}

          <Tooltip title="SQL query">
            <IconButton
              component={Link}
              to={props.controller.showSQLShellURL()}
            >
              <Terminal color="secondary" />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>

      {anyErrors && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
          gap={3}
        >
          <>
            <WarningAmberIcon sx={{color: "red"}} />
            <Typography color="error" variant="body3">
              Some tables were not generated successfully.
            </Typography>
          </>
        </Stack>
      )}

      {sections.map((section) => (
        <React.Fragment key={`${section.sectionName}`}>
          <Stack direction={"row"} gap={1}>
            <Typography sx={{my: 3}} variant="h2">
              {section.sectionName}
            </Typography>
            {section == functionTablesSection && (
              <>
                <Tooltip title="Sorting of function tables">
                  <IconButton {...bindTrigger(popupState)}>
                    <SortIcon color="secondary" />
                  </IconButton>
                </Tooltip>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      setFuncTablesSort("spec");
                      popupState.close();
                    }}
                    disabled={funcTablesSort == "spec"}
                  >
                    Sort by order of functions in the spec
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setFuncTablesSort("name");
                      popupState.close();
                    }}
                    disabled={funcTablesSort == "name"}
                  >
                    Sort by package/type/function name
                  </MenuItem>
                </Menu>
              </>
            )}
          </Stack>
          <Box
            display={"grid"}
            gridTemplateColumns={"repeat(auto-fill, 360px)"}
            gridAutoRows={"fit-content"}
            justifyContent={"flex-start"}
            gap={1.5}
            paddingBottom={5}
          >
            {section.tables.map((table) => (
              <TableItem
                table={table}
                key={table.Name}
                setSelectedTableURL={props.setSelectedTableURL}
              />
            ))}
            {section == derivedTablesSection && (
              <Box gridColumn={1} mt={2}>
                <Button
                  component={Link}
                  to={props.addNewDerivedTableURL}
                  variant="contained"
                  color="primary"
                  startIcon={<AddOutlinedIcon />}
                >
                  Add table
                </Button>
              </Box>
            )}
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
}

// funcOrMethodName returns [<type>.]<func name>.
function funcOrMethodName(fn: FunctionName): string {
  return fn.Type !== "" ? `${fn.Type}.${fn.Name}` : fn.Name;
}

function trimStartingSlash(s: string) {
  if (s.startsWith("/")) {
    return s.slice(1);
  } else {
    return s;
  }
}

const NoMaxWidthTooltip = styled(
  ({className, children, ...props}: TooltipProps) => (
    <Tooltip {...props} children={children} classes={{popper: className}} />
  ),
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

// TableItem renders the name and metadata of a table as a card in the tables
// gallery.
function TableItem({
  table,
  setSelectedTableURL,
}: {
  table: TableSchema;
  setSelectedTableURL: (tableReference: TableReference) => string;
}): React.JSX.Element {
  let tableURL: string;
  switch (table.Details.__typename) {
    case "DerivedTableDetails":
      tableURL = setSelectedTableURL({
        __typename: "DerivedTableReference",
        TableID: table.Details.Spec.id,
      });
      break;
    case "BuiltinTableDetails":
      tableURL = setSelectedTableURL({
        __typename: "BuiltinTableReference",
        TableName: table.Name,
      });
      break;
    case "FunctionTableDetails":
      tableURL = setSelectedTableURL({
        __typename: "FunctionReference",
        FuncQualifiedName: table.Details.FuncName.QualifiedName,
      });
      break;
    case "EventsTableDetails":
      tableURL = setSelectedTableURL({
        __typename: "FunctionStartEventReference",
        FuncQualifiedName: table.Details.FuncName.QualifiedName,
      });
      break;
    case undefined:
      throw new Error("table.Details.__typename is undefined");
    default:
      exhaustiveCheck(table.Details);
  }

  let errorMessage: React.JSX.Element | undefined;
  if (table.Error) {
    errorMessage = (
      <Tooltip title={table.Error} sx={{mt: 3}}>
        <Typography noWrap color="error" variant="body3">
          {table.Error}
        </Typography>
      </Tooltip>
    );
  }

  let tableNameBox: React.JSX.Element;
  let tableDetails: React.JSX.Element;
  switch (table.Details.__typename) {
    case "DerivedTableDetails":
      tableNameBox = (
        <Typography variant="subtitle1" color="primary">
          {table.Name}
        </Typography>
      );
      tableDetails = (
        <NoMaxWidthTooltip title={<pre>{table.Details.Spec.query}</pre>}>
          <Typography variant="muted">
            <i>Derived</i>
          </Typography>
        </NoMaxWidthTooltip>
      );

      break;
    case "BuiltinTableDetails":
      tableNameBox = (
        <Typography variant="subtitle1" color="primary">
          {table.Name}
        </Typography>
      );
      tableDetails = (
        <Tooltip title={table.Details.Note}>
          <Typography variant="muted">
            <i>Builtin</i>
          </Typography>
        </Tooltip>
      );
      break;
    case "FunctionTableDetails":
    case "EventsTableDetails": {
      const fn = table.Details.FuncName;
      const tableName = table.Details.CustomTableName
        ? table.Name
        : funcOrMethodNameWithShortPkg(fn);
      const moduleName = table.Details.ModuleName;
      const trimmedPackagePath = trimStartingSlash(
        fn.Package.replace(`${table.Details.ModulePackage}`, ""),
      );
      const packagePath =
        trimmedPackagePath === ""
          ? table.Details.ModulePackage
          : trimmedPackagePath;
      const trimmedFuncName = packagePath + "/" + funcOrMethodName(fn);

      tableNameBox = (
        <Stack flexGrow={1}>
          <Typography
            variant="subtitle1"
            color={table.Error ? "error" : "primary"}
            sx={{overflowWrap: "break-word"}}
          >
            {tableName}
          </Typography>
          <Typography variant="caption" mt="auto">
            {table.Details.CustomTableName ? "Function" : "Package"}:
          </Typography>
          <Typography noWrap variant="body3">
            {table.Details.CustomTableName ? trimmedFuncName : packagePath}
          </Typography>
        </Stack>
      );

      tableDetails = (
        <Tooltip title={table.Details.ModulePackage}>
          <Typography variant="muted">{moduleName}</Typography>
        </Tooltip>
      );

      break;
    }
    case undefined:
      throw new Error("table.Details.__typename is undefined");
    default:
      exhaustiveCheck(table.Details);
  }
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        // Highlight the tables with errors with a red border.
        border: table.Error ? "1px solid red" : "",
        textDecoration: "none",
        transition: "background .5s",
        ":hover": {
          background: (theme) => theme.palette.background.gradient,

          ".MuiDivider-root": {
            background: (theme) => theme.palette.primary.main,
          },
        },
      }}
      component={Link}
      to={tableURL}
    >
      <CardContent component={Stack} flexGrow={1}>
        <Stack flexGrow={1}>
          {tableNameBox}
          <Typography variant="caption" mt={2}>
            Columns:
          </Typography>
          <Typography noWrap variant="body3">
            {table.Columns.filter((col) => !col.Hidden)
              .map((col) => col.Name)
              .join(", ")}
          </Typography>
        </Stack>

        {errorMessage}

        <Divider sx={{my: 2}}></Divider>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2">
            {tableDetails && tableDetails}
          </Typography>

          <Typography variant="body4" color="textSecondary">
            {table.NumRows} rows
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

// SelectedTableTitle renders the name of a table in the header of the table
// viewer.
function SelectedTableTitle({
  table,
  enableNameEdit,
  showConfirmationDialog,
}: {
  table: TableSchema;
  showConfirmationDialog: (_: confirmationDialogInfo) => Promise<boolean>;
  enableNameEdit: boolean;
}): React.JSX.Element {
  const client = useApolloClient();

  switch (table.Details.__typename) {
    case "DerivedTableDetails": {
      const tableSpec = table.Details.Spec;
      const columnSpecs: ColumnSpec[] = tableSpec.columns || [];
      return (
        <Stack direction={"row"} alignItems={"center"}>
          <EditableLabel
            label={"Table name:"}
            text={table.Name}
            disableEdit={!enableNameEdit}
            editTooltip={"Edit table name"}
            allowEmpty={false}
            onSave={async (newName) => {
              const res = await addOrUpdateDerivedTableSpec(
                client,
                {
                  id: tableSpec.id,
                  name: newName,
                  query: tableSpec.query,
                  columns: columnSpecs,
                },
                showConfirmationDialog,
              );
              return res != false;
            }}
          />
        </Stack>
      );
    }
    case "BuiltinTableDetails":
      return (
        <Tooltip title={table.Details.Note}>
          <span>{table.Name}</span>
        </Tooltip>
      );
    case "FunctionTableDetails":
    case "EventsTableDetails": {
      const tableName = table.Details.FuncName;
      return (
        <Box>
          <Tooltip title={tableName.QualifiedName}>
            <span>
              {table.Details.__typename == "EventsTableDetails" &&
                "Events for: "}
              <Typography variant="muted">Package: </Typography>
              {tableName.Package}
              {tableName.Type && (
                <>
                  <Typography variant="muted" sx={{ml: 1}}>
                    Type:{" "}
                  </Typography>
                  {`${tableName.Type}`}
                </>
              )}
              <Typography variant="muted" sx={{ml: 1, mr: 1}}>
                Function:
              </Typography>
              {tableName.Name}
            </span>
          </Tooltip>
        </Box>
      );
    }
    case undefined:
      throw new Error("table.Details.__typename is undefined");
    default:
      exhaustiveCheck(table.Details);
  }
}

type columnsInfo = {
  cols: MRT_ColumnDef<Row>[];
  initialColumnVisibility: {[key: string]: boolean};
};

// buildColumns transforms from a list of column names to the MaterialReactTable
// column definitions which access the respective fields from rows by the column
// index.
//
// spec is used to resolve table names in links.
//
// onExpand is called when the user expands or collapses a JSON field.
function buildColumns(
  columns: ColumnInfo[],
  links: LinkEndpointSpec[],
  onExpand: (rowIndex: number, expanded: boolean) => void,
  controller: tablesURLController,
  schema: schemaInfo,
  processResolver: ProcessResolver,
  snapshotState: SnapshotState | undefined,
): columnsInfo {
  const colVisibility: {[key: string]: boolean} = {};
  // rowToLinks will accumulate the links for each column in each row. It is
  // populated lazily: the entry for a row is created by the first column in the
  // row to be processed by the Cell function below.
  const rowToLinks = new Map<Row, resolvedLinksInfo[][]>();
  const rowToProcess = new Map<Row, ProcessInfo>();

  const goroutineColsIdx = [] as number[];
  for (const [i, col] of columns.entries()) {
    if (col.Type == ColumnType.GoroutineId) {
      goroutineColsIdx.push(i);
    }
  }
  let goroutineColIdx: number | undefined;
  if (goroutineColsIdx.length == 1) {
    goroutineColIdx = goroutineColsIdx[0];
  }

  const processColsIdx = [] as number[];
  for (const [i, col] of columns.entries()) {
    if (col.Type == ColumnType.ProcessId) {
      processColsIdx.push(i);
    }
  }
  let processColIdx: number | undefined;
  if (processColsIdx.length == 1) {
    processColIdx = processColsIdx[0];
  }

  const res = columns.map((col, i) => {
    const id = i.toString();
    if (col.Hidden) {
      colVisibility[id] = false;
    }
    return {
      id: id,
      header: col.Name,
      // This accessor function is used for filtering, sorting, and grouping. It
      // is not used for rendering (see Cell).
      accessorFn: (row: Row) => {
        if (row.ColumnValues[i] == null) {
          return "null";
        }
        return row.ColumnValues[i];
      },
      // Use "contains" instead of "fuzzy" for the filtering, because fuzzy
      // doesn't seem to work on our JSON fields.
      filterFn: "contains",
      Cell: (cell) => {
        const row = cell.row.original;
        if (!rowToLinks.has(row)) {
          rowToLinks.set(
            row,
            linksByColumn(row.Links, columns, row.ColumnValues, links, schema),
          );
        }
        if (!rowToProcess.has(row)) {
          let processID: number | undefined;
          if (goroutineColIdx != undefined) {
            const val = row.ColumnValues[goroutineColIdx];
            if (val != null) {
              const gid = parseGoroutineID(val);
              processID = gid.ProcessID;
            }
          }
          if (processID == undefined && processColIdx != undefined) {
            const val = row.ColumnValues[processColIdx];
            if (val != null) {
              processID = parseInt(val);
              if (isNaN(processID)) {
                processID = undefined;
              }
            }
          }
          if (processID != undefined) {
            const procInfo = processResolver.resolveProcessID(processID);
            if (procInfo != undefined) {
              rowToProcess.set(row, procInfo);
            }
          }
        }
        // Figure out which links are relevant to this cell/column.
        const linksByCol = rowToLinks.get(row)!;
        const colLinks: resolvedLinksInfo[] = linksByCol[i];
        const process: ProcessInfo | undefined = rowToProcess.get(row);

        return (
          <MyTableCell
            value={row.ColumnValues[i]}
            links={colLinks}
            name={col.Name}
            type={col.Type}
            onExpand={(expanded: boolean) => {
              onExpand(cell.row.index, expanded);
            }}
            controller={controller}
            processInfo={process}
            snapshotState={snapshotState}
          />
        );
      },
    } as MRT_ColumnDef<Row>;
  });
  return {cols: res, initialColumnVisibility: colVisibility} as columnsInfo;
}

export interface UserDefinedTableData {
  Error?: string;
  Columns: ColumnInfo[];
  Links: LinkEndpointSpec[];
  Rows: Row[];
}

// UserDefinedTable renders a table.
export function UserDefinedTable(props: {
  table: UserDefinedTableData;
  initialGrouping?: string[];
  controller: tablesURLController;
  schema: schemaInfo;
  snapshotState: SnapshotState | undefined;
}): React.JSX.Element {
  const processResolver = useProcessResolver();
  const [rowsAllowedToGrow, setRowsAllowedToGrow] = useState([] as number[]);
  const onCellExpand = (rowIndex: number, expanded: boolean) => {
    // When a user interacts with a row by expanding a JSON field, allow that
    // row to grow vertically indefinitely (i.e. exempt the row from the default
    // max height).
    if (!expanded) {
      // Something is being collapsed; nothing to do.
      return;
    }
    if (!rowsAllowedToGrow.includes(rowIndex)) {
      setRowsAllowedToGrow([...rowsAllowedToGrow, rowIndex]);
    }
  };

  const columns = buildColumns(
    props.table.Columns,
    props.table.Links,
    onCellExpand,
    props.controller,
    props.schema,
    processResolver,
    props.snapshotState,
  );
  const grouping = props.initialGrouping
    ? {grouping: props.initialGrouping}
    : undefined;

  // NOTE: This table causes a console warning in `React.StrictMode` that reads:
  // "Can't perform a React state update on a component that hasn't mounted
  // yet." I believe the relevant bug report is
  // https://github.com/TanStack/table/issues/5026.
  const mrt = useMaterialReactTable({
    columns: columns.cols,
    data: props.table.Rows,
    layoutMode: "grid",
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: false,
    enableStickyFooter: false,
    enableColumnFilters: true,
    enableColumnFilterModes: true,
    // Use "contains" instead of "fuzzy" for the global search, because fuzzy
    // doesn't seem to work on our JSON fields.
    globalFilterFn: "contains",
    initialState: {
      density: "compact",
      pagination: {pageIndex: 0, pageSize: 20},
      columnVisibility: columns.initialColumnVisibility,
      ...grouping,
    },
    defaultColumn: {
      muiTableBodyCellProps: {
        // Align items at the top of the cell. Depending on `layoutMode` above
        // being `grid` or `semantic`, either ``alignItems` or verticalAlign`
        // kicks in.
        sx: {verticalAlign: "top"},
        align: "left",
      },
    },
    muiToolbarAlertBannerChipProps: {color: "primary"},
    muiTableBodyRowProps: (props) => {
      return {
        sx: {
          maxHeight: rowsAllowedToGrow.includes(props.row.index)
            ? "auto"
            : "400px",
        },
        hover: false,
      };
    },
  });

  return (
    <Box>
      {props.table.Error && (
        <Box>
          <Typography color="error">
            This table was not successfully generated: {props.table.Error}
          </Typography>
        </Box>
      )}
      <MaterialReactTable table={mrt} />
    </Box>
  );
}
