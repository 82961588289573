import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiChip = (): Pick<Components<typeof darkTheme>, "MuiChip"> => ({
  MuiChip: {
    styleOverrides: {
      filled: ({theme}) => ({
        color: theme.palette.background.default,
        backgroundColor: theme.palette.primary.light,
      }),
      outlined: ({theme}) => ({
        color: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      }),
    },
  },
});
