import {BarChart, DefaultChartsAxisTooltipContent} from "@mui/x-charts";
import React from "react";
import {formatDurationNanos} from "@util/util.ts";
import {Histogram} from "@graphql/graphql.ts";
import {AxisValueFormatterContext} from "@mui/x-charts/internals";

// DurationHistogramChart generates a bar chart representing a latency
// histogram.
export default function DurationHistogramChart({
  histogram,
  seriesName,
}: {
  histogram: Histogram;
  seriesName: string;
}): React.JSX.Element {
  const numBuckets = 30;
  const bucketDurationNanos =
    (histogram.maxNanos - histogram.minNanos) / numBuckets;

  type axisData = {
    bucketStartNanos: number;
    bucketEndNanos: number;
    previousBucketEndNanos?: number;
  };

  let bucketStartNanos = histogram.minNanos;
  const buckets: axisData[] = [];
  const bucketValues: number[] = [];
  for (const count of histogram.counts) {
    buckets.push({
      bucketStartNanos: bucketStartNanos,
      bucketEndNanos: bucketStartNanos + bucketDurationNanos,
    });
    bucketValues.push(count);
    bucketStartNanos += bucketDurationNanos;
  }

  return (
    <BarChart
      xAxis={[
        {
          scaleType: "band",
          data: buckets,
          valueFormatter: (
            value: axisData,
            context: AxisValueFormatterContext,
          ): string => {
            if (context.location != "tooltip") {
              return formatDurationNanos(value.bucketStartNanos);
            }

            let start = formatDurationNanos(value.bucketStartNanos);
            let end = formatDurationNanos(value.bucketEndNanos);
            // If the start and the end have been humanized to the same value,
            // make an attempt to get more precision: if they're in the
            // millisecond range, add microsecond precision.
            if (start == end) {
              start = formatDurationNanos(
                value.bucketStartNanos,
                true /* forceMicros */,
              );
              end = formatDurationNanos(
                value.bucketEndNanos,
                true /* forceMicros */,
              );
            }
            return `${start} - ${end}`;
          },
          tickPlacement: "start",
          tickLabelPlacement: "tick",
          label: "Function duration",
        },
      ]}
      yAxis={[
        {
          scaleType: "linear",
          label: "Event count",
          // Don't add fractional ticks.
          tickMinStep: 1,
        },
      ]}
      series={[{data: bucketValues, label: seriesName}]}
      slotProps={{legend: {labelStyle: {fontSize: 12}}}}
      // Trigger the tooltip when hovering anywhere, not just over a bar. This is the default.
      tooltip={{trigger: "axis"}}
      slots={{axisContent: DefaultChartsAxisTooltipContent}}
    />
  );
}
