import {useApolloClient, useSuspenseQuery} from "@apollo/client";
import {GET_SCHEMA} from "@util/queries.tsx";
import React, {Key, useContext, useState} from "react";
import {SpecContext} from "@providers/spec-provider.tsx";
import {
  GetSchemaQuery,
  LinkSpecInput,
  SnapshotSpec,
  TableReference,
} from "@graphql/graphql.ts";
import {
  tableDetailsToTableReference,
  tableReferenceToInputID,
} from "@util/spec.ts";
import {ADD_OR_UPDATE_LINK} from "../gqlHelpers.ts";
import toast from "react-hot-toast";
import {
  AutocompleteRenderOptionState,
  Button,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Popper,
  Select,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  matchTableAutocompleteOptions,
  tablesAutocompleteOption,
  tablesToAutocompleteOptions,
  toastError,
} from "@components/tables/util.tsx";
import _ from "lodash";
import {exhaustiveCheck} from "@util/util.ts";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {BaseSelectProps} from "@mui/material/Select/Select";

// NewLinkCard renders that card that allows the user to add a new link to the
// spec. The card lets the user select source and destination tables, and
// provide a name and note for the link.
export function NewLinkCard() {
  const client = useApolloClient();
  const {data: getSchemaRes} = useSuspenseQuery(GET_SCHEMA);
  const schema = getSchemaRes.getSchema;
  const spec = useContext(SpecContext);
  const [newLink, setNewLink] = useState({
    srcTable: undefined as TableReference | undefined,
    srcCols: [] as string[],
    destTable: undefined as TableReference | undefined,
    destCols: [] as string[],
    note: "",
  });

  function setSourceTable(tableRef: TableReference | undefined) {
    setNewLink({...newLink, srcTable: tableRef, srcCols: []});
  }

  function setDestTable(tableRef: TableReference | undefined) {
    setNewLink({...newLink, destTable: tableRef, destCols: []});
  }

  function setSourceCols(cols: string[]) {
    setNewLink({...newLink, srcCols: cols});
  }

  function setDestCols(cols: string[]) {
    setNewLink({...newLink, destCols: cols});
  }

  async function addLink() {
    if (!newLink.srcTable || !newLink.destTable) {
      throw new Error("source and destination tables are required");
    }

    const newLinkInput: LinkSpecInput = {
      // We don't specify an id, so this mutation will result in a new link
      // being added.
      id: undefined,
      srcTableID: tableReferenceToInputID(newLink.srcTable),
      srcCols: newLink.srcCols,
      destTableID: tableReferenceToInputID(newLink.destTable),
      destCols: newLink.destCols,
      note: newLink.note,
    };

    await client
      .mutate({
        mutation: ADD_OR_UPDATE_LINK,
        variables: {
          input: newLinkInput,
        },
      })
      .then(() => {
        // Reset the state.
        setNewLink({
          srcTable: undefined,
          srcCols: [] as string[],
          destTable: undefined,
          destCols: [] as string[],
          note: "",
        });
      })
      .catch((err) => {
        toastError(err, "failed to add link");
      });
  }

  function validate(): [boolean, string | undefined] {
    if (!newLink.srcTable) {
      return [false, "Source table is required"];
    }
    if (newLink.srcCols.length == 0) {
      return [false, "Source columns are required"];
    }
    if (!newLink.destTable) {
      return [false, "Destination table is required"];
    }
    if (newLink.destCols.length == 0) {
      return [false, "Destination columns are required"];
    }
    if (newLink.srcCols.length != newLink.destCols.length) {
      return [
        false,
        "The same number of columns are required for source and target",
      ];
    }
    return [true, undefined];
  }

  const [valid, validationError] = validate();
  const saveTooltip = validationError ?? "Add the new link to the spec";
  const empty =
    newLink.srcCols.length == 0 &&
    newLink.destCols.length == 0 &&
    newLink.note == "" &&
    newLink.srcTable == undefined &&
    newLink.destTable == undefined;

  return (
    <Card color="default" variant="dense">
      <CardHeader title={"Add new link"} />
      <CardContent component={Stack} gap={2}>
        <TableAndCols
          label="Source"
          spec={spec}
          schema={schema}
          table={newLink.srcTable}
          cols={newLink.srcCols}
          setTable={setSourceTable}
          setCols={setSourceCols}
        />
        <TableAndCols
          label="Target"
          spec={spec}
          schema={schema}
          table={newLink.destTable}
          cols={newLink.destCols}
          setTable={setDestTable}
          setCols={setDestCols}
        />
        <Stack direction={"row"} gap={2} flexWrap="wrap">
          <StyledTypography variant="body2">Note</StyledTypography>
          <TextField
            sx={{flexGrow: 1, minWidth: 300}}
            color="secondary"
            placeholder="Description"
            value={newLink.note}
            onChange={(event) =>
              setNewLink({
                ...newLink,
                note: event.target.value,
              })
            }
          />
        </Stack>

        <Stack direction="row" justifyContent="flex-end">
          <Tooltip title={saveTooltip}>
            <span>
              <Button
                onClick={() => {
                  void addLink();
                }}
                disabled={!valid}
                startIcon={<AddOutlinedIcon />}
                color="primary"
                variant="contained"
                size="large"
                sx={{px: 8}}
              >
                Add
              </Button>
            </span>
          </Tooltip>
        </Stack>

        {validationError && !empty && (
          <Typography color="error" variant="body3">
            {validationError}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

type TableAndColsProps = {
  label: string;
  spec: SnapshotSpec;
  schema: GetSchemaQuery["getSchema"];
  table: TableReference | undefined;
  setTable: (newTable: TableReference | undefined) => void;
  cols: string[];
  setCols: (cols: string[]) => void;
};

function isOptionEqualToValue(
  opt: tablesAutocompleteOption,
  value: tablesAutocompleteOption,
): boolean {
  // Remove the `match` field before comparing.
  const {match: matchOpt, ...restOpt} = opt;
  const {match: matchValue, ...restValue} = value;

  return _.isEqual(restOpt, restValue);
}

const StyledTypography = styled(Typography)(() => ({
  width: "100px",
  minWidth: "100px",
  height: 48,
  lineHeight: "48px",
}));

function TableAndCols(props: TableAndColsProps): React.JSX.Element {
  const autocompleteOptions = tablesToAutocompleteOptions(props.schema, {
    includeModules: false,
    includeTypes: false,
    includePackages: false,
    includeColumns: false,
  });

  const tables = new Map<string, TableReference>();
  for (const table of props.schema) {
    switch (table.Details.__typename) {
      case "DerivedTableDetails":
        tables.set(table.Name, {
          __typename: "DerivedTableReference",
          TableID: table.Details.Spec.id,
        });
        break;
      case "FunctionTableDetails":
        tables.set(table.Name, {
          __typename: "FunctionReference",
          FuncQualifiedName: table.Details.FuncName.QualifiedName,
        });
        break;
      case "EventsTableDetails":
        // Events table do not participate in links, at least for now.
        break;
      case "BuiltinTableDetails":
        tables.set(table.Name, {
          __typename: "BuiltinTableReference",
          TableName: table.Name,
        });
        break;
      case undefined:
        throw new Error("table.Details.__typename is undefined");
      default:
        exhaustiveCheck(table.Details);
    }
  }

  let selectedTable: GetSchemaQuery["getSchema"][number] | undefined =
    undefined;
  if (props.table != undefined) {
    selectedTable = props.schema.find((t) => {
      const tRef = tableDetailsToTableReference(t.Details, t.Name);
      return _.isEqual(tRef, props.table);
    });
    if (!selectedTable) {
      throw new Error("table not found in schema");
    }
  }

  let selectedTableOption: tablesAutocompleteOption | null = null;
  if (props.table) {
    const opt = autocompleteOptions.find((opt) => {
      if (opt.type != "table") {
        return false;
      }
      return _.isEqual(opt.tableReference, props.table);
    });
    if (!opt) {
      throw new Error("table not found in autocomplete options");
    }
    selectedTableOption = opt;
  }

  const MyPopper = function (props) {
    return (
      <Popper
        {...props}
        // Make the max width larger than the Autocomplete's width.
        style={{width: "fit-content", maxWidth: "70rem"}}
        placement="bottom-start"
      />
    );
  };

  const columnTextFieldProps: BaseSelectProps = {
    disabled: !props.table,
    color: "secondary",
    fullWidth: true,
    displayEmpty: true,
  };

  return (
    <Stack direction="row" gap={2} flexWrap="wrap">
      <StyledTypography variant="body2">{props.label}</StyledTypography>
      <Autocomplete
        PopperComponent={MyPopper}
        sx={{flexGrow: 1, minWidth: 300}}
        options={autocompleteOptions}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select table" color="secondary" />
        )}
        // We take control over the filtering process so that we can do our own
        // matching of the query to the suggestions. Also, the suggestions
        // change in response to the query -- the options' `match` fields get
        // set.
        filterOptions={(options, state) =>
          matchTableAutocompleteOptions(options, state.inputValue)
        }
        renderOption={(
          props: React.HTMLAttributes<HTMLLIElement> & {key: Key},
          option: tablesAutocompleteOption,
        ) => option.render(props)}
        getOptionKey={(o) => o.key()}
        getOptionLabel={(o) => o.label()}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={(_, value: tablesAutocompleteOption | null) => {
          if (value == null) {
            props.setTable(undefined);
            return;
          }
          if (value.type != "table") {
            throw new Error("expected a table");
          }
          props.setTable(value.tableReference);
        }}
        value={selectedTableOption}
      />
      <Stack gap={1} width={380} ml="auto">
        {props.cols.length == 0 ? (
          // If there are no columns, start with a single column selector.
          <Select
            {...columnTextFieldProps}
            value={""}
            onChange={(event) => {
              props.setCols([event.target.value as string]);
            }}
          >
            {selectedTable && (
              <MenuItem value={""} disabled sx={{display: "none"}}>
                <Typography variant="body3" color="secondary">
                  Column
                </Typography>
              </MenuItem>
            )}

            {selectedTable?.Columns.map((col) => (
              <MenuItem value={col.Name} key={col.Name}>
                {col.Name}
              </MenuItem>
            )) ?? (
              <MenuItem value={""} disabled>
                Select a table first
              </MenuItem>
            )}
          </Select>
        ) : (
          // Render a column selector for each column in the state.
          props.cols.map((col, idx) => (
            <Stack direction="row" gap={1} key={idx}>
              <Select
                {...columnTextFieldProps}
                value={col}
                onChange={(event) => {
                  const newCols = [...props.cols];
                  newCols[idx] = event.target.value as string;
                  props.setCols(newCols);
                }}
              >
                {selectedTable && (
                  <MenuItem value={""} disabled sx={{display: "none"}}>
                    <Typography variant="body3" color="secondary">
                      Column
                    </Typography>
                  </MenuItem>
                )}

                {selectedTable?.Columns.map((col) => (
                  <MenuItem value={col.Name} key={col.Name}>
                    {col.Name}
                  </MenuItem>
                )) ?? (
                  <MenuItem value={""} disabled>
                    Select a table first
                  </MenuItem>
                )}
              </Select>

              <Tooltip title={"Delete column"}>
                <Button
                  color="info"
                  variant="outlined"
                  onClick={() => {
                    props.setCols(props.cols.toSpliced(idx, 1));
                  }}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Stack>
          ))
        )}
      </Stack>

      <Tooltip title={"Add column"}>
        <Button
          onClick={() => props.setCols([...props.cols, ""])}
          color="info"
          variant="outlined"
        >
          <AddIcon />
        </Button>
      </Tooltip>
    </Stack>
  );
}
