import {
  IconButton,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useContext, useState} from "react";
import {useApolloClient} from "@apollo/client";
import {SpecContext} from "@providers/spec-provider.tsx";
import {getProgram} from "@util/spec.ts";
import {IconEdit} from "@components/icons/IconEdit.tsx";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import {HelpCircle} from "@components/HelpCircle.tsx";
import {
  ADD_OR_UPDATE_PROGRAM_SPEC,
  DELETE_PROGRAM_SPEC,
} from "../gqlHelpers.ts";

type Props = {
  program: string;
  onSave?: () => void;
  asTableRow?: true;
};

// Renders the UI for editing the process friendly name spec - the format string
// and the expression.
export default function ProcessFriendlyNameComposer(
  props: Props,
): React.JSX.Element {
  const spec = useContext(SpecContext);
  const progSpec = getProgram(spec, props.program);
  const client = useApolloClient();

  const [editing, setEditing] = useState(false);
  const [format, setFormat] = useState(progSpec?.processFriendlyNameFmt ?? "");
  const [func, setFunc] = useState(
    progSpec?.processFriendlyNameFunctionQualifiedName ?? "",
  );
  const [expr, setExpr] = useState(progSpec?.processFriendlyNameExpr ?? "");

  // We only consider functions with a snapshot spec.
  const allFunctions = spec.modules
    .flatMap((m) => m.functionSpecs)
    .filter((f) => f.snapshotSpec != undefined);
  const selectedFunc = allFunctions.find(
    (f) => f.funcName.QualifiedName == func,
  );
  const exprsForSelectedFunction = selectedFunc?.snapshotSpec!.collectExprs;

  // dirty is set if this state differs from the database. The save button is
  // enabled when this is set.
  const [dirty, setDirty] = useState(false);

  // valid tracks whether the new settings can be saved.
  const valid = func && expr;

  async function save() {
    const {errors} = await client.mutate({
      mutation: ADD_OR_UPDATE_PROGRAM_SPEC,
      variables: {
        input: {
          programName: props.program,
          processFriendlyNameFmt: format,
          processFriendlyNameFunctionQualifiedName: func,
          processFriendlyNameExpr: expr,
        },
      },
    });
    if (errors) {
      console.error(errors);
      throw errors;
    }
    setEditing(false);
    setDirty(false);
    if (props.onSave) {
      props.onSave();
    }
  }

  async function onDelete() {
    const {errors} = await client.mutate({
      mutation: DELETE_PROGRAM_SPEC,
      variables: {
        program: props.program,
      },
    });
    if (errors) {
      console.error(errors);
      throw errors;
    }
    setEditing(false);
    setDirty(false);
  }

  const Program = () => (
    <Typography variant="body3" color="primary.light">
      {props.program}
    </Typography>
  );

  const Format = () => {
    if (!progSpec) {
      return (
        <Typography variant="body3">None (hostname use by default)</Typography>
      );
    }

    return (
      <>
        <Typography variant="body3" color="primary.light">
          {progSpec.processFriendlyNameFmt}
        </Typography>
      </>
    );
  };

  const Value = () => (
    <Typography variant="body3" color="primary.light">
      {progSpec?.processFriendlyNameFunctionQualifiedName}:
      {progSpec?.processFriendlyNameExpr}
    </Typography>
  );

  const FormatEditor = () => (
    <Stack direction="row" gap={1} alignItems="center">
      <TextField
        placeholder="Format string"
        value={format}
        onChange={(event) => {
          setFormat(event.target.value);
          setDirty(true);
        }}
        fullWidth
        size={props.asTableRow && "small"}
        color={props.asTableRow ? "primary" : "secondary"}
        sx={{minWidth: 200}}
      />
      <HelpCircle
        tip={`The template used for the name of the processes. A
              "{}" placeholder is replaced with the value of the expression
              selected by the following function and expression.`}
      />
    </Stack>
  );

  const FunctionEditor = () => (
    <Select
      value={func}
      onChange={(event) => {
        setFunc(event.target.value);
        setExpr("");
        setDirty(true);
      }}
      displayEmpty
      size={props.asTableRow && "small"}
      color={props.asTableRow ? "primary" : "secondary"}
      sx={{minWidth: 250, flexGrow: 1}}
    >
      <MenuItem value={""} disabled sx={{display: "none"}}>
        <Typography variant="body3" color="secondary">
          Function
        </Typography>
      </MenuItem>
      <MenuItem value={"__none"}>None (use hostname)</MenuItem>
      {allFunctions.map((f) => (
        <MenuItem
          key={f.funcName.QualifiedName}
          value={f.funcName.QualifiedName}
        >
          {f.funcName.QualifiedName}
        </MenuItem>
      ))}
    </Select>
  );

  const ColumnEditor = () => (
    <Select
      value={expr}
      disabled={!func}
      onChange={(event) => {
        setExpr(event.target.value);
        setDirty(true);
      }}
      displayEmpty
      size={props.asTableRow && "small"}
      color={props.asTableRow ? "primary" : "secondary"}
      sx={{minWidth: 250}}
    >
      <MenuItem value={""} disabled sx={{display: "none"}}>
        <Typography variant="body3" color="secondary">
          Column
        </Typography>
      </MenuItem>
      <MenuItem value={"__none"}>None</MenuItem>
      {exprsForSelectedFunction?.map((expr) => (
        <MenuItem key={expr.expr} value={expr.expr}>
          {expr.expr}
        </MenuItem>
      ))}
    </Select>
  );

  const Actions = () => (
    <Stack
      gap={1}
      sx={{display: "grid", gridTemplateColumns: "40px 40px 40px"}}
    >
      <IconButton
        disabled={!dirty || !valid}
        onClick={() => void save()}
        color="primary"
      >
        <SaveIcon />
      </IconButton>

      <IconButton
        onClick={() => void onDelete()}
        disabled={progSpec == undefined}
      >
        <DeleteIcon />
      </IconButton>

      <IconButton onClick={() => setEditing(false)}>
        <ClearIcon color="primary" />
      </IconButton>
    </Stack>
  );

  const EditButton = () => (
    <IconButton onClick={() => setEditing(true)}>
      <IconEdit />
    </IconButton>
  );

  if (props.asTableRow && !editing) {
    return (
      <TableRow>
        <TableCell>
          <Program />
        </TableCell>
        <TableCell>
          <Format />
        </TableCell>
        <TableCell>
          <Value />
        </TableCell>
        <TableCell align="right">
          <EditButton />
        </TableCell>
      </TableRow>
    );
  }

  if (props.asTableRow && editing) {
    return (
      <TableRow>
        <TableCell>
          <Program />
        </TableCell>
        <TableCell>{FormatEditor()}</TableCell>
        <TableCell>
          <Stack direction="row" gap={1}>
            <FunctionEditor />
            <ColumnEditor />
          </Stack>
        </TableCell>
        <TableCell align="right">
          <Actions />
        </TableCell>
      </TableRow>
    );
  }

  if (editing) {
    return (
      <Stack direction="row" gap={2} flexWrap="wrap">
        {FormatEditor()}
        <FunctionEditor />
        <ColumnEditor />
        <Actions />
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="body3">Program: </Typography>
        <Program />
      </Stack>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="body3">Format string: </Typography>
        <Format />
        {progSpec?.processFriendlyNameFunctionQualifiedName && (
          <>
            <Typography variant="body3">Value: </Typography>
            <Value />
          </>
        )}
      </Stack>

      <EditButton />
    </Stack>
  );
}
