import React, {memo, ReactNode} from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd";
import {Button, List, ListItem, Tooltip} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

// The type restrictions say that Item is a type that has a property K whose
// value is a string.
type Props<Item extends Record<K, string>, K extends PropertyKey> = {
  items: Item[];
  renderItem: (item: Item, index: number) => ReactNode;
  onDragEnd: OnDragEndResponder;
  draggableIdKey: K;
};

const GenericDraggableList = <
  Item extends Record<K, string>,
  K extends PropertyKey,
>(
  props: Props<Item, K>,
) => {
  const {items, renderItem, onDragEnd, draggableIdKey} = props;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <List
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{display: "flex", flexDirection: "column", pt: 0}}
          >
            {items.map((item: Item, index: number) => (
              <Draggable
                key={item[draggableIdKey]}
                draggableId={item[draggableIdKey]}
                index={index}
              >
                {(provided, snapshot) => (
                  <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    disablePadding
                    sx={{gap: 1, pb: 3}}
                  >
                    <Tooltip title="Reorder">
                      <Button
                        variant="outlined"
                        color="info"
                        {...provided.dragHandleProps}
                        sx={
                          snapshot.isDragging
                            ? undefined
                            : {background: "transparent"}
                        }
                      >
                        <DragIndicatorIcon
                          color={snapshot.isDragging ? "action" : "info"}
                        />
                      </Button>
                    </Tooltip>
                    {renderItem(item, index)}
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const genericMemo: <T>(component: T) => T = memo;

const DraggableList = genericMemo(GenericDraggableList);
export default DraggableList;
