import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {createFilterOptions, debounce} from "@mui/material";
import {useApolloClient} from "@apollo/client";
import {gql} from "@graphql/gql.ts";
import {useEffect, useMemo, useState} from "react";

const LIST_TYPES_FROM_BINARY = gql(`
    query ListTypesFromBinary($binaryID: String!, $filter: String, $limit: Int) {
      listTypesFromBinary(binaryID: $binaryID, filter: $filter, limit: $limit)
    }
`);

type TypesAutocompleteProps = {
  binaryID: string;
  value: string | null;
  prompt: string;
  onValueChange: (string) => void;
};

const filterOptions = createFilterOptions({
  limit: 50,
});

export default function TypesAutocomplete(props: TypesAutocompleteProps) {
  const [pending, setPending] = useState<boolean>(false);
  const [options, setOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const client = useApolloClient();

  const fetchOptions = useMemo(
    () =>
      debounce(
        (request: {input: string}, callback: (results?: string[]) => void) => {
          console.log("fetching types with limit and filter", request.input);
          void client
            .query({
              query: LIST_TYPES_FROM_BINARY,
              variables: {
                binaryID: props.binaryID,
                filter: request.input,
                limit: 50,
              },
            })
            .then((res) => {
              callback(res.data.listTypesFromBinary);
            });
        },
        400,
      ),
    [client, props.binaryID],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }
    setPending(true);
    fetchOptions({input: inputValue}, (results?: string[]) => {
      if (!active) {
        return;
      }
      let newOptions = [] as string[];
      if (results) {
        newOptions = [...results];
      }
      setOptions(newOptions);
      setPending(false);
    });
    return () => {
      active = false;
    };
  }, [fetchOptions, inputValue]);

  return (
    <Autocomplete
      disablePortal
      filterOptions={filterOptions}
      noOptionsText={
        inputValue
          ? pending
            ? "Searching..."
            : "No types found"
          : "Type to search"
      }
      options={options}
      fullWidth
      renderInput={(params) => (
        <TextField {...params} color="secondary" placeholder={props.prompt} />
      )}
      inputValue={inputValue}
      value={props.value}
      onInputChange={(_event, newInputValue, _reason) => {
        setInputValue(newInputValue);
      }}
      onChange={(_event, newValue, _reason) => {
        props.onValueChange(newValue);
      }}
    />
  );
}
