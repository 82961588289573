import LoadingButton from "@mui/lab/LoadingButton";
import React, {useContext, useRef, useState} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  ClickAwayListener,
  Popper,
} from "@mui/material";
import {gql} from "src/__generated__";
import {useMutation} from "@apollo/client";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {
  processSelectionToGraph,
  RawProcessSelection,
  useProcessSelection,
} from "./ProcessSelector/helpers/processSelection.ts";
import {ProcessSelector} from "@components/ProcessSelector";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import IconSnapshots from "@components/icons/IconSnapshots.tsx";
import {toastError} from "./tables/util.tsx";
import {SnapshotOpenerContext} from "src/templates/PageLayout/components/Header.tsx";
import {UNNAMED_ENV} from "src/constants/unnamed_env";
import {
  selectionFromSessionStorage,
  SessionStorageKeys,
  setProcessesSelectionStateInStorage,
} from "@util/local-storage";
import {GET_RECORDINGS} from "@pages/RecordingsList/gqlHelper";

export const CAPTURE_SNAPSHOTS = gql(/* GraphQL */ `
  mutation captureSnapshots($input: CaptureSnapshotsInput!) {
    captureSnapshots(input: $input) {
      id
      captureTimestamp
      note
      processSnapshots {
        id
      }
      processSnapshotFailures {
        binaryPath
        error
      }
    }
  }
`);

// CaptureSnapshotButton renders the button that captures a snapshot, together
// with the popper for selecting the environment, programs and processes to
// include in the snapshot.
export default function CaptureSnapshotButton(): React.JSX.Element {
  const anchorRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLButtonElement>(null);
  const [selectionPopupOpen, setSelectionPopupOpen] = useState(false);
  const [capturingSnapshot, setCapturingSnapshot] = useState(false);
  const navigate = useNavigate();
  const snapshotOpenerConfig = useContext(SnapshotOpenerContext);

  const processSelectionFromStorage = selectionFromSessionStorage(
    SessionStorageKeys.ProcessesSelectionStateForSnapshot,
  );

  const [processSelection, agentReport, _binaries] = useProcessSelection(
    processSelectionFromStorage,
  );
  const forceUpdate = useForceUpdate();

  function setProcessSelection(newSelection: RawProcessSelection | undefined) {
    forceUpdate();
    setProcessesSelectionStateInStorage(
      SessionStorageKeys.ProcessesSelectionStateForSnapshot,
      newSelection,
    );
  }

  if (agentReport != undefined) {
    // Don't update the state if the agent report is not yet available.
    if (
      (processSelection == undefined) !=
        (processSelectionFromStorage == undefined) ||
      (processSelection != undefined &&
        processSelectionFromStorage != undefined &&
        JSON.stringify(processSelection.toRaw()) !=
          JSON.stringify(processSelectionFromStorage))
    ) {
      setProcessSelection(processSelection?.toRaw());
    }
  }

  const [captureSnapshotsMutation] = useMutation(CAPTURE_SNAPSHOTS, {
    update(cache, {data}) {
      // Evict the results of getRecordings from the cache so the query gets
      // re-executed the next time the snapshots page is rendered.
      cache.evict({id: "ROOT_QUERY", fieldName: "getRecordings"});
    },
    // TODO: when a new snapshot is captured, the "missing types" field of the
    // spec might change. We should arrange it such that the cache is either
    // automatically updated with the missing types, or the cache is
    // invalidated.
  });

  const onPopperClose = (event: Event) => {
    // Close the popper unless the click was inside the popper or on the toggle
    // button. In the case of the toggle botton, we do want the popper to be
    // closed, but that will be done by handleToggle (if we also closed it here,
    // handleToggle would open it again immediately).
    if (
      popperRef.current &&
      popperRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (
      toggleButtonRef.current &&
      toggleButtonRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setSelectionPopupOpen(false);
  };

  // onCaptureSnapshotClick captures a collection of snapshots.
  function onCaptureSnapshotClick() {
    if (!processSelection) {
      console.warn(
        "bug: attempting to capture a snapshot with no selection status",
      );
      return;
    }

    // Capture the snapshots.
    setCapturingSnapshot(true);
    const [env, selection] = processSelectionToGraph(processSelection.toRaw());
    captureSnapshotsMutation({
      variables: {
        input: {
          environment: env == UNNAMED_ENV ? null : env,
          selections: selection,
        },
      },
      refetchQueries: [GET_RECORDINGS],
      errorPolicy: "none",
    })
      .then((res) => {
        if (res.errors) {
          console.error("capturing snapshots failed", res.errors);
          return;
        }
        toast(
          (t) => {
            const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
              toast.dismiss(t.id);

              const newSnapshotID = res.data!.captureSnapshots!.id;
              // If a custom event handler was specified, use it. Otherwise,
              // do the default.
              const handler = snapshotOpenerConfig.handler;
              if (handler) {
                void handler(event, newSnapshotID);
              } else {
                const newWindow = event.ctrlKey || event.button == 1;
                if (!newWindow) {
                  navigate(`/snapshots/${newSnapshotID}`);
                } else {
                  window.open(`/#/snapshots/${newSnapshotID}`);
                }
              }
            };

            const numFailures =
              res.data!.captureSnapshots!.processSnapshotFailures!.length || 0;
            const failuresMessage = `${numFailures} process${
              numFailures === 1 ? "" : "es"
            } failed.`;

            for (const failure of res.data!.captureSnapshots!
              .processSnapshotFailures!) {
              console.log(
                `failed process snapshot ${failure.binaryPath}`,
                failure.error,
              );
            }

            return (
              <span>
                Captured new snapshot.
                {numFailures > 0 && (
                  <>
                    <br /> {failuresMessage}
                  </>
                )}
                <br />
                <Button onAuxClick={onClick} onClick={onClick}>
                  Open
                </Button>
                <Button onClick={() => toast.dismiss(t.id)}>Dismiss</Button>
              </span>
            );
          },
          {duration: 10000},
        );
      })
      .catch((err) => {
        toastError(err, "Failed to capture snapshot");
      })
      .finally(() => {
        setCapturingSnapshot(false);
      });
  }

  const onSelectionToggle = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
  ) => {
    setSelectionPopupOpen((prevOpen) => {
      return !prevOpen;
    });
  };

  const disabled = processSelection == undefined || processSelection.empty();

  return (
    <Box>
      <ButtonGroup variant="outlined" ref={anchorRef}>
        <LoadingButton
          style={{
            fontSize: "14px",
            fontWeight: 500,
            padding: "10px 8px 10px 16px",
            borderRadius: "25px 0 0 25px",
          }}
          color="primary"
          startIcon={<IconSnapshots color={disabled ? "" : "white"} />}
          loadingPosition={"start"}
          loading={capturingSnapshot}
          variant={"contained"}
          onClick={onCaptureSnapshotClick}
          disabled={disabled}
        >
          Capture snapshot
        </LoadingButton>
        <Button
          style={{paddingLeft: "2px", borderLeft: "1px solid #D4C2FF"}}
          size="small"
          ref={toggleButtonRef}
          variant="contained"
          disabled={capturingSnapshot}
          onClick={(event) => onSelectionToggle(event)}
        >
          <ExpandMoreSharpIcon />
        </Button>
      </ButtonGroup>

      <Popper
        open={selectionPopupOpen}
        // Keep the process selector mounted. We rely on it to generate events
        // that enable or disable the button.
        keepMounted={true}
        placement="bottom-end"
        anchorEl={anchorRef.current}
        ref={popperRef}
        sx={{zIndex: 4, pt: 1}}
      >
        <Card sx={{boxShadow: "0px 4px 30px 0px #000000"}}>
          <CardContent>
            <ClickAwayListener
              onClickAway={onPopperClose}
              // Setting the mouse event is some sort of hack that I got from
              // https://github.com/mui/material-ui/issues/12034#issuecomment-828240836
              // Without it, clicking on the dropdowns behaves as a click
              // outside of the Popper for some reason, inadvertently closing
              // it.
              mouseEvent={"onMouseUp"}
            >
              <span>
                <ProcessSelector
                  selection={processSelection}
                  agentReports={agentReport}
                  onSelectionUpdated={(
                    newSelection: RawProcessSelection | undefined,
                  ) => setProcessSelection(newSelection)}
                />
              </span>
            </ClickAwayListener>
          </CardContent>
        </Card>
      </Popper>
    </Box>
  );
}

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
}
