import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiTable = (): Pick<Components<typeof darkTheme>, "MuiTable"> => ({
  MuiTable: {
    styleOverrides: {
      root: ({theme}) => ({
        borderRadius: 16,
        backgroundColor: theme.palette.background.paper,
        ".MuiTableCell-root": {
          fontSize: "12px",
          borderColor: theme.palette.background.default,
          lineHeight: "16px",
          "&:has(.MuiFormControl-root, .MuiButtonBase-root, .MuiInputBase-root)":
            {
              paddingBlock: "4px",
            },
          "> .MuiButtonBase-root": {
            paddingBlock: "8px",
          },
        },
        ".MuiTableCell-head": {
          color: theme.palette.primary.light,
        },
      }),
    },
    variants: [
      {
        props: {color: "secondary"},
        style: ({theme}) => ({
          backgroundColor: theme.palette.background.default,
          ".MuiTableCell-root": {
            borderColor: theme.palette.background.paper,
          },
        }),
      },
    ],
  },
});
