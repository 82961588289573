import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiIconButton = (): Pick<
  Components<typeof darkTheme>,
  "MuiIconButton"
> => ({
  MuiIconButton: {
    defaultProps: {
      color: "info",
    },
  },
});
