import React, {Fragment} from "react";
import {
  Box,
  Button,
  Checkbox,
  debounce,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {SimpleTreeView, TreeItem} from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {CircleExclamationMarkTooltip} from "src/components/util.tsx";
import PublishIcon from "@mui/icons-material/Publish";
import {treeNode, treeNodeType} from "./treeNode";
import {ClearIcon} from "@mui/x-date-pickers";

type ExprTreeProps = {
  nodes: treeNode[];
  // disabled, if set, makes all checkboxes in the tree disabled.
  disabled?: boolean;
  hoisting: boolean;
  onNodeToggle: (expandedNodeIds: string[]) => void;
  onNodeChange: (
    node: treeNode,
    checked: boolean,
    alias: string | undefined,
  ) => void;
  // onStaleNodeDelete is called when the "delete" button is clicked on a node
  // that is not compatible with the current binary.
  onStaleNodeDelete: (node: treeNode) => void;
  expandedNodeIDs: string[];
};

// ExpressionTree renders a tree of variables and their fields. The tree is
// passed in through props, and represents either the list of variables
// available in a stack frame, or the fields of a type. For nodes of a struct
// type, children are the struct fields. When a node is expanded or collapsed,
// props.onNodeToggle is called; this lets the parent lazily load a node's
// children.
export default function ExpressionTree(
  props: ExprTreeProps,
): React.JSX.Element {
  const onNodeToggle = (_event: unknown, nodeIds: string[]): void => {
    props.onNodeToggle(nodeIds);
  };

  const onNodeCollectChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    node: treeNode,
  ): void => {
    event.stopPropagation();
    const checked = (event.target as HTMLInputElement).checked;
    // If the node was deselected, we generally want to call
    // props.onNodeCollectChange(node, checked)
    // However, there is a special case: imagine a function in the spec that was
    // capturing the expression foo.bar. This means that, at some point, foo had
    // a structure type. Let's say that, in the meantime, foo no longer has a
    // structure type (perhaps it's a primitive or an interface). In that case,
    // if the user unchecks foo, we want to delete foo.bar from the spec on the
    // argument that it's stale and that there's no other way to de-select
    // foo.bar using this ExpressionTree.
    const uncheckedWithStaleChildren =
      !checked && !node.childrenNotLoaded && node.children == undefined;
    if (uncheckedWithStaleChildren) {
      props.onStaleNodeDelete(node);
    } else {
      props.onNodeChange(node, checked, node.alias);
    }
  };
  const debouncedOnNodeChange = debounce(props.onNodeChange, 400);

  const renderTree = (nodes: treeNode[]): React.JSX.Element[] => {
    if (nodes.length == 0) {
      return [
        <Box key={"no-fields"}>
          <Typography variant={"explanation"}>Struct has no fields.</Typography>
        </Box>,
      ];
    }
    return nodes.map((node) => {
      return (
        <TreeItem
          key={node.fullExpr}
          itemId={node.fullExpr}
          label={
            <Stack
              flexDirection="row"
              alignItems="center"
              gap={1}
              sx={{
                whiteSpace: "nowrap",
                color: node.locListAvailable ? "" : "grey",
              }}
            >
              <Checkbox
                checked={node.collected === "yes"}
                indeterminate={node.collected === "partial"}
                onClick={(event) => onNodeCollectChange(event, node)}
                tabIndex={-1}
                disabled={node.typeName == undefined || props.disabled}
              />

              <Typography variant="body3">{node.name}</Typography>

              {node.note && <CircleExclamationMarkTooltip tip={node.note} />}

              {node.typeName != undefined ? (
                <Typography variant="body3" color="secondary">
                  {node.nodeType == treeNodeType.RETURN_VALUE
                    ? "return value"
                    : node.nodeType == treeNodeType.PARAMETER
                      ? "param"
                      : node.nodeType == treeNodeType.VARIABLE
                        ? "variable"
                        : "field"}{" "}
                  : {node.typeName ?? "<unknown type>"}
                </Typography>
              ) : (
                <Button onClick={() => props.onStaleNodeDelete(node)}>
                  Delete
                </Button>
              )}
              {props.hoisting && node.collected === "yes" && (
                <Typography variant="body3" style={{marginLeft: "auto"}}>
                  {node.alias && (
                    <Fragment>
                      <TextField
                        size="small"
                        variant="standard"
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        onKeyDown={(event) => {
                          event.stopPropagation();
                        }}
                        onChange={(event) => {
                          debouncedOnNodeChange(node, true, event.target.value);
                        }}
                        defaultValue={node.alias}
                      />
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          props.onNodeChange(node, true, undefined);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Fragment>
                  )}
                  {!node.alias && (
                    <Tooltip
                      title={
                        "Alias this expression - aliased expression will appear as their own column in any function table; if multiple expressions use the same alias, they will be COALESCE'd"
                      }
                    >
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          props.onNodeChange(node, true, node.name);
                        }}
                      >
                        <PublishIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Typography>
              )}
            </Stack>
          }
        >
          {renderChildren(node)}
        </TreeItem>
      );
    });
  };

  const renderChildren = (node: treeNode): React.JSX.Element[] | undefined => {
    if (node.children) {
      return renderTree(node.children);
    }
    // Render a dummy child node to force the tree to render an "expand" icon.
    if (node.childrenNotLoaded) {
      const key = node.fullExpr + ".dummy";
      return [<TreeItem itemId={key} key={key}></TreeItem>];
    }
    return undefined;
  };

  return (
    <>
      <SimpleTreeView
        multiSelect
        slots={{expandIcon: ChevronRightIcon, collapseIcon: ExpandMoreIcon}}
        expandedItems={props.expandedNodeIDs}
        onExpandedItemsChange={onNodeToggle}
      >
        {renderTree(props.nodes)}
      </SimpleTreeView>
    </>
  );
}
