import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiMenuItem = (): Pick<
  Components<typeof darkTheme>,
  "MuiMenuItem"
> => ({
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
    },
  },
});
