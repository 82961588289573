import CodeMirror, {EditorView} from "@uiw/react-codemirror";
import {sql} from "@codemirror/lang-sql";
import {autocompletion, CompletionContext} from "@codemirror/autocomplete";
import {useQuery} from "@apollo/client";
import {tableCompletions} from "src/components/tables/util.tsx";
import {SQLShellTheme} from "src/theme/SQLShellTheme.ts";
import {GET_TABLE_NAMES} from "@util/queries.tsx";

type QueryEditorProps = {
  query: string;
  setQuery: (query: string) => void;
};

// QueryEditor renders a CodeMirror editor for SQL queries.
export default function QueryEditor({query, setQuery}: QueryEditorProps) {
  // Hack the theme for the editor so that we can set a minHeight without the
  // horizontal scroll bar appearing in the wrong place. See
  // https://github.com/uiwjs/react-codemirror/issues/615#issuecomment-1890754380
  const minHeight = "100px";
  const theme = EditorView.theme({
    "& div.cm-scroller": {
      minHeight: `${minHeight} !important`,
    },
  });

  const {data: tablesData} = useQuery(GET_TABLE_NAMES);
  const extensions = [sql(), theme];
  if (tablesData) {
    extensions.push(
      autocompletion({
        override: [
          (context: CompletionContext) =>
            tableCompletions(
              context,
              tablesData.getSchema.map((t) => t.Name),
            ),
        ],
      }),
    );
  }
  return (
    <CodeMirror
      value={query}
      // Make the editor grow vertically to fit the text.
      height="auto"
      theme={SQLShellTheme()}
      extensions={extensions}
      basicSetup={true}
      onChange={(value) => setQuery(value)}
    />
  );
}
