import CodeMirror, {
  EditorView,
  keymap,
  Prec,
  ReactCodeMirrorRef,
} from "@uiw/react-codemirror";
import {sql} from "@codemirror/lang-sql";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Button from "@mui/material/Button";
import React, {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {ApolloError, useApolloClient, useSuspenseQuery} from "@apollo/client";
import {
  UserDefinedTable,
  UserDefinedTableData,
} from "src/components/tables/tables.tsx";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {autocompletion, CompletionContext} from "@codemirror/autocomplete";
import {
  addOrUpdateDerivedTableSpec,
  confirmationDialogInfo,
  tableCompletions,
  tablesURLController,
} from "src/components/tables/util.tsx";
import {TableReference} from "src/__generated__/graphql.ts";
import {schemaInfo} from "src/util/spec.ts";
import {SQLShellTheme} from "../../theme/SQLShellTheme.ts";
import {GET_TABLE_NAMES, RUN_SQL_QUERY} from "@util/queries.tsx";
import {useSnapshotState} from "@providers/snapshot-state.tsx";

export default function SQLShell(props: {
  snapshotID?: number;
  logID?: number;
  initialQuery?: string;
  // If set, initialQuery will be run on the first render.
  initialRun?: boolean;
  initialGrouping?: string[];
  showTableValidationErrorConfirmationDialog: (
    _: confirmationDialogInfo,
  ) => Promise<boolean>;
  onNewTableAdded: (newTable: TableReference) => void;
  controller: tablesURLController;
  schema: schemaInfo;
}): React.JSX.Element {
  if (props.initialRun && !props.initialQuery) {
    throw new Error("initialQuery must be set if initialRun is true");
  }
  if (props.initialGrouping && !props.initialRun) {
    throw new Error("initialRun must be set if initialGrouping is set");
  }

  const snapshotState = useSnapshotState();
  const [table, setTable] = useState<UserDefinedTableData | undefined>(
    undefined,
  );
  // tableQuery tracks the query that generated `table`, if any - i.e. the last
  // query that was run. This is different from `query`, which tracks the
  // current value of the textbox.
  const [tableQuery, setTableQuery] = useState("");
  const client = useApolloClient();
  const [queryError, setQueryError] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [tableName, setTableName] = useState("");

  // A ref to the "imperative handle" provided by MyCodeMirror.
  const editorRef = useRef<CodeEditorHandle>(null);

  // Get the names of all tables to use for auto-completion.
  const {data: tableNamesRes} = useSuspenseQuery(GET_TABLE_NAMES, {
    // Throw on errors (the default).
    errorPolicy: "none",
  });

  const runQuery = async (query: string) => {
    try {
      const {data} = await client.query({
        query: RUN_SQL_QUERY,
        variables: {
          snapshotID: props.snapshotID,
          logID: props.logID,
          query: query,
        },
        // Seems like a good idea to not cache the results of arbitrary queries.
        fetchPolicy: "no-cache",
      });
      setTable(data.runSQLQuery);
      setTableQuery(query);
      setQueryError("");
    } catch (e) {
      if (e instanceof ApolloError) {
        setQueryError(e.message);
      } else {
        setQueryError(String(e));
        console.log(e);
      }
    }
  };

  // On the first render, run the initial query if it is set.
  useEffect(
    () => {
      if (props.initialRun) {
        void runQuery(props.initialQuery!);
      }
    },
    // This effect runs on the first render only; we don't care about deps
    // changing (we don't support changes in the initialRun prop).
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onAddTableToSpecClick = () => {
    setDialogOpen(true);
  };

  const addTableToSpec = async () => {
    void (await addOrUpdateDerivedTableSpec(
      client,
      {
        id: undefined, // Add a new table.
        name: tableName,
        query: editorRef.current!.value(),
      },
      props.showTableValidationErrorConfirmationDialog,
    ).then((res) => {
      if (res != false) {
        props.onNewTableAdded(res);
      }
      setDialogOpen(false);
    }));
  };

  function onSaveDialogCanceled() {
    setDialogOpen(false);
  }

  return (
    <Box>
      <MyCodeMirror
        ref={editorRef}
        tableNames={tableNamesRes.getSchema.map((t) => t.Name)}
        onRunQuery={runQuery}
        initialValue={props.initialQuery}
      />
      <Stack direction="row" gap={3} my={3}>
        <Button
          variant={"contained"}
          sx={{width: "1px"}}
          onClick={() => {
            void runQuery(editorRef.current!.value());
          }}
        >
          Run
        </Button>
        <Button
          variant={"contained"}
          sx={{ml: 1, width: "250px"}}
          onClick={onAddTableToSpecClick}
          disabled={
            props.initialQuery != undefined &&
            editorRef.current?.value() == props.initialQuery
          }
        >
          Save as new table
        </Button>
      </Stack>

      {queryError && (
        <div style={{display: "flex", alignItems: "center"}}>
          <WarningAmberIcon style={{color: "red"}} />
          <div style={{color: "red", marginLeft: "5px"}}>{queryError}</div>
        </div>
      )}

      {table && (
        <UserDefinedTable
          table={table}
          // Reset the table state when the query changes.
          key={tableQuery}
          initialGrouping={
            // We want props.initialGrouping to only be applied to the initial
            // query. Note that we change the key of the UserDefinedTable based
            // on the query, so we have to take care to not pass initialGrouping
            // again.
            tableQuery == props.initialQuery ? props.initialGrouping : undefined
          }
          controller={props.controller}
          schema={props.schema}
          snapshotState={snapshotState}
        />
      )}

      <Dialog open={dialogOpen} onClose={onSaveDialogCanceled}>
        <DialogTitle>Table name</DialogTitle>
        <DialogContent>
          <TextField
            sx={{mt: 1, width: "100%"}}
            label="Table name"
            variant="outlined"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTableName(event.target.value);
            }}
            value={tableName}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant={"contained"}
            sx={{ml: 1, width: "250px"}}
            onClick={() => {
              void addTableToSpec();
            }}
          >
            Save table definition
          </Button>
          <Button
            variant={"contained"}
            sx={{ml: 1, width: "250px"}}
            onClick={onSaveDialogCanceled}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

class CodeEditorHandle {
  ref: MutableRefObject<ReactCodeMirrorRef>;

  constructor(ref: MutableRefObject<ReactCodeMirrorRef>) {
    this.ref = ref;
  }

  value(): string {
    return this.ref.current?.view?.state.doc.toString() ?? "";
  }
}

// MyCodeMirror wraps CodeMirror and provides an "imperative handle" for
// retrieving the current value of the editor.
//
// We use the imperative handle instead of signaling the parent on every change
// of the text because a) causing the parent to re-render on every keystroke
// leads to laggy typing, and also because there appears to be a bug in the
// CodeMirror component that causes the cursor to jump to the start of the line
// sometimes when typing fast (see
// https://github.com/uiwjs/react-codemirror/issues/694).
const MyCodeMirror = forwardRef(
  (
    props: {
      tableNames: string[];
      initialValue?: string;
      onRunQuery: (query: string) => Promise<void>;
    },
    ref: ForwardedRef<CodeEditorHandle>,
  ): React.JSX.Element => {
    const cmRef = useRef<ReactCodeMirrorRef>({});
    useImperativeHandle(ref, () => new CodeEditorHandle(cmRef));

    // Make Ctrl-Enter run the query.
    const customKeymap = Prec.highest(
      keymap.of([
        {
          key: "Ctrl-Enter",
          run: (_editor) => {
            const value = cmRef.current.view!.state.doc.toString();
            void props.onRunQuery(value);
            return true;
          },
        },
      ]),
    );

    // Hack the theme for the editor so that we can set a minHeight without the
    // horizontal scroll bar appearing in the wrong place. See
    // https://github.com/uiwjs/react-codemirror/issues/615#issuecomment-1890754380
    const minHeight = "100px";
    const theme = EditorView.theme({
      "& div.cm-scroller": {
        minHeight: `${minHeight} !important`,
      },
    });

    return (
      <CodeMirror
        value={props.initialValue}
        ref={cmRef}
        height="auto"
        basicSetup={true}
        theme={SQLShellTheme()}
        extensions={[
          sql(),
          theme,
          customKeymap,
          autocompletion({
            override: [
              (context: CompletionContext) =>
                tableCompletions(context, props.tableNames),
            ],
          }),
        ]}
      />
    );
  },
);
