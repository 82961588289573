import {Button, Drawer, Stack, Tooltip, Typography} from "@mui/material";
import {ErrorBoundary} from "react-error-boundary";
import React from "react";
import useResize from "src/util/use-resize.tsx";
import {
  FunctionSpec,
  ParseFunctionQualifiedNameQuery,
  QueryParseFunctionQualifiedNameArgs,
  TypeSpec,
} from "src/__generated__/graphql.ts";
import {FunctionSpecCard} from "src/pages/Spec/components/FunctionSpecCard.tsx";
import {
  SkipToken,
  skipToken,
  SuspenseQueryHookOptions,
  useApolloClient,
  useSuspenseQuery,
} from "@apollo/client";
import {ProgramCounter} from "src/util/types.ts";
import {deleteFunctionSpec} from "src/util/queries.tsx";
import {useConfirmationDialog} from "src/providers/confirmation-dialog.tsx";
import {gql} from "../__generated__";

const PARSE_FUNC_NAME = gql(/* GraphQL */ `
  query ParseFunctionQualifiedName($funcQualifiedName: String!) {
    parseFunctionQualifiedName(funcQualifiedName: $funcQualifiedName) {
      Package
      Type
      Name
      QualifiedName
    }
  }
`);

export type FunctionSpecSkeleton = {
  funcQualifiedName: string;
};

// FunctionSpecDrawer renders a drawer that opens from the right side with the
// spec/available variables of a single function or type. It wraps
// FunctionSpecCard (if editing a function spec) or TypeSpecCard (if editing a
// type spec).
export default function FunctionSpecDrawer(props: {
  // display is the spec to display.
  //
  // Note that the spec for the function or type does not need to actually exist
  // in the database. If we're passed a spec that doesn't exist (probably an
  // empty one), it will be created if the user modifies it in any way.
  display: FunctionSpec | FunctionSpecSkeleton;

  // showDeleteButton controls whether the delete button is rendered. This
  // should be false when `display` refers to a function or type whose spec does
  // not exist in the database.
  showDeleteButton: boolean;

  // The binary to be used for listing the available variables or struct fields.
  binaryID: string;

  // If display is a function spec, an inline information can be set. It will be
  // used to display a note about inlined function quirks.
  inlined?: boolean;

  // If display is a function spec, a program counter can be set. It will be
  // used to display variables that are available at this particular code
  // location.
  pc?: ProgramCounter;

  onClose: () => void;
}): React.JSX.Element {
  const {width: varsDrawerWidth, enableResize} = useResize({minWidth: 800});
  const client = useApolloClient();
  const showConfirmationDialog = useConfirmationDialog();

  let queryArgs:
    | SuspenseQueryHookOptions<
        ParseFunctionQualifiedNameQuery,
        QueryParseFunctionQualifiedNameArgs
      >
    | SkipToken = skipToken;
  const spec = props.display;
  if (isFunctionSpecSkeleton(spec)) {
    queryArgs = {
      variables: {
        funcQualifiedName: spec.funcQualifiedName,
      },
    };
  }
  const {data: funcNameRes} = useSuspenseQuery(PARSE_FUNC_NAME, queryArgs);
  let funcSpec: FunctionSpec | undefined = undefined;
  if (isFunctionSpec(props.display)) {
    funcSpec = props.display;
  } else if (isFunctionSpecSkeleton(props.display)) {
    funcSpec = {
      funcName: funcNameRes!.parseFunctionQualifiedName,
      snapshotSpec: null,
      functionStartEvent: null,
    };
  }

  async function onDeleteClick() {
    const spec = props.display;
    let ok = true;
    if (isFunctionSpec(spec)) {
      ok = await deleteFunctionSpec(spec, client, showConfirmationDialog);
    }
    if (ok) {
      props.onClose();
    }
  }

  return (
    <Drawer
      anchor={"right"}
      open={!!props.display}
      onClose={props.onClose}
      PaperProps={{
        sx: {
          width: varsDrawerWidth,
          paddingLeft: 1,
        },
      }}
      onClick={(event) => event.stopPropagation()}
    >
      {props.display && (
        <>
          <div
            style={{
              position: "absolute",
              width: "4px",
              top: "0",
              left: "0",
              bottom: "0",
              cursor: "col-resize",
            }}
            onMouseDown={enableResize}
          />

          {funcSpec && (
            <span>
              <h4>
                <Stack direction="row" spacing={2} alignItems={"baseline"}>
                  <Stack direction="row">
                    <Typography variant={"mutedNormalSize"} sx={{mr: 1}}>
                      Function:
                    </Typography>
                    <span
                      style={{
                        fontFamily: "monospace",
                        overflowWrap: "break-word",
                      }}
                    >
                      {funcSpec.funcName.QualifiedName}
                    </span>
                  </Stack>
                  {props.inlined && (
                    <Typography variant={"warning"}>Inlined</Typography>
                  )}
                </Stack>
              </h4>
            </span>
          )}

          <ErrorBoundary
            fallbackRender={({error}) => <div>Failed: {error.message}</div>}
          >
            {funcSpec && (
              <>
                {props.showDeleteButton && (
                  <Tooltip
                    title={
                      <>
                        <Typography>Delete function spec</Typography>
                        Remove this function from the set of functions for which
                        data is collected in a snapshot when the function is
                        encountered on a goroutine's stack trace.
                      </>
                    }
                  >
                    <Button
                      sx={{width: "fit-content"}}
                      onClick={() => void onDeleteClick()}
                      className={"dense"}
                    >
                      Delete function spec
                    </Button>
                  </Tooltip>
                )}
                <FunctionSpecCard
                  functionSpec={funcSpec}
                  inlined={props.inlined}
                  showHeader={false}
                  binaryID={props.binaryID}
                  pc={props.pc}
                  // We must be in the context of a snapshot, so let's start
                  // with the function's snapshot spec expanded.
                  defaultExpanded={"snapshot"}
                />
              </>
            )}
          </ErrorBoundary>
        </>
      )}
    </Drawer>
  );
}

function isFunctionSpec(
  spec: FunctionSpec | FunctionSpecSkeleton | TypeSpec,
): spec is FunctionSpec {
  return (spec as FunctionSpec).funcName !== undefined;
}

function isFunctionSpecSkeleton(
  spec: FunctionSpec | FunctionSpecSkeleton | TypeSpec,
): spec is FunctionSpecSkeleton {
  return (spec as FunctionSpecSkeleton).funcQualifiedName !== undefined;
}
