import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiTooltip = (): Pick<
  Components<typeof darkTheme>,
  "MuiTooltip"
> => ({
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        whiteSpace: "pre-wrap",
      },
    },
  },
});
