import React, {Suspense, useState} from "react";
import {GoContextSpec, TypeSpecInput} from "src/__generated__/graphql.ts";
import {TypeSpecCard} from "./TypeSpecCard";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {gql} from "src/__generated__";
import {useApolloClient} from "@apollo/client";
import TypesAutocomplete from "./TypesAutocomplete";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import {TableNoDataMessage} from "@components/TableNoDataMessage.tsx";
import {ErrorBoundary} from "react-error-boundary";

const StyledTableRow = styled(TableRow)(({theme}) => ({
  "&:nth-of-type(odd)": {backgroundColor: theme.palette.secondary.dark},
}));

type GoContextSpecCardProps = {
  spec: GoContextSpec[];
  binaryID: string;
};

const ADD_OR_UPDATE_GO_CONTEXT_SPEC = gql(/* GraphQL */ `
  mutation AddOrUpdateGoContextSpec($input: GoContextSpecInput!) {
    addOrUpdateGoContextSpec(input: $input) {
      ...FullSnapshotSpec
      missingTypeQualifiedNames
    }
  }
`);

const DELETE_GO_CONTEXT_SPEC = gql(/* GraphQL */ `
  mutation DeleteGoContextSpec($keyType: String!, $valueType: String!) {
    deleteGoContextSpec(keyType: $keyType, valueType: $valueType) {
      ...FullSnapshotSpec
      missingTypeQualifiedNames
    }
  }
`);

export function GoContextSpecCard(
  props: GoContextSpecCardProps,
): React.JSX.Element {
  const client = useApolloClient();
  const addOrUpdateGoContextSpec = (
    key_value: string,
    value_type_spec: TypeSpecInput | undefined,
  ) => {
    void (async () => {
      const {errors} = await client.mutate({
        mutation: ADD_OR_UPDATE_GO_CONTEXT_SPEC,
        variables: {
          input: {
            keyType: key_value,
            valueType: value_type_spec,
          },
        },
      });
      if (errors) {
        console.error("failed to update go context spec", errors);
      }
    })();
  };

  const [newKeyType, setNewKeyType] = useState(null as string | null);
  const [newValueType, setNewValueType] = useState(null as string | null);
  const addGoContextSpec = () => {
    addOrUpdateGoContextSpec(
      newKeyType ?? "",
      newValueType
        ? {
            typeQualifiedName: newValueType,
            collectAll: true,
            collectExprs: [],
          }
        : undefined,
    );
  };

  const deleteGoContextSpec = (keyType: string, valueType: string) => {
    void (async () => {
      const {errors} = await client.mutate({
        mutation: DELETE_GO_CONTEXT_SPEC,
        variables: {
          // Specs are identified by exactly one of key or value, and deletion enforces that.
          keyType: keyType,
          valueType: keyType == "" ? valueType : "",
        },
      });
      if (errors) {
        console.error("failed to update go context spec", errors);
      }
    })();
  };

  return (
    <Stack my={2} gap={3}>
      <Card>
        <CardContent>
          <Stack direction="row" gap={2}>
            <TypesAutocomplete
              binaryID={props.binaryID}
              value={newKeyType}
              prompt="Key type"
              onValueChange={(val: string) => setNewKeyType(val)}
            />
            <TypesAutocomplete
              binaryID={props.binaryID}
              value={newValueType}
              prompt="Value type"
              onValueChange={(val: string) => setNewValueType(val)}
            />
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddOutlinedIcon />}
              onClick={() => {
                addGoContextSpec();
              }}
              disabled={!newKeyType && !newValueType}
            >
              Add
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Key type</TableCell>
              <TableCell>Value type</TableCell>
              <TableCell sx={{width: "50%"}}>Expressions</TableCell>
              <TableCell sx={{width: "45px"}} align="right">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.spec.length && <TableNoDataMessage />}
            {props.spec.map((spec) => (
              <StyledTableRow
                key={spec.keyType + ":" + spec.valueType?.typeQualifiedName}
              >
                <TableCell>
                  {spec.keyType == "" ? "<any>" : spec.keyType}
                </TableCell>
                <TableCell>
                  {spec.valueType?.typeQualifiedName ?? "<any>"}
                </TableCell>
                <TableCell>
                  {spec.valueType ? (
                    <ErrorBoundary
                      fallbackRender={({error}) => (
                        <div>Failed: {error.message}</div>
                      )}
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <TypeSpecCard
                          spec={spec.valueType}
                          hoisting={true}
                          binaryID={props.binaryID}
                          addOrUpdateTypeSpec={(
                            value_type_spec: TypeSpecInput,
                          ) => {
                            addOrUpdateGoContextSpec(
                              spec.keyType,
                              value_type_spec,
                            );
                          }}
                        />
                      </Suspense>
                    </ErrorBoundary>
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      deleteGoContextSpec(
                        spec.keyType,
                        spec.valueType?.typeQualifiedName ?? "",
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
