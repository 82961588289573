import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiCardHeader = (): Pick<
  Components<typeof darkTheme>,
  "MuiCardHeader"
> => ({
  MuiCardHeader: {
    styleOverrides: {
      title: {
        fontSize: 20,
        fontWeight: 500,
      },
      root: {
        padding: 24,

        "& + .MuiCardContent-root": {
          paddingTop: 0,
        },
      },
      subheader: {
        marginTop: 10,
        fontSize: 12,
      },
    },
  },
});
