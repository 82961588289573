import {UNNAMED_ENV} from "src/constants/unnamed_env";

export type ProgramSelectionStatus =
  | AllProcessesSelected
  | NoProcessesSelected
  | SomeProcessesSelected;

export type AllProcessesSelected = {
  type: "all";
};

export type NoProcessesSelected = {
  type: "none";
};

export type SomeProcessesSelected = {
  type: "some";
  selectedProcs: ProcessInfo[];
};

interface ProcessInfoData {
  host: {
    ip: string | undefined;
    hostname: string;
  };
  agentID: string;
  pid: number;
  processToken: string;
  programName: string;
  // a user-assigned identifier for this process
  processID: string;
  binary: BinaryInfo;
  environment: string | typeof UNNAMED_ENV;
}

export class ProcessInfo implements ProcessInfoData {
  host: {
    ip: string | undefined;
    hostname: string;
  } = {ip: undefined, hostname: ""};
  agentID: string = "";
  pid: number = 0;
  processToken: string = "";
  programName: string = "";
  // a user-assigned identifier for this process
  processID: string = "";
  binary: BinaryInfo = {hash: "", unknown: false, binaryName: ""};
  environment: string | typeof UNNAMED_ENV = UNNAMED_ENV;

  constructor(data: ProcessInfoData) {
    Object.assign(this, data);
  }

  toJSON() {
    return this.processToken;
  }
}

export type BinaryInfo = {
  hash: string;
  unknown: boolean;
  binaryName: string;
};
