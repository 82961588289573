import React from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import {Link as ReactLink} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {useMutation} from "@apollo/client";
import {DELETE_RECORDING, GET_RECORDINGS} from "./gqlHelper.ts";
import dayjs from "dayjs";
import {Avatar} from "@components/Avatar.tsx";
import {toastError} from "src/components/tables/util";
import {SnapshotInfoMinimal} from "./@types.ts";

type Props = {
  snapshot: SnapshotInfoMinimal;
};

export const SnapshotItem: React.FC<Props> = ({snapshot}) => {
  const [deleteRecording] = useMutation(DELETE_RECORDING, {
    refetchQueries: [GET_RECORDINGS],
  });

  let name = "Snapshot";
  if (snapshot.environment) {
    name += `: ${snapshot.environment}`;
  }
  name += " (" + snapshot.programs.join(", ") + ")";

  return (
    <Card>
      <CardContent
        component={Stack}
        direction="row"
        justifyContent="space-between"
        gap={4}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          flexWrap="wrap"
          flexGrow={1}
        >
          <Typography variant="body2" color="primary.light" sx={{minWidth: 50}}>
            {snapshot.id}
          </Typography>

          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Avatar photoURL={snapshot.user?.photoURL ?? undefined} />
            <Typography variant="body2" color="textSecondary" width={200}>
              {snapshot.user?.name ?? "<user unknown>"}
            </Typography>
            <Link component={ReactLink} to={`/snapshots/` + snapshot.id}>
              {name}
            </Link>
          </Stack>

          <Typography variant="body2" color="textSecondary" width={300}>
            {dayjs(snapshot.captureTime).format("dddd, h:mm:ss A z")}
          </Typography>
        </Stack>

        <IconButton
          onClick={() => {
            deleteRecording({
              variables: {id: snapshot.recordingID},
            }).catch((e) => toastError(e, "failed to delete recording"));
          }}
        >
          <DeleteIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};
