import {gql} from "@graphql/gql.ts";

export const GET_LOG = gql(/* GraphQL */ `
  query GetLog($id: ID!) {
    getLog(id: $id) {
      id
      startTime
      durationMillis
      status
      specs {
        funcQualifiedName
      }
      processes {
        processID
        processFriendlyName
        binaryID
        captureTime
      }
    }
  }
`);

export const GET_LOG_EVENTS = gql(/* GraphQL */ `
  query GetLogEvents($logID: ID!) {
    getLogEvents(logID: $logID) {
      goroutineID {
        ID
        ProcessID
      }
      timestamp
      message
      processID
      data
      funcDurationNanos
    }
  }
`);

export const GET_HISTOGRAM = gql(/* GraphQL */ `
  query getHistogram($id: ID!, $numBuckets: Int!, $funcQualifiedName: String!) {
    getDurationHistogramForLog(
      id: $id
      numBuckets: $numBuckets
      funcQualifiedName: $funcQualifiedName
    ) {
      minNanos
      maxNanos
      counts
    }
  }
`);

export const GET_EVENT_FREQUENCY = gql(/* GraphQL */ `
  query getEventFrequency($id: ID!, $funcQualifiedName: String) {
    getEventFrequency(id: $id, funcQualifiedName: $funcQualifiedName) {
      start
      functionCounts {
        functionQualifiedName
        countsPerSecond
      }
    }
  }
`);

export const GET_STACKS_FOR_LOG = gql(/* GraphQL */ `
  query getStacksForLog($logID: ID!, $funcQualifiedName: String) {
    getStacksForLog(logID: $logID, funcQualifiedName: $funcQualifiedName) {
      treeJSON
    }
  }
`);
