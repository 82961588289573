import {ModuleSpec, TypeSpecInput} from "@graphql/graphql.ts";
import React, {useState, Suspense, useEffect, useRef} from "react";
import {
  Collapse,
  IconButton,
  Link,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {ExpandableCell} from "@components/ExpandableCell.tsx";
import {DELETE_TYPE_SPEC} from "@util/queries.tsx";
import DeleteIcon from "@mui/icons-material/Delete";
import {useApolloClient} from "@apollo/client";
import {TypeSpecCard} from "./TypeSpecCard";
import {ErrorBoundary} from "react-error-boundary";
import {GoContextSpecCard} from "./GoContextSpecCard";
import {ADD_OR_UPDATE_TYPE_SPEC} from "@components/available-vars-helpers";
import {TableNoDataMessage} from "@components/TableNoDataMessage";
import IconPaper from "@components/icons/IconPaper";

type ModuleTypesSpecProps = {
  module: ModuleSpec;
  editType?: string;
  clearEditType: () => void;
  binaryID?: string;
  onBinarySelected: (callback: () => void) => void;
};

export function ModuleTypesSpec(
  props: ModuleTypesSpecProps,
): React.JSX.Element {
  const client = useApolloClient();
  const addOrUpdateTypeSpec = (input: TypeSpecInput) => {
    void (async () => {
      const {errors} = await client.mutate({
        mutation: ADD_OR_UPDATE_TYPE_SPEC,
        variables: {
          input: input,
        },
      });
      if (errors) {
        console.error("failed to update type spec", errors);
      }
    })();
  };
  const context_module = "Go standard library";

  return (
    <TableContainer>
      <Table sx={{minWidth: 650}} color="secondary">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell sx={{width: "45px"}} align="right">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.module.name != context_module &&
            !props.module.typeSpecs.length && <TableNoDataMessage />}
          <>
            {props.module.name == context_module && (
              <TypeSpecRow
                key={"context.Context"}
                name={"context.Context"}
                help={
                  <Link
                    href="https://docs.side-eye.io/data-capture/"
                    target="_blank"
                  >
                    <Tooltip title="Read more on `context.Context` data collection">
                      <IconButton aria-label="documentation">
                        <IconPaper />
                      </IconButton>
                    </Tooltip>
                  </Link>
                }
                typeSpecCard={
                  <GoContextSpecCard
                    spec={props.module.goContextSpecs}
                    binaryID={props.binaryID!}
                  />
                }
                editType={props.editType}
                clearEditType={props.clearEditType}
                deletable={false}
                binaryID={props.binaryID}
                onBinarySelected={props.onBinarySelected}
              />
            )}
            {props.module.typeSpecs.map((typeSpec) => (
              <TypeSpecRow
                key={typeSpec.typeQualifiedName}
                name={typeSpec.typeQualifiedName}
                typeSpecCard={
                  <TypeSpecCard
                    spec={typeSpec}
                    hoisting={false}
                    binaryID={props.binaryID!}
                    addOrUpdateTypeSpec={addOrUpdateTypeSpec}
                  />
                }
                editType={props.editType}
                clearEditType={props.clearEditType}
                deletable={true}
                binaryID={props.binaryID}
                onBinarySelected={props.onBinarySelected}
              />
            ))}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const StyledTableRow = styled(TableRow)(({theme}) => ({
  // Every other row is a collapsed row, alternate colors between non-collapsed rows.
  "&:nth-of-type(4n+1)": {backgroundColor: theme.palette.secondary.dark},
  "&:nth-of-type(4n+2)": {backgroundColor: theme.palette.secondary.dark},
}));

type TypeSpecRowProps = {
  name: string;
  typeSpecCard: React.JSX.Element;
  help?: React.JSX.Element;
  editType?: string;
  clearEditType: () => void;
  deletable: boolean;
  binaryID?: string;
  onBinarySelected: (callback: () => void) => void;
};

function TypeSpecRow(props: TypeSpecRowProps): React.JSX.Element {
  const [typeOpen, setTypeOpen] = useState<boolean>(false);
  const client = useApolloClient();
  const ref = useRef<HTMLTableRowElement | null>(null);

  useEffect(() => {
    if (props.editType && props.editType == props.name) {
      setTypeOpen(true);
      props.clearEditType();
      setTimeout(() => {
        ref.current?.scrollIntoView();
      }, 60);
    }
  }, [props]);

  return (
    <React.Fragment>
      <StyledTableRow>
        <ExpandableCell
          iconSide="LEFT"
          state={typeOpen}
          onClick={() => {
            props.onBinarySelected(() => {
              setTypeOpen(!typeOpen);
            });
          }}
        >
          {props.name}
          {props.help}
        </ExpandableCell>
        <TableCell>
          {props.deletable ? (
            <Tooltip
              title={
                <>
                  <Typography>Delete type spec</Typography>
                  Deleting this type spec will cause fields of the respective
                  type to be collected according to the default rules. In
                  particular, if this type is wrapped in an interface, it will
                  not be collected at all.
                </>
              }
            >
              <IconButton
                onClick={() => {
                  void client.mutate({
                    mutation: DELETE_TYPE_SPEC,
                    variables: {
                      typeQualifiedName: props.name,
                    },
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton disabled>
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </StyledTableRow>
      <StyledTableRow ref={ref}>
        <TableCell sx={{py: 0}} colSpan={2}>
          <Collapse in={typeOpen} timeout={0} unmountOnExit>
            <ErrorBoundary
              fallbackRender={({error}) => <div>Failed: {error.message}</div>}
            >
              <Suspense fallback={<div>Loading...</div>}>
                {props.typeSpecCard}
              </Suspense>
            </ErrorBoundary>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}
