import {Box, Button, Theme, Tooltip} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {SxProps} from "@mui/material/styles";
import {Goroutine, GoroutineStatus} from "src/__generated__/graphql";
import {formatDurationNanos} from "src/util/util.ts";
import {HelpCircle} from "@components/HelpCircle.tsx";
import {useProcessResolver} from "@providers/processResolverProvider.tsx";

type GoroutineLinkProps = {
  processID: number;
  goroutineID: number;

  // clickURL is the URL that the goroutine link points to. If not set, the
  // goroutine ID is not rendered as a link.
  clickURL: string | undefined;
  // processClickURL, if set, is the URL that the process part of the goroutine
  // ID links to. If set, the process is rendered as a separate link from the
  // goroutine ID.
  processClickURL?: string;

  // If set, more information about the goroutine that can be displayed in
  // the tooltip.
  goroutine?: Goroutine;

  sx?: SxProps<Theme>;

  // color, if set, overrides the default color of the link(s). This is a
  // separate property than sx because sx applies to a parent element to the
  // links, and the links don't inherit the color.
  color?: string;
};

// GoroutineLink renders a link of the form <process name>:<goroutine id>.
// Clicking on the process name alters the filters to focus on the respective
// process. Clicking on the goroutine ID further alters the filters to focus
// only on the respective goroutine.
export default function GoroutineLink(
  props: GoroutineLinkProps,
): React.JSX.Element {
  const {processID, goroutineID} = props;
  const processResolver = useProcessResolver();
  const processInfo = processResolver.resolveProcessIDOrThrow(processID);
  if (processInfo === undefined) {
    throw new Error(`could not resolve snapshot ID ${props.processID}`);
  }

  return (
    <Box display={"inline"} sx={{...props.sx}}>
      <Tooltip
        title={
          props.goroutine && (
            <GoroutineTooltip
              goroutine={props.goroutine}
              processID={props.processID}
              linkColor={"orange"} // Change the links default color, so it looks good on the tooltip's dark background.
              processLinkURL={props.processClickURL}
            />
          )
        }
      >
        <span>
          {props.clickURL ? (
            <Button
              sx={{
                minWidth: 0,
                paddingRight: 0,
                paddingLeft: 0,
                paddingTop: 0,
                paddingBottom: 0,
                color: props.color,
              }}
              size={"small"}
              variant={"text"}
              component={Link}
              to={props.clickURL}
            >
              {processInfo.FriendlyName + ":"}
              {goroutineID}
            </Button>
          ) : (
            <span>
              {processInfo.FriendlyName + ":"}
              {goroutineID}
            </span>
          )}
        </span>
      </Tooltip>
    </Box>
  );
}

function GoroutineTooltip(props: {
  goroutine?: Goroutine;
  processID?: number;
  linkColor?: string;
  processLinkURL?: string;
}): React.JSX.Element {
  if (!props.goroutine) {
    if (props.processID == undefined) {
      throw new Error("either goroutine or processID must be set");
    }
    return <ProcessLink processID={props.processID} color={props.linkColor} />;
  }

  const goroutine = props.goroutine;
  return (
    <>
      Goroutine status: {goroutine.Status}
      {goroutine.Status == GoroutineStatus.Waiting && (
        <>
          <br />
          Has been waiting for:{" "}
          {goroutine.WaitForNanos ? (
            formatDurationNanos(goroutine.WaitForNanos)
          ) : (
            <>
              unknown
              <HelpCircle
                small={true}
                tip={`Short wait times are not always known. The Go runtime only
                populates this information for a goroutine during a
                garbage-collection cycle. This goroutine was not waiting at the
                time of the last GC run.`}
              />
            </>
          )}
          <Box sx={{mt: 1}}>
            <ProcessLink
              processID={goroutine.ID.ProcessID}
              color={props.linkColor}
              clickURL={props.processLinkURL}
            />
          </Box>
        </>
      )}
    </>
  );
}

type ProcessLinkProps = {
  processID: number;
  color?: string;
  clickURL?: string;
};

// ProcessLink renders a link rendered as <process name>. Clicking on the
// process name alters the filters to focus on the respective process.
export function ProcessLink(props: ProcessLinkProps) {
  const processResolver = useProcessResolver();
  const processInfo = processResolver.resolveProcessIDOrThrow(props.processID);
  if (processInfo === undefined) {
    throw new Error(`could not resolve snapshot ID ${props.processID}`);
  }

  return props.clickURL ? (
    <Button
      sx={{
        minWidth: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        color: props.color,
      }}
      size={"small"}
      variant={"text"}
      component={Link}
      to={props.clickURL}
    >
      Filter to process {processInfo.FriendlyName}
    </Button>
  ) : (
    <span>{processInfo.FriendlyName}</span>
  );
}
