import React from "react";

const IconSnapshots: React.FC<{color?: string}> = ({
  color = "currentColor",
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 7H6V16H2V7Z" />
      <path d="M7 7H11V21H7V7Z" />
      <path d="M18 7H22V12H18V7Z" />
      <path d="M13 7H17V19H13V7Z" />
      <path d="M2 4H11V6H2V4Z" />
      <path d="M13 4H22V6H13V4Z" />
    </svg>
  );
};

export default IconSnapshots;
