// debouncedFetchOptions returns a debounced version of a function that performs
// a gql query to fetch function suggestions.
import {ApolloClient} from "@apollo/client";
import {FunctionName} from "@graphql/graphql";
import {gql} from "@graphql/gql";
import {toastError} from "@components/tables/util";
import _ from "lodash";

export const debouncedListFunctionsFromBinary: (
  client: ApolloClient<unknown>,
  binaryID: string,
  input: string,
  callback: (results: FunctionName[]) => void,
) => void = _.debounce(
  (
    client: ApolloClient<unknown>,
    binaryID: string,
    input: string,
    callback: (results: FunctionName[]) => void,
  ): void => {
    client
      .query({
        query: LIST_FUNCTIONS_FROM_BINARY,
        variables: {
          binaryID: binaryID,
          filter: input,
          limit: 50,
        },
      })
      .then(
        (res) => {
          callback(res.data.listFunctionsFromBinary);
        },
        (err) => {
          toastError(err, "failed to get function suggestions");
          throw err;
        },
      );
  },
  400,
);

const LIST_FUNCTIONS_FROM_BINARY = gql(/* GraphQL */ `
  query ListFunctionsFromBinary(
    $binaryID: String!
    $filter: String
    $limit: Int
  ) {
    listFunctionsFromBinary(
      binaryID: $binaryID
      filter: $filter
      limit: $limit
    ) {
      Package
      Type
      Name
      QualifiedName
    }
  }
`);
