import {
  ColumnInfo,
  GoroutineId,
  LinkEndpointSpec,
  LinkSide,
  RowLinksInfo,
  TableReference,
} from "src/__generated__/graphql.ts";
import {schemaInfo, tableInfo} from "src/util/spec.ts";

// resolvedLinkSpec is a distilled version of graph.LinkEndpointSpec. A
// resolvedLinkSpec is constructed in the context of a specific table, which is
// normalized as the "source table" of the link (so the resolvedLinkSpec only
// describes the target table).
export type resolvedLinkSpec = {
  LinkID: string;
  Side: LinkSide;
  LinkNote: string;
  SourceCols: string[];
  TargetCols: string[];
  TargetTableRef: TableReference;
  TargetTableName: string;
  TargetDisplayName: string;
};

// lower a table's link specs to a more convenient form. In particular, the name of the
// table that the rows are linked to is resolved and the source/target are
// normalized such that this table (i.e. the table from which the
// LinkEndpointSpec's came) is the source table.
export function resolveLinkSpecs(
  links: LinkEndpointSpec[],
  schema: schemaInfo,
): resolvedLinkSpec[] {
  return links.map((li): resolvedLinkSpec => {
    const linkSpec = li.LinkSpec;
    let srcCols: string[];
    let targetCols: string[];
    let ti: tableInfo;
    if (li.Side == LinkSide.Source) {
      // We are `srcTable`, so the other table is `destTable`.
      const tabInfo = schema.resolveTableReference(linkSpec.destTable);
      if (tabInfo == undefined) {
        throw new Error(
          "table not found in schema: " + JSON.stringify(linkSpec.destTable),
        );
      }
      ti = tabInfo;
      srcCols = linkSpec.srcCols;
      targetCols = linkSpec.destCols;
    } else {
      // We are `destTable`, so the other table is `srcTable`.
      const tabInfo = schema.resolveTableReference(linkSpec.srcTable);
      if (tabInfo == undefined) {
        throw new Error(
          "table not found: " + JSON.stringify(linkSpec.destTable),
        );
      }
      ti = tabInfo;
      srcCols = linkSpec.destCols;
      targetCols = linkSpec.srcCols;
    }
    return {
      LinkID: linkSpec.id,
      Side: li.Side,
      LinkNote: linkSpec.note ?? "",
      TargetTableRef: ti.tableReference,
      TargetDisplayName: ti.displayName,
      TargetTableName: ti.tableName,
      SourceCols: srcCols,
      TargetCols: targetCols,
    };
  });
}

// resolvedLinksInfo is a distilled version of graph.RowLinksInfo. In
// particular, the values of the columns participating in the links are
// collected. A resolvedLinksInfo is constructed in the context of a specific
// table, which is normalized as the "source table".
export type resolvedLinksInfo = {
  LinkID: string;
  LinkNote: string;
  // The names of the columns defining the link from the source table (i.e. the
  // table in the context of which this resolvedLinksInfo is constructed).
  SourceCols: string[];
  // The names of the columns defining the link from the target table.
  TargetCols: string[];
  // The values of the columns defining this link. One entry for each TargetCols
  // element.
  ColValues: (string | null)[];
  TargetTableRef: TableReference;
  TargetTableDisplayName: string;
  TargetTableName: string;
  NumLinks: number;
  SampleGoroutineID: GoroutineId | undefined;
};

let columnErrorLogOnce = false;

// linksByColumn resolves the links for a single table row. It takes in a list
// of RowLinksInfo (a summary of a row's links), and the respective row (as
// parallel lists of column names and values) and expands the links into a list
// of links for each individual column.
export function linksByColumn(
  links: RowLinksInfo[],
  // The row's columns.
  columns: ColumnInfo[],
  // The row's columns values.
  columnValues: (string | null)[],
  linkEndpointSpecs: LinkEndpointSpec[],
  schema: schemaInfo,
): resolvedLinksInfo[][] {
  // resolveLinks takes the info about a row's links of a specified type, and the
  // spec for those links, and accumulates the values of the columns that are
  // part of the link.
  function resolveLinks(
    links: RowLinksInfo,
    linkSpec: resolvedLinkSpec,
    colNameToIdx: Map<string, number>,
  ): resolvedLinksInfo | undefined {
    let columnErrorLogOnce = false;
    // Collect the values of the columns participating in the link.
    const values: (string | null)[] = [];
    for (const sourceCol of linkSpec.SourceCols) {
      const idx = colNameToIdx.get(sourceCol);
      if (idx == undefined) {
        // The column referenced by this link spec was not selected by this
        // query. This is unexpected; all the columns referenced by links should
        // have been included in the query results. Ignore this link.
        if (!columnErrorLogOnce) {
          columnErrorLogOnce = true;
          console.error(
            "column referenced by link not found in query request:",
            sourceCol,
            linkSpec.LinkID,
          );
        }
        return undefined;
      }
      values.push(columnValues[idx]);
    }

    return {
      LinkID: linkSpec.LinkID,
      LinkNote: linkSpec.LinkNote,
      SourceCols: linkSpec.SourceCols,
      TargetCols: linkSpec.TargetCols,
      ColValues: values,
      TargetTableRef: linkSpec.TargetTableRef,
      TargetTableDisplayName: linkSpec.TargetDisplayName,
      TargetTableName: linkSpec.TargetTableName,
      NumLinks: links.NumLinks,
      SampleGoroutineID: links.SampleGoroutineID ?? undefined,
    };
  }

  // Build a map from column name to index.
  const colNameToIdx = new Map<string, number>();
  for (const [i, col] of columns.entries()) {
    // We use the OriginalName, since that's what the links use.
    if (col.OriginalName) {
      colNameToIdx.set(col.OriginalName, i);
    }
  }

  // Process the link specs into a more convenient form.
  const resolvedLinkSpecs: resolvedLinkSpec[] = resolveLinkSpecs(
    linkEndpointSpecs,
    schema,
  );
  const resolvedLinks: resolvedLinksInfo[] = links
    .map((links: RowLinksInfo): resolvedLinksInfo | undefined => {
      const linkSpec = resolvedLinkSpecs.find(
        (linkSpec: resolvedLinkSpec) =>
          links.LinkID == linkSpec.LinkID && links.Side == linkSpec.Side,
      );
      if (!linkSpec) {
        throw new Error(
          "link spec not found for links: " + JSON.stringify(links),
        );
      }
      // NOTE: resolveLink() will return undefined if any of the columns
      // referenced by the link spec are not found in the query results. We
      // don't expect that to happen, as the query results are supposed to
      // include all the columns referenced by the links. We filter out the
      // undefined's below.
      return resolveLinks(links, linkSpec, colNameToIdx);
    })
    .filter((li) => li != undefined);

  // Go through all the columns selected by the query; for each column, collect
  // the links that include the column.
  return columns.map((c) =>
    resolvedLinks.filter(
      (li) =>
        c.OriginalName &&
        li.SourceCols.find((colName) => c.OriginalName == colName),
    ),
  );
}
