import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {useApolloClient} from "@apollo/client";
import React, {useEffect, useState} from "react";
import {FunctionName} from "@graphql/graphql.ts";
import {debouncedListFunctionsFromBinary} from "@util/debouncedListFunctionsFromBinary";

type FunctionsAutocompleteProps = {
  binaryID: string;
  // value is the qualified name of the selected function, or null if no function is selected.
  value: string | null;
  // inputValue is the text in the input box. In order for the parent to be able
  // to clear the Autocomplete, it seems that it needs to control both value and
  // inputValue.
  inputValue: string;
  onValueChange: (_: string | null) => void;
  onInputChange: (val: string) => void;
};

export type functionSuggestion = {
  friendlyName: string;
  qualifiedName: string;
};

export default function FunctionsAutocomplete(
  props: FunctionsAutocompleteProps,
): React.JSX.Element {
  const [pending, setPending] = useState<boolean>(false);
  const [options, setOptions] = useState<functionSuggestion[]>([]);
  const client = useApolloClient();

  useEffect(() => {
    let active = true;

    if (props.inputValue === "") {
      setOptions([]);
      return undefined;
    }
    setPending(true);
    debouncedListFunctionsFromBinary(
      client,
      props.binaryID,
      props.inputValue,
      (results: FunctionName[]): void => {
        // If this request was cancelled, ignore the result.
        if (!active) {
          return;
        }
        let newOptions = [] as functionSuggestion[];
        if (results) {
          newOptions = results.map((fn) => {
            let friendlyName: string;
            if (fn.Type) {
              friendlyName = `${fn.Package}.${fn.Type}.${fn.Name}`;
            } else {
              friendlyName = `${fn.Package}.${fn.Name}`;
            }
            return {
              qualifiedName: fn.QualifiedName,
              friendlyName: friendlyName,
            } as functionSuggestion;
          });
          setOptions(newOptions);
          setPending(false);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [client, props.binaryID, props.inputValue]);

  return (
    <Autocomplete
      disablePortal
      // Disable the built-in filtering because we do our own filtering by
      // requesting new suggestions. As instructed by
      // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
      filterOptions={(o) => o}
      clearOnBlur={false}
      noOptionsText={
        props.inputValue
          ? pending
            ? "Searching..."
            : "No functions found"
          : "Type to search"
      }
      options={!pending ? options : []}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          placeholder={`Add new function`}
        />
      )}
      inputValue={props.inputValue}
      getOptionLabel={(option: functionSuggestion) => option.qualifiedName}
      getOptionKey={(option: functionSuggestion) => option.qualifiedName}
      value={
        props.value == null
          ? null
          : {friendlyName: "", qualifiedName: props.value}
      }
      isOptionEqualToValue={(option, value) =>
        option.qualifiedName === value.qualifiedName
      }
      onInputChange={(_event, newInputValue, _reason) => {
        props.onInputChange(newInputValue);
      }}
      onChange={(_event, newValue: functionSuggestion | null, _reason) => {
        props.onValueChange(newValue ? newValue?.qualifiedName : null);
      }}
      // Make the home/end keys move the cursor in the text box as opposed to
      // changing the selection in the dropdown.
      handleHomeEndKeys={false}
    />
  );
}
