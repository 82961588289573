import React, {useContext, useState} from "react";
import {useApolloClient} from "@apollo/client";
import {useBinarySelectionDialog} from "@providers/binary-selection-dialog.tsx";
import {ADD_OR_UPDATE_TYPE_SPEC} from "@components/available-vars-helpers.tsx";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import MissingTypes from "./MissingTypes.tsx";
import ModuleName from "./ModuleName.tsx";
import {AddFunctionOrType} from "./AddFunctionOrType.tsx";
import {SpecContext} from "@providers/spec-provider.tsx";
import {DeleteModuleButton} from "./DeleteModuleButton.tsx";
import {ModuleTypesSpec} from "./ModuleTypesSpec.tsx";
import {ModuleFunctionsList} from "./ModuleFunctionsList.tsx";
import DraggableList from "@components/DraggableList.tsx";
import {MOVE_MODULE_TO_INDEX} from "../gqlHelpers.ts";
import {DropResult} from "@hello-pangea/dnd";
import IconCode from "../../../assets/icons/IconCode.svg";
import {ModuleSpec} from "@graphql/graphql.ts";

// ModulesEditor is the editor for the modules of the spec. It is rendered in
// the "Data collection" tab of the SnapshotSpecEditor.
export function ModulesEditor(): React.JSX.Element {
  const spec = useContext(SpecContext);
  const client = useApolloClient();

  const [binaryID, setBinaryID] = useState<string | undefined>(undefined);

  const [editType, setEditType] = useState<string | undefined>(undefined);

  const moduleKey = (module: ModuleSpec, index) => module.pkgPath + `_` + index;

  const [expanded, setExpanded] = React.useState<Set<string>>(new Set());
  const expandModule = (moduleKey: string) => {
    expanded.add(moduleKey);
    setExpanded(new Set(expanded));
  };
  const foldModule = (moduleKey: string) => {
    expanded.delete(moduleKey);
    setExpanded(new Set(expanded));
  };

  // The name of the type whose spec is currently being edited in the drawer. If
  // undefined, the drawer is closed.
  const showBinarySelectionDialog = useBinarySelectionDialog();

  async function promptForBinarySelection(): Promise<string | undefined> {
    const binaryID = await showBinarySelectionDialog();
    if (binaryID == undefined) {
      return undefined;
    }
    setBinaryID(binaryID);
    return binaryID;
  }

  async function onBinarySelected(callback: () => void) {
    if (!binaryID) {
      await promptForBinarySelection();
    }
    callback();
  }

  // onAddAndEditType is called when the user wants to add a type spec and
  // immediately edit in the drawer. A type spec is created that does not
  // collect anything. This is different from adding a type spec without
  // expressing the intent to edit it immediately; in these cases the type spec
  // is set to collect everything.
  async function onAddAndEditType(typeName: string) {
    let binID = binaryID;
    if (!binID) {
      binID = await promptForBinarySelection();
      if (binID == undefined) {
        return;
      }
    }

    const {data, errors} = await client.mutate({
      mutation: ADD_OR_UPDATE_TYPE_SPEC,
      variables: {
        input: {
          typeQualifiedName: typeName,
          collectExprs: [],
          // Note that we do not collect anything.
          collectAll: false,
        },
      },
    });
    if (errors) {
      console.error("failed to add type spec", errors);
    } else {
      data?.addOrUpdateTypeSpec.modules.forEach((m, i) => {
        if (m.typeSpecs.some((t) => t.typeQualifiedName == typeName)) {
          expandModule(moduleKey(m, i));
        }
      });
      setEditType(typeName);
    }
  }

  const onDragEnd = ({destination, source}: DropResult) => {
    // Dropped outside the list.
    if (!destination) return;
    if (source.index == destination.index) {
      // Nothing to do.
      return;
    }

    const m = spec.modules[source.index];

    void client.mutate({
      mutation: MOVE_MODULE_TO_INDEX,
      variables: {pkgPath: m.pkgPath, newIdx: destination.index},
      optimisticResponse: () => {
        const reordered = Array.from(spec.modules);
        reordered.splice(source.index, 1);
        reordered.splice(destination.index, 0, m);
        return {
          moveModuleToIndex: {
            ...spec,
            modules: [...reordered],
          },
        };
      },
    });
  };

  return (
    <Stack sx={{pb: 4}}>
      <AddFunctionOrType binaryID={binaryID} setBinaryID={setBinaryID} />

      <Stack direction="row" gap={2}>
        <div style={{flexGrow: 1}}>
          <DraggableList
            draggableIdKey="pkgPath"
            items={spec.modules}
            onDragEnd={onDragEnd}
            renderItem={(module, index) => {
              const key = moduleKey(module, index);
              return (
                <Accordion
                  key={key}
                  sx={{flexGrow: 1}}
                  slotProps={{transition: {unmountOnExit: true, timeout: 0}}}
                  expanded={expanded.has(key)}
                  onChange={() => {
                    if (expanded.has(key)) {
                      foldModule(key);
                    } else {
                      expandModule(key);
                    }
                  }}
                >
                  <AccordionSummary>
                    <Stack
                      display="grid"
                      gridTemplateColumns="30px minmax(200px, 1fr) 40px"
                      gap={2}
                      alignItems="center"
                      flexGrow={1}
                    >
                      <img src={IconCode} alt="module" />

                      <ModuleName module={module} />

                      <DeleteModuleButton module={module} />
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails sx={{display: "grid", gap: 3}}>
                    <ModuleFunctionsList
                      binaryID={binaryID}
                      module={module}
                      promptForBinarySelection={() => {
                        void promptForBinarySelection();
                      }}
                    />

                    <ModuleTypesSpec
                      binaryID={binaryID}
                      module={module}
                      editType={editType}
                      clearEditType={() => setEditType(undefined)}
                      onBinarySelected={(callback) =>
                        void onBinarySelected(callback)
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }}
          />
        </div>

        <div style={{maxWidth: 400}}>
          <MissingTypes
            spec={spec}
            onEditTypeClick={(arg) => void onAddAndEditType(arg)}
          />
        </div>
      </Stack>
    </Stack>
  );
}
