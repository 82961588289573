import {Components} from "@mui/material/styles/components";
import {darkTheme} from "../theme.ts";

export const MuiButton = (): Pick<
  Components<typeof darkTheme>,
  "MuiButton"
> => ({
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 100,
      },
    },
    variants: [
      {
        props: {size: "medium"},
        style: {
          fontSize: 14,
          lineHeight: "20px",
          padding: "10px 24px",
        },
      },
      {
        props: {size: "large"},
        style: {
          fontSize: 14,
          lineHeight: "20px",
          padding: "13px 24px",
        },
      },
      {
        props: {variant: "outlined", color: "info"},
        style: ({theme}) => ({
          borderColor: "transparent",
          borderRadius: 12,
          minWidth: 48,
          width: 48,
          height: 48,
          padding: 8,
          backgroundColor: theme.palette.background.component,
        }),
      },
    ],
  },
});
